import React from 'react'

const Profiel = () => {
  return (
    <div>
      Profiel
    </div>
  )
}

export default Profiel
