import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import RP_Avatar from "../RP_Avatar";

export const CardAdmin = ({ admin, getSelected }) => {
  const navigate = useNavigate();

  return (
    <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <div class="flex flex-col items-center py-8">
        <RP_Avatar avatar={admin.avatar} size={24} alt={`Specialist`} />
      
        <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">
          {admin.full_name}
        </h5>
        <span class="text-sm text-gray-500 dark:text-gray-400">
          {admin?.beroep||'...'}
        </span>
        <div className="gap-x-4 mt-2"> <div class="text-xs text-gray-500 dark:text-gray-400">
          tel: {admin?.phone_number || '0x xxx xx xx'}
        </div>
        <div class="text-xs text-gray-500 dark:text-gray-400">
          email: {admin?.email || '...'}
        </div>
        </div>
        <div class="flex mt-4 md:mt-6">
        <div
        onClick={() => getSelected(admin)}
        >
          <span
            class="inline-flex items-center px-2 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            Maak een afspraak
          </span>
          </div>
          <NavLink
            to={'/mailbox'}>
          <span
            class="inline-flex  py-2 px-2 ms-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          >
            Stuur een mail
          </span>
          </NavLink>

        </div>
      </div>
    </div>
  );
};
