
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAtintkNL-nj4RwFp2fwYH4t-2CUGrYEfs",
  authDomain: "repeatheatlh.firebaseapp.com",
  projectId: "repeatheatlh",
  storageBucket: "repeatheatlh.firebasestorage.app",
  messagingSenderId: "757002749578",
  appId: "1:757002749578:web:ac8e55b4bce3964781cf73",
  measurementId: "G-LSYXZQD9LS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };


