import React from "react";
import classNames from '../../../utils'
import { Radio, RadioGroup } from "@headlessui/react";


const Gezondheidsprofiel = ({formData, editeer,handleChange,onChangeRadio}) => {

  const changeCheckbox = (e, name) => 
    {
      const value = e.target.value;
      const currentValues = formData[name] || '';
      let newValues;
      if (currentValues.includes(value)) {
        // Remove value if it exists
        newValues = currentValues.replace(value, '');
      } else {
        // Add value if it doesn't exist
        newValues = currentValues ? currentValues + ',' + value : value;
      }
      onChangeRadio(name, newValues);
    }

    
  return (
    <div className="mt-4 p-8 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-6xl">
      <p className="text-center p-4 rounded-md bg-gray-200 ">
        Gezondheidsprofiel
      </p>
      <div className="space-y-8 mx-16 my-12">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            1. Hebt u een van de volgende aandoeningen gehad? Omcirkel degene
            die u reeds heeft gehad.
          </label>
          <div className="mt-2 grid grid-cols-2 gap-3 sm:grid-cols-2">
            {[
              {
                value: "Ziekte van pfeiffer",
                label: "Ziekte van Pfeiffer",
              },
              { value: "Schimmelinfecties", label: "Schimmelinfecties" },
              { value: "Toxoplasmose", label: "Toxoplasmose" },
              { value: "Kalknagels", label: "Kalknagels" },
              { value: "Zwemmerseczeem", label: "Zwemmerseczeem" },
              {
                value: "Ringworm/anale jeuk",
                label: "Ringworm/anale jeuk",
              },
              { value: "Darmklachten", label: "Darmklachten" },
            ].map((option) => (
              <label
                key={option.value}
                className={classNames(
                  "relative flex cursor-pointer rounded-md px-3 py-3 text-xs font-semibold uppercase",
                  formData?.health_conditions?.includes(option.value)
                    ? "bg-green-700 text-white hover:bg-green-800"
                    : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                )}
              >
                <input
                  type="checkbox"
                  disabled={editeer}
                  name="health_conditions"
                  value={option.value}
                  checked={formData?.health_conditions?.includes(option.value)}
                  onChange={(e) => changeCheckbox(e,'health_conditions')}
                  className="sr-only"
                />
                <span className="flex-1 text-center">{option.label}</span>
              </label>
            ))}
          </div>
        </div>
        {/* 2. Welke andere ziekten hebt u doorgemaakt ? Gelieve deze liefst
      chronologisch te vermelden met jaartal indien mogelijk. */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            2. Welke andere ziekten hebt u doorgemaakt ? Gelieve deze liefst
            chronologisch te vermelden met jaartal indien mogelijk.
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <textarea rows={5}
              name="other_diseases"
              disabled={editeer}
              value={formData.other_diseases}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 3. Zijn er erfelijke aandoeningen/terugkerende klachten in uw familie? */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            3. Zijn er erfelijke aandoeningen/terugkerende klachten in uw
            familie?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="text"
              name="family_conditions"
              disabled={editeer}
              value={formData.family_conditions}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 4. Welke medicijnen en/of supplementen hebt u tijdens uw leven
      gebruikt? Antibiotica, anticonceptiva, vitaminen, statines, slaap-
      en/of kalmeringsmiddelen, etc.? */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            4. Welke medicijnen en/of supplementen hebt u tijdens uw leven
            gebruikt? Antibiotica, anticonceptiva, vitaminen, statines, slaap-
            en/of kalmeringsmiddelen, etc.?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <textarea rows={5}
              name="medications_used"
              disabled={editeer}
              value={formData.medications_used}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 5. Welke middelen gebruikt u op het moment ? Gelieve deze liefst mee nemen naar het intake/consult indien mogelijk. */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            5. Welke middelen gebruikt u op het moment ? Gelieve deze liefst mee
            nemen naar het intake/consult indien mogelijk.{" "}
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <textarea rows={5}
              name="current_medications"
              disabled={editeer}
              value={formData.current_medications}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 6. Welke vaccinaties heeft u gekregen? Eventuele reacties?*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            6. Welke vaccinaties heeft u gekregen? Eventuele reacties?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <textarea rows={5}
              name="vaccinations"
              disabled={editeer}
              value={formData.vaccinations}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 7. Heeft u vaak last van terugkerende sportblessures, zo ja welke?*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            7. Heeft u vaak last van terugkerende sportblessures, zo ja welke?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <textarea rows={5}
              name="sports_injuries"
              disabled={editeer}
              value={formData.sports_injuries}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 8. Heeft u last van allergieën en/of intoleranties, zo ja welke?*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            8. Heeft u last van allergieën en/of intoleranties, zo ja welke?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <textarea rows={5}
              name="allergies"
              disabled={editeer}
              value={formData.allergies}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 9. Hoe is het gesteld met uw ademhaling? Heeft u last van (chronische) hyperventilatie?*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            9. Hoe is het gesteld met uw ademhaling? Heeft u last van
            (chronische) hyperventilatie?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <textarea rows={5}
              name="breathing"
              disabled={editeer}
              value={formData.breathing}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 10. Hoe is het gesteld met de kwaliteit van uw slaap?*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            10. Hoe is het gesteld met de kwaliteit van uw slaap?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <textarea rows={5}
              name="sleep_quality"
              disabled={editeer}
              value={formData.sleep_quality}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 11. Hoeveel uur slaapt u gemiddeld per nacht? */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            11. Hoeveel uur slaapt u gemiddeld per nacht?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="text"
              name="average_sleep_hours"
              disabled={editeer}
              value={formData.average_sleep_hours}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            12. Beoordeling van uw slaap
          </label>
          <RadioGroup
            disabled={editeer}
            value={formData?.sleep_rating}
            name={"sleep_rating"}
            onChange={(e) => onChangeRadio("sleep_rating", e)}
            className="mt-2 grid grid-cols-6 gap-3 sm:grid-cols-12"
          >
            {Array.from({ length: 11 }, (_, i) => (
              <div key={i} className="flex items-center">
                <Radio
                  key={i}
                  disabled={editeer}
                  value={i}
                  name={"sleep_rating"}
                  className={({ focus, checked }) =>
                    classNames(
                      "cursor-pointer focus:outline-none",
                      focus ? "ring-2 ring-indigo-600 ring-offset-2" : "",
                      checked
                        ? "bg-[#01bd64] text-white hover:bg-[#01bd64]"
                        : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                      "flex items-center justify-center rounded-md px-2 py-2 text-xs font-semibold uppercase sm:flex-1"
                    )
                  }
                >
                  {i}
                </Radio>
              </div>
            ))}
          </RadioGroup>
        </div>
        {/* 13. Heeft u last van: */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            13. Heeft u last van:
          </label>
          <div className="mt-2 grid grid-cols-2 gap-3 sm:grid-cols-2">
            {[
              { value: "Slapeloosheid", label: "Slapeloosheid" },
              {
                value: "Moeilijk_inslapen_zo_ja_wat_houdt_u_wakker?",
                label: "Moeilijk inslapen, zo ja wat houdt u wakker?",
              },
              {
                value: "'s_Nachts_wakker_worden_zo_ja_op_welk_tijdstip?",
                label: "'s Nachts wakker worden, zo ja op welk tijdstip?",
              },
              {
                value: "Nachtmerries_hevige_dromen",
                label: "Nachtmerries/hevige dromen",
              },
              {
                value: "Comateus_wakker_worden_s_nachts_slaapverlamming",
                label: "Comateus wakker worden 's nachts/slaapverlamming",
              },
              {
                value: "Dorst_hebben_s_nachts_en_overdag",
                label: "Dorst hebben 's nachts en/overdag",
              },
              { value: "Plassen_s_nachts", label: "Plassen 's nachts" },
            ].map((option) => (
              <label
                key={option.value}
                className={classNames(
                  "relative flex cursor-pointer rounded-md px-3 py-3 text-xs font-semibold uppercase",
                  formData?.sleep_issues?.includes(option.value)
                    ? "bg-green-700 text-white hover:bg-green-800"
                    : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                )}
              >
                <input
                  type="checkbox"
                  disabled={editeer}
                  name="sleep_issues"
                  value={option.value}
                  checked={formData?.sleep_issues?.includes(option.value)}
                  onChange={(e) => changeCheckbox(e,'sleep_issues')}
                  className="sr-only"
                />
                <span className="flex-1 text-center">{option.label}</span>
              </label>
            ))}
          </div>
        </div>
        {/* 14. Heeft u een regelmatige stoelgang? Gaat u dagelijks?*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            14. Heeft u een regelmatige stoelgang? Gaat u dagelijks?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="text"
              name="regular_bowel_movement"
              disabled={editeer}
              value={formData.regular_bowel_movement}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 15. Heeft u ooit in het buitenland een virusinfectie, bacteriële, en/of schimmelinfectie opgelopen? Wat waren de klachten?*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            15. Heeft u ooit in het buitenland een virusinfectie, bacteriële,
            en/of schimmelinfectie opgelopen? Wat waren de klachten?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="text"
              name="foreign_infections"
              disabled={editeer}
              value={formData.foreign_infections}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 16a. Bent u geboren via de natuurlijke weg of via keizersnede? En heb jij borstvoeding gekregen? */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            16a. Bent u geboren via de natuurlijke weg of via keizersnede?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="text"
              name="birth_method"
              disabled={editeer}
              value={formData.birth_method}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 16b. Bent u borstvoeding gegeven?*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            16b. Bent u borstvoeding gegeven?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="text"
              name="breastfeeding"
              disabled={editeer}
              value={formData.breastfeeding}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 17. Krijgt u klachten van bepaalde voedingsmiddelen?*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            17. Krijgt u klachten van bepaalde voedingsmiddelen?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="text"
              name="food_reactions"
              disabled={editeer}
              value={formData.food_reactions}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 18. Heeft u holte spoelingen gehad (darmspoelingen)?*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            18. Heeft u holte spoelingen gehad (darmspoelingen)?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="text"
              name="gut_cleanings"
              disabled={editeer}
              value={formData.gut_cleanings}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 19. Heeft u een kunstgebit, jackets, kronen bruggen, gebit plaatje, pacemaker, spiraaltje, operatie metaal en/of trommelvliesbuisjes*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            19. Heeft u een kunstgebit, jackets, kronen bruggen, gebit plaatje,
            pacemaker, spiraaltje, operatie metaal en/of trommelvliesbuisjes
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="text"
              name="dental_work"
              disabled={editeer}
              value={formData.dental_work}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 20. Heeft u amalgaamvullingen? */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            20. Heeft u amalgaamvullingen?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="text"
              name="amalgam_fillings"
              disabled={editeer}
              value={formData.amalgam_fillings}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 21. Gebruikt u de pil? Zo ja, dewelke? */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            21. Gebruikt u de pil? Zo ja, dewelke?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <textarea rows={5}
              name="contraceptives"
              disabled={editeer}
              value={formData.contraceptives}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 22. Rookt u? Zo ja, hoeveel per dag?*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            22. Rookt u? Zo ja, hoeveel per dag?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="text"
              name="smoking"
              disabled={editeer}
              value={formData.smoking}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 23. Drinkt u alcohol? Hoevaak per week en hoeveel glazen per dag?*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            23. Drinkt u alcohol? Hoevaak per week en hoeveel glazen per dag?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="text"
              name="alcohol"
              disabled={editeer}
              value={formData.alcohol}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 24. Drinkt u koffie? Hoeveel koppen per dag?*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            24. Drinkt u koffie? Hoeveel koppen per dag?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="text"
              name="coffee"
              disabled={editeer}
              value={formData.coffee}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 25. Heeft u een sterke drang naar zoet, bitter, zout, scherp en/of zuur? */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            25. Heeft u een sterke drang naar zoet, bitter, zout, scherp en/of
            zuur?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="text"
              name="food_cravings"
              disabled={editeer}
              value={formData.food_cravings}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 26. Zijn er bepaalde voedingsmiddelen waar u verslaafd aan bent?*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            26. Zijn er bepaalde voedingsmiddelen waar u verslaafd aan bent?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <textarea rows={5}
              name="food_addictions"
              disabled={editeer}
              value={formData.food_addictions}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/*  27. Heeft u (andere) verslavingen? */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            27. Heeft u (andere) verslavingen?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <textarea rows={5}
              name="other_addictions"
              disabled={editeer}
              value={formData.other_addictions}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 28. Wat is uw gemiddeld stressniveau tijdens een gemiddelde dag op een schaal van 0 tot 10? Denk hierbij na over alle activiteiten waar u bij betrokken bent zoals  werk, school, mantelzorg, huishoudelijk werk, reizen, etc. */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            28. Wat is uw gemiddeld stressniveau tijdens een gemiddelde dag op
            een schaal van 0 tot 10? Denk hierbij na over alle activiteiten waar
            u bij betrokken bent zoals werk, school, mantelzorg, huishoudelijk
            werk, reizen, etc.
          </label>
          <RadioGroup
            disabled={editeer}
            value={formData?.stress_handling}
            name={"stress_handling"}
            onChange={(e) => onChangeRadio("stress_handling", e)}
            className="mt-2 grid grid-cols-6 gap-3 sm:grid-cols-12"
          >
            {Array.from({ length: 11 }, (_, i) => (
              <div key={i} className="flex items-center">
                <Radio
                  key={i}
                  disabled={editeer}
                  value={i}
                  name={"stress_handling"}
                  className={({ focus, checked }) =>
                    classNames(
                      "cursor-pointer focus:outline-none",
                      focus ? "ring-2 ring-indigo-600 ring-offset-2" : "",
                      checked
                        ? "bg-[#01bd64] text-white hover:bg-[#01bd64]"
                        : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                      "flex items-center justify-center rounded-md px-2 py-2 text-xs font-semibold uppercase sm:flex-1"
                    )
                  }
                >
                  {i}
                </Radio>
              </div>
            ))}
          </RadioGroup>
        </div>
        {/* 29. Hoe gaat u om met stress? */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            29. Hoe gaat u om met stress?{" "}
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <textarea rows={5}
              name="stress_level"
              disabled={editeer}
              value={formData.stress_level}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 30. Heeft u psychische/emotionele problemen? */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            30. Heeft u psychische/emotionele problemen?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <textarea rows={5}
              name="mental_issues"
              disabled={editeer}
              value={formData.mental_issues}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 31. Ervaart u een van de onderstaande klachten?*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            31. Ervaart u een van de onderstaande klachten?
          </label>
          <div className="mt-2 grid grid-cols-2 gap-3 sm:grid-cols-2">
            {[
              { value: "Depressie", label: "Depressie" },
              { value: "Laag zelfbeeld", label: "Laag zelfbeeld" },
              { value: "Dwang", label: "Dwang" },
              { value: "Schuldgevoelens", label: "Schuldgevoelens" },
              { value: "Stressgevoelig", label: "Stressgevoelig" },
              { value: "Verslavingen", label: "Verslavingen" },
              {
                value: "Uitstelgedrag/motivatieproblemen",
                label: "Uitstelgedrag/motivatieproblemen",
              },
              {
                value: "Drang naar comfort food (cravings)",
                label: "Drang naar comfort food (cravings)",
              },
              { value: "Eenzaamheid", label: "Eenzaamheid" },
              {
                value: "Drang naar suiker/zetmeel/alcohol",
                label: "Drang naar suiker/zetmeel/alcohol",
              },
              { value: "Angst/paniek", label: "Angst/paniek" },
              { value: "Spierspanning", label: "Spierspanning" },
              { value: "Geheugenproblemen", label: "Geheugenproblemen" },
              { value: "Slaapproblemen", label: "Slaapproblemen" },
            ].map((option) => (
              <label
                key={option.value}
                className={classNames(
                  "relative flex cursor-pointer rounded-md px-3 py-3 text-xs font-semibold uppercase",
                  formData?.issueses?.includes(option.value)
                    ? "bg-green-700 text-white hover:bg-green-800"
                    : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                )}
              >
                <input
                  type="checkbox"
                  name="issueses"
                  value={option.value}
                  disabled={editeer}
                  checked={formData?.issueses?.includes(option.value)}
                  onChange={(e) => changeCheckbox(e,'issueses')}
                  className="sr-only"
                />
                <span className="flex-1 text-center">{option.label}</span>
              </label>
            ))}
          </div>
        </div>
        {/* 32. Hoe emotioneel flexibel bent u op een schaal van 0 to 10?*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            32. Hoe emotioneel flexibel bent u op een schaal van 0 to 10?
          </label>
          <RadioGroup
            disabled={editeer}
            value={formData?.emotional_flexibility}
            name={"emotional_flexibility"}
            onChange={(e) => onChangeRadio("emotional_flexibility", e)}
            className="mt-2 grid grid-cols-6 gap-3 sm:grid-cols-12"
          >
            {Array.from({ length: 11 }, (_, i) => (
              <div key={i} className="flex items-center">
                <Radio
                  key={i}
                  disabled={editeer}
                  value={i}
                  name={"emotional_flexibility"}
                  className={({ focus, checked }) =>
                    classNames(
                      "cursor-pointer focus:outline-none",
                      focus ? "ring-2 ring-indigo-600 ring-offset-2" : "",
                      checked
                        ? "bg-[#01bd64] text-white hover:bg-[#01bd64]"
                        : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                      "flex items-center justify-center rounded-md px-2 py-2 text-xs font-semibold uppercase sm:flex-1"
                    )
                  }
                >
                  {i}
                </Radio>
              </div>
            ))}
          </RadioGroup>
        </div>
        {/* 33. Persoonlijke opmerkingen?*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            33. Persoonlijke opmerkingen?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <textarea rows={5}
              name="personal_remarks"
              disabled={editeer}
              value={formData.personal_remarks}
              onChange={handleChange}
              rows="3"
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gezondheidsprofiel;
