import { createSlice } from '@reduxjs/toolkit';

const calendlyAfspraakSlice = createSlice({
  name: 'user',
  initialState: {
    calendlyafspraak:[],
  },
  reducers: {
    setCalendlyafspraak: (state, action) => {
      // Appends the new item to the existing user array
      state.calendlyafspraak = action.payload;
    },
    setsetCalendlyafspraakDelete: (state, action) => {
      // This one seems to replace mailMessage entirely, no change needed here
      state.calendlyafspraak = state.calendlyafspraak.filter(appointment => 
        appointment.id != action.payload.id);},
  },
  
});

export const {
setCalendlyafspraak,
setsetCalendlyafspraakDelete
} = calendlyAfspraakSlice.actions;

export default calendlyAfspraakSlice.reducer;
