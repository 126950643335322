import React, { useLayoutEffect, useState } from "react";
import Banner from "../../Layout/Banner";
import { AXIOS_GET } from "../../server/api/crud";
import { getUser } from "../../Localstorage/localstorage";
import RPProgressbarVragenlijst from "../../Componenten/Progressbar/RPProgressbarVragenlijst";
import { useNavigate } from "react-router-dom";
import DashboardAdmin from "./DashboardAdmin";
import DashboardClient from "./DashboardClient";



const Dashboard = () => {
  const selectedUser = getUser();
  const [user, setUser] = useState({})
  const navigate = useNavigate();
  
  useLayoutEffect(() => {
        if (selectedUser){
          fetchData(selectedUser.id);
        }else{
          navigate('/signin');
        }
  }, [])

  const fetchData = async (p_id) => {
    try {
      const response = await AXIOS_GET(`users/${p_id}`);
      setUser(response.data.data[0]);
    } catch (error) {
      navigate('/signin')
      console.error("Error fetching data:", error);
    }
  };

 if (user.role_id == 1) return (
   <DashboardAdmin/>
  );

  if (user.role_id == 2) return (
   <DashboardClient/>
  );

  return (
    <div className="w-full h-96 flex items-center justify-center">
      Aan het laden...
    </div>
  );
};

export default Dashboard;
