import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import RoutesApp from "./RoutesApp";
import { AuthProvider } from "./context/AuthContext";


function App() {

  return (
    <Provider store={store}>
        <AuthProvider>
          <RoutesApp />
        </AuthProvider>
    </Provider>
  );
}

export default App;
