
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverBackdrop,
    PopoverButton,
    PopoverPanel,
  } from '@headlessui/react'
  import {
    ArrowLongLeftIcon,
    CheckIcon,
    HandThumbUpIcon,
    HomeIcon,
    MagnifyingGlassIcon,
    PaperClipIcon,
    QuestionMarkCircleIcon,
    UserIcon,
  } from '@heroicons/react/20/solid'
  import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import Spinner from '../../../Componenten/Spinner'
import { Attachments } from '../../../Componenten/Documenten/Attachments'
import Comments from '../../../Componenten/Comments/Comments'
import VragenlijstenResumer from '../../../Componenten/Vragenlijsten/VragenlijstenResumer'
import { useCallback, useLayoutEffect, useState } from 'react'
import { AXIOS_GET } from '../../../server/api/crud'
import Commentaar from '../../../Componenten/Commentaar'
import { getUserdetails } from '../../../Localstorage/localstorage'
import RP_Avatar from '../../../Componenten/RP_Avatar'


  const eventTypes = {
    applied: { icon: XMarkIcon, bgColorClass: 'bg-red-400' },
    advanced: { icon: XMarkIcon, bgColorClass: 'bg-red-500' },
    completed: { icon: XMarkIcon, bgColorClass: 'bg-red-500' },
  }
  
  const timeline = [
    {
      id: 1,
      type: eventTypes.applied,
      content: 'Pre-intake formulier',
      target: 'Niet ingevuld',
      date: 'Sep 20',
      datetime: '2020-09-20',
    },
    {
      id: 2,
      type: eventTypes.advanced,
      content: 'Klachtenregistratie formulier',
      target: 'Niet ingevuld',
      date: 'Sep 22',
      datetime: '2020-09-22',
    },
    {
      id: 3,
      type: eventTypes.advanced,
      content: 'VoedingsDagboek formulier',
      target: 'Niet ingevuld',
      date: 'Sep 22',
      datetime: '2020-09-22',
    }
  ]

  const CalculateAge = ( birthDate ) => {
    // Get the current date
    const currentDate = moment();
    
    // Create a moment object for the birthdate
    const birthDateMoment = moment(birthDate, 'YYYY-MM-DD'); // Adjust format as needed

    // Calculate the age
    const age = currentDate.diff(birthDateMoment, 'years');
    
    return age
  };
  
  const DetailProfiel = () => {

    const usernav = useLocation();
    const {user} = usernav?.state;
    const [attachments, setAttachments] = useState([])
    const [vragenlijst, setVragenlijst] = useState({})

    const userAdmin = getUserdetails();

    const getDocumentByID = async (p_user_id) =>{
      await AXIOS_GET(`documents/user/${p_user_id}`).then((res)=>{
          setAttachments(res.data.data)
      })
    }

    const fetchData = async (p_id) => {
      try {
        const response = await AXIOS_GET(`vragenlijsten/${p_id}`);
        setVragenlijst(response.data.data[0])

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getAll = useCallback(async () => {
      if (user) {
        const { user_id } = user;
        await Promise.all([ getDocumentByID(user_id),   fetchData(user_id)]);
      }
    }, [user?.user_id]); 
  
    useLayoutEffect(() => {
      getAll();
    }, [])
    

  if (user) return (
        <div className="h-fit bg-gray-200  dark:bg-gray-800">
          <main className="py-10 h-fit">
            {/* Page header */}
            <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
              <div className="flex items-center space-x-5">
                <div className="flex-shrink-0">
                  <div className="relative">
                    <RP_Avatar avatar={user?.userdetails_avatar} size={16} alt={` ${user?.userdetails_full_name}`} />
                    <span aria-hidden="true" className="absolute inset-0 rounded-full shadow-inner" />
                  </div>
                </div>
                <div>
                  <h1 className="text-2xl font-bold text-gray-900 dark:text-white">{user?.userdetails_full_name}</h1>
                  <p className="text-sm font-medium text-gray-500 dark:text-white">
                    <a href="#" className="text-gray-900 dark:text-white">
                      Geboortedatum
                    </a>{' '}
                    on <time dateTime="2020-08-25">{`${moment(user?.userdetails_date_of_birth).format('LL')} (${CalculateAge(user?.userdetails_date_of_birth)} jaar)`}</time>
                  </p>
                </div>
              </div>
              
            </div>
  
            <div className="mx-auto mt-8 grid max-w-3xl dark:bg-gray-800 grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
              <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                {/* Description list*/}
                <section aria-labelledby="applicant-information-title">
                  <div className="bg-white shadow sm:rounded-lg dark:bg-gray-800">
                    <div className="px-4 py-5 sm:px-6">
                      <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                        Informatie van de patient
                      </h2>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-white">Samenvatting van zijn gehele gezondheidsevolutie</p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500 dark:text-white">Geregistreed op</dt>
                          <dd className="mt-1 text-sm text-gray-900 dark:text-white">{moment(user?.userdetails_created_at).format('LLLL')}</dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500 dark:text-white">Email address</dt>
                          <dd className="mt-1 text-sm text-gray-900 dark:text-white">{user?.userdetails_email}</dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500 dark:text-white">Klacht</dt>
                          <dd className="mt-1 text-sm text-gray-900 dark:text-white">{user?.userdetails_klacht}</dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500 dark:text-white">Phone</dt>
                          <dd className="mt-1 text-sm text-gray-900 dark:text-white">{user?.userdetails_phone_number}</dd>
                        </div>
                        <div className="sm:col-span-2">
                          <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Situatie</dt>
                          <dd className="mt-1 text-sm text-gray-900 dark:text-white">
                           {user?.userdetails_more_details}
                          </dd>
                        </div>
                       {attachments?.length > 0 && <Attachments attachments={attachments}/>}
                      </dl>
                    </div>
                 
                  </div>
                </section>
  
                {/* Comments*/}
                <Commentaar admin={userAdmin} user={user}/>
              </div>
  
            <VragenlijstenResumer timeline={timeline} vragenlijst={{klacht_verstuur: user?.vragenlijsten_klacht_verstuur, preintake_verstuur:user?.vragenlijsten_preintake_verstuur, voeding_verstuur: user?.vragenlijsten_voeding_verstuur}} user={user}/>
            </div>
          </main>
        </div>
    )

    return(<Spinner/>)
  }

  export default DetailProfiel
  