import React from "react";
import { getUser } from "../../Localstorage/localstorage";
import Spinner from "../../Componenten/Spinner";
import { documentenTab } from "../../function";
import CardDocument from "../../Componenten/Cards/CardDocument";

const Documents = () => {
  const user = getUser();

  if(user.role === 1) return (
    <div className="p-4">
        <div className="grid grid-cols-3 gap-x-4">
          {
            documentenTab({advies:0, plans:4, uploads:9})?.map((item,index)=><CardDocument key={index} item={item}/>)
          }
        </div>
      </div>
  );
  if(user.role === 2) return (
      <div className="p-4">
        <div className="grid grid-cols-3 gap-x-4">
          {
            documentenTab({advies:0, plans:4, uploads:9})?.map((item,index)=><CardDocument key={index} item={item}/>)
          }
        </div>
      </div>
  ); 

  return (
    <Spinner/>
  );
};

export default Documents;
