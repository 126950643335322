import { useState} from "react";
import "tailwindcss/tailwind.css";
import { getUser } from "../../Localstorage/localstorage";
import EvolutionClient from "./EvolutionClient";
import EvolutionAdmin from "./EvolutionAdmin";
import Spinner from "../../Componenten/Spinner";



const Evolution = () => {
  const user = getUser();

  // Tab content based on the selected metric
  if(user.role == 2) return <EvolutionClient/>;
  if(user.role == 1) return <EvolutionAdmin/>;

  return <Spinner/>
};

export default Evolution;
