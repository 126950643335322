import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import { AXIOS_POST, AXIOS_UPDATE } from "../../server/api/crud";
import Swal from "sweetalert2";
import moment from "moment";
import {
  setAppointment,
  setAppointmentLoad,
} from "../../redux/appointmentSlice";
import { Carousel } from "react-responsive-carousel";
import Dropzone from "../Dropzone";
import { Attachments } from "../Documenten/Attachments";
import Dropzone2 from "../Dropzone2";
import ReInput from "../Layout/ReInput";

export default function UploadDocumenten({
  open = false,
  setOpen,
  setRefresh,
  user,
  patient,
  type = "uploads",
}) {
  const [progresses, setProgresses] = useState({}); // Track progress for each file
  const [documenten, setDocumenten] = useState([]);

  const reset = () => {
    setProgresses({});
    setDocumenten([]);
    setOpen(false);
  };

  const saveDocuments = async () => {
    if (documenten?.length == 0) return;

    let finalDocument = documenten?.map((document) => {
      // Return a new object with updated title, spreading in other properties
      return {
        ...document,
        title: progresses[document?.bestand]?.title || document.title, // Fallback to existing title if not in progresses
      };
    });

    const url =
      user?.role_id == 2
        ? "documents/multiple"
        : type == "advies"
        ? "adviezen/multiple"
        : "documents/admin/multiple";
    try {
      const resp = await AXIOS_POST(url, { documenten: finalDocument });
      console.log(resp);

      if (resp.data.success) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: `Bestanden zijn opgeslagen`,
          showConfirmButton: false,
          timer: 2500,
        });
        reset();
        setRefresh(true);
      }
    } catch (error) {
      console.error(
        "Error occurred:",
        error.response?.data || error.message || error
      );

      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `er is iets mis gelopen`,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-700 px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-full sm:max-w-5xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="w-full pt-10 ">
              <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900 mb-4">
                Laad een bestanden op
              </h2>
              <div>
                <Dropzone2
                  user={user}
                  setProgresses={setProgresses}
                  setDocumenten={setDocumenten}
                  patient={patient}
                  type={type}
                  progresses={progresses}
                />
              </div>
              <div className="flex justify-between">
                <button
                  onClick={() => setOpen(false)}
                  className="bg-gray-800 text-white text-sm rounded mr-2 mt-2 py-2 px-4"
                >
                  Sluiten
                </button>
                <div className="flex justify-end">
                  <button
                    onClick={saveDocuments}
                    className="bg-green-700 text-white text-sm rounded mr-2 mt-2 py-2 px-4"
                  >
                    Opslaan
                  </button>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
