import React, { useEffect, useState } from "react";
import Banner from "../../Layout/Banner";
import DagBoekList from "./detail/DagBoekList";
import { getUser, getUserdetails } from "../../Localstorage/localstorage";
import Swal from "sweetalert2";
import {
  AXIOS_DELETE,
  AXIOS_POST,
  AXIOS_UPDATE,
  AXIOS_UPLOAD_DOCUMENT,
} from "../../server/api/crud";
import ReInput from "../../Componenten/Layout/ReInput";
import TypeNote from "./TypeNote";
import { Radio, RadioGroup } from "@headlessui/react";
import UploadBestand from "../../Componenten/ImageUpload/UploadBestand";
import ReInputAttachtments from "../../Componenten/Layout/ReInputAttachtments";
import MonthViewEetdagboek from "../../Componenten/CalenderEetdagboek/MonthViewEetdagboek";
import RPDrawerComment from "../../Componenten/Drawers/RPDrawerComment";
import UploadBestandInput from "../../Componenten/ImageUpload/UploadBestandInput";
import Spinner from "../../Componenten/Spinner";
import moment from "moment";
import {
  CalendarIcon,
  ChatBubbleLeftIcon,
  CloudArrowDownIcon,
  DocumentIcon,
} from "@heroicons/react/24/solid";

const memoryOptions = [
  { name: "Dag", type_note: 1 },
  { name: "Ontbijt - Lunch - Dinner", type_note: 2 },
  { name: "Uren in een dag", type_note: 3 },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const EetdagboekClient = ({}) => {
  const [dagboeken, setDagboeken] = useState([]);
  const user = getUser();
  const userdetails = getUserdetails();
  const [loading, setLoading] = useState(false);
  const [command, setCommand] = useState("insert");
  const [refresh, setRefresh] = useState(false);
  const [startdate, setStartdate] = useState(null);
  const [enddate, setEnddate] = useState(null);
  const [view, setView] = useState(1);
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const eetdagboek = {
    user_id: user?.id,
    datum: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    dag: "",
    ochtend: "",
    voormiddag: "",
    middag: "",
    namiddag: "",
    avond: "",
    smorgens: "",
    smiddags: "",
    savonds: "",
    type_note: 1,
    title: "",
  };

  const [formData, setFormData] = useState(eetdagboek);

  const refreshForm = () => {
    setFormData(eetdagboek);
    setFile(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AXIOS_POST(`eetdagboek/user`, {
          id: user?.id,
          startdate,
          enddate,
        });
        const eetdagboeken = response.data.data;
        setDagboeken(eetdagboeken);
        setRefresh(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // if (refresh) {
    fetchData();
    // }
  }, [refresh, user?.id, startdate, enddate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    if (!formData.title.trim()) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Gegevens niet opgeslagen. Gelieve al de velden invullen.",
        showConfirmButton: false,
        timer: 2500,
      });
      return false;
    }
    return true;
  };

  const Opslaan = async () => {
    if (!validateForm()) return;
    if (command == "wijzigen") {
      try {
        await AXIOS_UPDATE(`eetdagboek`, formData.id, formData);
        setCommand(null);
        setFile(null);
        Swal.fire("Gegevens zijn gewijzigd", "", "success");
        setRefresh(true);
      } catch (error) {
        console.error("Error updating data:", error);
        Swal.fire("Er is een fout opgetreden bij het wijzigen", "", "error");
      }
    } else {
      try {
        const result = await Swal.fire({
          icon: "question",
          title: "Wilt u deze gegevens opslaan?",
          showCancelButton: true,
          confirmButtonText: "Opslaan",
          cancelButtonColor: "gray",
          confirmButtonColor: "#111827",
          cancelButtonText: "Annuleer",
        });

        if (result.isConfirmed) {
          await AXIOS_POST(`eetdagboek`, formData);
          refreshForm();

          setCommand(null);
          Swal.fire(
            "Eetdagboek is vandaag ingevuld en opgeslagen",
            "",
            "success"
          );
          setRefresh(true);
        }
      } catch (error) {
        console.error("Error saving data:", error);
        Swal.fire("Er is een fout opgetreden bij het opslaan", "", "error");
      }
    }
  };

  const EditTekst = async (item, cmd) => {
    const result = await Swal.fire({
      title: "Wenst u de gegevens te wijzigen?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Wijzigen",
      denyButtonText: "Verwijderen",
      cancelButtonColor: "gray",
      cancelButtonText: "Annuleer",
      confirmButtonColor: "#111827",
      denyButtonColor: "red",
    });

    if (result.isConfirmed) {
      setCommand(cmd);
      setShowEdit(false);
      setFormData((prev) => ({
        ...prev,
        id: item?.eetdagboek_id || null,
        user_id: item?.eetdagboek_user_id || "",
        datum: item?.eetdagboek_datum || new Date(),
        dag: item?.eetdagboek_dag || "",
        ochtend: item?.eetdagboek_ochtend || "",
        voormiddag: item?.eetdagboek_voormiddag || "",
        middag: item?.eetdagboek_middag || "",
        namiddag: item?.eetdagboek_namiddag || "",
        avond: item?.eetdagboek_avond || "",
        smorgens: item?.eetdagboek_smorgens || "",
        smiddags: item?.eetdagboek_smiddags || "",
        savonds: item?.eetdagboek_savonds || "",
        type_note: item?.eetdagboek_type_note || 1,
        title: item?.eetdagboek_title || "",
        url: item?.eetdagboek_url || "",
      }));
    } else if (result.isDenied) {
      setCommand(null);
      const deleteResult = await Swal.fire({
        title: "Ben u zeker deze gegevens te willen verwijderen?",
        showCancelButton: true,
        confirmButtonText: "Ja",
        cancelButtonText: "Neen",
        confirmButtonColor: "#111827",
      });

      if (deleteResult.isConfirmed) {
        setCommand(null);
        let id = Number(item?.eetdagboek_id);
        const res = await AXIOS_DELETE(`eetdagboek`, id);

        Swal.fire({
          title: "Gegevens zijn verwijderd",
          icon: "success",
          confirmButtonColor: "green",
        });

        setRefresh(true);
      }
    }
  };

  const ShowText = (item) => {
    setFormData((prev) => ({
      ...prev,
      id: item?.eetdagboek_id || "",
      user_id: item?.eetdagboek_user_id || "",
      datum: item?.eetdagboek_datum || new Date(),
      dag: item?.eetdagboek_dag || "",
      ochtend: item?.eetdagboek_ochtend || "",
      voormiddag: item?.eetdagboek_voormiddag || "",
      middag: item?.eetdagboek_middag || "",
      namiddag: item?.eetdagboek_namiddag || "",
      avond: item?.eetdagboek_avond || "",
      smorgens: item?.eetdagboek_smorgens || "",
      smiddags: item?.eetdagboek_smiddags || "",
      savonds: item?.eetdagboek_savonds || "",
      type_note: item?.eetdagboek_type_note || 1,
      title: item?.eetdagboek_title || "",
      admin: item?.eetdagboek_admin,
      admin_id: item?.eetdagboek_admin_id,
      score: item?.eetdagboek_score || 0,
      opmerking: item?.eetdagboek_opmerking || "",
      url: item?.eetdagboek_url || "",
    }));

    setShowEdit(true);
  };


  return (
    <>
      {view == 2 ? (
        <MonthViewEetdagboek
          user={user}
          userdetails={userdetails}
          setView={setView}
          appointments={dagboeken}
        />
      ) : (
        <div className="flex h-dvh dark:bg-gray-800">
          <aside className="inset-y-0 bg-gray-100 hidden 2xl:w-1/4 xl:w-2/6 dark:bg-gray-700 overflow-y-auto px-4 py-6 sm:px-6 lg:px-4 xl:block">
            <div className="mt-50 py-10">
              {dagboeken?.length > 0 ? (
                <DagBoekList
                  editTekst={EditTekst}
                  showText={ShowText}
                  setView={setView}
                  dagboeken={dagboeken}
                />
              ) : (
                <div>
                  <p>Geen gegevens gevonden</p>
                </div>
              )}
            </div>
          </aside>
          <main className="2xl:w-3/4 xl:w-4/6 h-dvh lg:overflow-y-auto">
            <div className="grid justify-items-stretch">
              <div className="justify-self-end pr-8 mt-2 items-center">
                <button
                  type="button"
                  title="Calenderview"
                  onClick={() => setView(2)}
                  className="ml-2 inline-flex  gap-2 items-end justify-end rounded-md bg-gray-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  <CalendarIcon
                    className="-mr-0.5 h-5 w-5 text-gray-300"
                    aria-hidden="true"
                  />
                </button>
                {!formData.id && formData.title && (
                  <UploadBestand
                    formData={formData}
                    userdetails={userdetails}
                    setLoading={setLoading}
                    linkbackend={"eetdagboek/upload"}
                    setformData={setFormData}
                    editkey={"url"}
                  />
                )}
                {formData?.opmerking && (
                  <button
                    type="button"
                    title="Opmerking geplaats door Specialist"
                    onClick={() => setOpen(true)}
                    className="inline-flex justify-self-end mt-2 dark:bg-gray-700 items-center gap-2 rounded-md bg-gray-700 mx-2 px-4 py-2 text-sm font-semibold text-white shadow-sm  hover:bg-red-700"
                  >
                    <ChatBubbleLeftIcon
                      className="mr h-5 w-5 text-gray-300"
                      aria-hidden="true"
                    />
                  </button>
                )}
                {formData.title && (
                  <button
                    type="button"
                    title="Nieuwe"
                    onClick={() => {
                      setFormData(eetdagboek);
                      setShowEdit(false);
                      setFile(null);
                      setCommand(null);
                    }}
                    className="dark:bg-gray-700 dark:text-white inline-flex items-center gap-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-blue-500"
                  >
                    <DocumentIcon
                      className="mr-0.5 h-5 w-5 text-gray-300"
                      aria-hidden="true"
                    />
                  </button>
                )}
                {formData.title && (
                  <button
                    type="button"
                    title="Opslaan"
                    onClick={Opslaan}
                    className="ml-2 inline-flex gap-2 items-center rounded-md bg-green-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  >
                    <CloudArrowDownIcon
                      className="-mr-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                )}
              </div>
            </div>
            <div className="xl:pl-8">
              <div className="px-4 py-4 sm:px-6 lg:px-8 lg:py-4">
                <div className="col-span-full">
                  {showEdit == false && (
                    <RadioGroup
                      value={formData.type_note}
                      onChange={(e) =>
                        setFormData((prev) => ({ ...prev, type_note: e }))
                      }
                      className="my-6 grid grid-cols-3 gap-3 sm:grid-cols-3"
                    >
                      {memoryOptions.map((option) => (
                        <Radio
                          key={option.name}
                          value={option.type_note}
                          className={({ focus, checked }) =>
                            classNames(
                              "cursor-pointer focus:outline-none",
                              focus
                                ? "ring-2 ring-green-600 ring-offset-2"
                                : "",
                              checked || formData.type_note == option.type_note
                                ? "bg-green-600 dark:bg-gray-600 text-white hover:bg-green-500"
                                : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                              "flex justify-center items-center rounded-md px-4 py-4 text-xs font-semibold"
                            )
                          }
                        >
                          {option.name}
                        </Radio>
                      ))}
                    </RadioGroup>
                  )}
                  <div className="col-span-full">
                    {showEdit == false ? (
                      <ReInputAttachtments
                        type="text"
                        id="title"
                        link={`eetdagboek/upload`}
                        name="title"
                        label="Titel"
                        placeholder="Vul de titel in"
                        setLoading={setLoading}
                        value={formData.title}
                        formData={formData}
                        onChange={handleChange}
                      />
                    ) : (
                      <div className="flex gap-x-4">
                        <div
                          dangerouslySetInnerHTML={{ __html: formData?.title }}
                          className={`mt-2 space-y-2 text-xl text-gray-800 dark:text-white`}
                        />
                        {formData?.id && (
                          <UploadBestandInput
                            linkbackend={`eetdagboek/upload`}
                            setLoading={setLoading}
                            userdetails={userdetails}
                            formData={formData}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <TypeNote
                  admin={showEdit}
                  Eetdagboek={formData}
                  OnChange={handleChange}
                />
              </div>
            </div>
          </main>
          {formData && (
            <RPDrawerComment
              item={formData}
              admin={formData}
              setOpen={setOpen}
              open={open}
              user={user}
            />
          )}
          {loading && (
            <div className="absolute z-40 bg-white w-screen">
              <Spinner />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EetdagboekClient;
