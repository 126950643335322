import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../Localstorage/localstorage";
import MailboxKlant from "./MailboxKlant";
import MailboxAdmin from "./MailboxAdmin";
import MailList from "./MailList";
import RPDrawerMessaging from "../../Componenten/Drawers/RPDrawerMessaging";
import { 
  setMailMessage, 
  setMailMessageItemsFilterLoad, 
  setMailMessageItemsLoad,  
  setMailRoomsLoad 
} from "../../redux/mailSlice";
import { AXIOS_GET } from "../../server/api/crud";
import { IOSOCKETCLIENT, setIOSocketListeners } from "../../linksocket/linksocket";
import { MAKE_SOCKET_CHANNEL, SOCKET_CHANNELS } from "../../constants/constants";

// Main MailBox Component
const MailBox = () => {
  const user = getUser();
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const { mailMessage } = useSelector((state) => state.mail);

  const fetchMailRooms = async () => {
    try {
      const response = await AXIOS_GET(`mailbox/mailmessageById/${user?.id}`);
      dispatch(setMailRoomsLoad(response.data.data));
      dispatch(setMailMessage(response.data.data[0]));
    } catch (error) {
      console.error("Error fetching mailrooms", error);
    }
  };

  const fetchMailMessages = async () => {
    try {
      const response = await AXIOS_GET(`mailbox/messageItemsByUser/${user?.id}`);
      dispatch(setMailMessageItemsLoad(response.data.data));
      dispatch(setMailMessageItemsFilterLoad(response.data.data));
    } catch (error) {
      console.error("Error fetching mail messages", error);
    }
  };

  const getMail = useCallback(async () => {
    await Promise.all([fetchMailRooms(), fetchMailMessages()]);
  }, [dispatch, user?.id]); // Only depend on dispatch and user?.id

  const refreshMail = useCallback(() => {
    getMail();
  }, [getMail]);

  useLayoutEffect(() => {
    if (user.role === 2 && user?.id) {
      getMail(); // Fetch initial mail data
    }
  }, [user?.id, getMail]); // Depend only on user?.id

  useEffect(() => {
    if (user.role === 2 && user?.id && mailMessage?.id) {
      
      const channel = MAKE_SOCKET_CHANNEL(mailMessage?.id, SOCKET_CHANNELS.MAIL_ITEM);
      // Set socket listeners
      setIOSocketListeners(channel, SOCKET_CHANNELS.MAIL_ITEM, refreshMail);
      // Cleanup function on unmount
      return () => {
        IOSOCKETCLIENT.emit("JOINLEAVEROOM", { action: "leave", channel });
      };
    }
  }, [user, mailMessage?.id, refreshMail]); // Clean dependencies

  return (
    <div className={`flex ${user.role == 1 ? 'bg-gray-100' : 'bg-white'} h-full flex-col dark:bg-gray-900`}>
      <div className="flex min-h-0 flex-1 overflow-hidden">
        <main className="min-w-0 flex-1 border-t border-gray-200 xl:flex h-dvh">
          <MailList setOpen={setOpenDialog} />
          {user?.role === 1 ? <MailboxAdmin /> : <MailboxKlant />}
        </main>
      </div>
      <RPDrawerMessaging open={openDialog} setOpen={setOpenDialog} />
    </div>
  );
};

export default MailBox;
