import { faFileImage, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export function capitalizeFirstLetter(string) {
    if (!string) return ''; // Handle empty or null strings
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  export const getFormattedMonth = (locale = 'nl-be') => {
    return moment().locale(locale).format("MMMM YYYY");
  };

  export const documentenTab = (aantal) => [ 
    {
      id: 1,
      title: "Adviezen",
      href:"/documents/advies",
      button:'Ga naar',
      subtitle:`Aantal documenten: ${aantal?.advies} (De adviezen van de specialisten)`,
    },
    {
      id: 2,
      title: "Plans",
      href:"/documents/plans",
      button:'Ga naar',
      subtitle:`Aantal documenten: ${aantal?.plans} (Mealplans - Voedingsmiddelentabel - Recepten) `,    
    },
    {
      id: 3,
      title: "Documenten",
      href:"/documents/uploads",
      button:'Ga naar',
      subtitle:`Aantal documenten: ${aantal?.uploads} (Uw opgeladen documenten) `,    
    },
  ];

  export const vooruitgangTabs = (user,vooruitgang) => [
    {
      id: 1,
      naam: "Slaapkwaliteit",
      key: "slaap",
      vooruitgang_id: vooruitgang?.id || 0,
      user_id: user?.id,
      score: 0,
      uitleg:"",
      date: moment().format("YYYY-MM-DD"),
    },
    {
      id: 2,
      naam: "Energieniveau",
      key: "energie",
      vooruitgang_id: vooruitgang?.id || 0,
      user_id: user?.id,
      score: 0,
      uitleg:"",
      date: moment().format("YYYY-MM-DD"),
    },
    {
      id: 3,
      naam: "Stressniveau",
      key: "stress",
      vooruitgang_id: vooruitgang?.id || 0,
      user_id: user?.id,
      score: 0,
      uitleg:"",
      date: moment().format("YYYY-MM-DD"),
    },
    {
      id: 4,
      naam: "Cravings",
      key: "cravings",
      vooruitgang_id: vooruitgang?.id || 0,
      user_id: user?.id,
      score: 0,
      uitleg:"",
      date: moment().format("YYYY-MM-DD"),
    },
  ];

  export  const CalculateAge = ( birthDate ) => {
    // Get the current date
    const currentDate = moment();
    
    // Create a moment object for the birthdate
    const birthDateMoment = moment(birthDate, 'YYYY-MM-DD'); // Adjust format as needed

    // Calculate the age
    const age = currentDate.diff(birthDateMoment, 'years');
    
    return age
  };

  export const typeFile = (type) => {

    return type === 'application/pdf' ?  faFilePdf : faFileImage;
  
  }

  export const nvl = (pa,pb) => {
    if(pa == '' || pa == undefined || pa == null)
     return pb;

     return pa;
  }

  export const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  }