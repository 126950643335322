import moment from "moment";
import React, { useState } from "react";
import { useEffect, useRef } from "react";
import { capitalizeFirstLetter } from "../../function";
import WeekEvents from "./WeekEvents";
import CrudEvent from "./CrudEvent";
import ShowInfoAfspraak from "./ShowInfoAfspraak";
import { useSelector } from "react-redux";
import { Menu, Transition } from "@headlessui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import { generateDaysArrayForWeek } from "./calenderUtilties";

const daysArray = [];
const currentMonth = moment().locale("nl-be").format("YYYY-MM");
const selectedDay = daysArray.find((day) => day.isSelected);

// components/Day.js
const Day = ({ titleDay, numDay }) => (
  <div className="flex gap-x-1 items-center justify-center py-3">
    <span className="font-semibold text-gray-900">{titleDay}</span>
    <span className="font-semibold text-gray-900">{numDay}</span>
  </div>
);

const TimeLabel = ({ label }) => (
  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
    {label}
  </div>
);

const Header = ({ formattedMonth, currentMonth, setOpen }) => (
  <header className="flex items-center justify-between border-b border-gray-200  px-6 py-4 lg:flex-none">
    <h1 className="text-base font-semibold leading-6 text-gray-900 dark:bg-gray-700 dark:text-white">
      <time dateTime={currentMonth}>
        {moment(formattedMonth).locale("nl-be").format("MMMM YYYY")}
      </time>
    </h1>
    <div className="flex items-center">
      <div className="hidden md:ml-4 md:flex md:items-center">
        <div className="ml-6 h-6 w-px bg-gray-300" />
        <button
          type="button"
          onClick={() => setOpen(true)}
          className="ml-6 rounded-md bg-green-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
        >
          Afspraak maken
        </button>
      </div>
    </div>
  </header>
);

const HeaderDays = ({ days, handlePreviousWeek, handleNextWeek }) => {
  return (
    <div className="mt-2 h-full  hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 dark:bg-gray-800 dark:text-white sm:grid">
      <div className="col-end-1 w-14" />
      {days.map((day, index) => (
        <div
          key={index}
          className="flex gap-x-1 items-center justify-center py-3 "
        >
          {index == 0 && (
            <button
              type="button"
              onClick={handlePreviousWeek}
              className="-m-1.5 flex flex-none dark:text-white items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-7 w-7" aria-hidden="true" />
            </button>
          )}
          <span className="items-center justify-center font-semibold text-gray-900 dark:text-white">
            {day.titleDay}
          </span>
          <span className="items-center justify-center font-semibold text-gray-900  dark:text-white">
            {day.numDay}
          </span>
          {index == 6 && (
            <button
              type="button"
              onClick={handleNextWeek}
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Previous month</span>
              <ChevronRightIcon
                className="h-7 w-7 dark:text-white"
                aria-hidden="true"
              />
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

const HoursLines = ({ containerOffset }) => {
  const hours = Array.from({ length: 15 }, (_, i) => {
    const acht = i + 8;
    const hour = Math.floor(acht).toString().padStart(2, "0"); // Get the full hour
    const minutes = "00"; // Alternate between ":00" and ":30"
    return `${hour}:${minutes}`; // Combine hour and minutes
  });

  return (
    <div
      className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100 dark:bg-gray-700 dark:text-white"
      style={{ gridTemplateRows: "repeat(15, minmax(8rem, 1fr))" }}
    >
      <div
        ref={containerOffset}
        className="row-end-1 h-7 dark:bg-gray-800"
      ></div>
      {hours.map((hour, index) => (
        <React.Fragment key={index}>
          <div>
            <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-700 ">
              <span className="text-sm dark:text-white">{hour}</span>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

const DaysLines = () => {
  const numColumns = 8; // Change this to dynamically adjust the number of columns

  return (
    <div
      className={`col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100  sm:grid sm:grid-cols-7`}
    >
      {Array.from({ length: numColumns }, (_, index) => (
        <div
          key={index}
          className={`col-start-${index + 1} row-span-full ${
            index === numColumns - 1 ? "w-8" : ""
          }`}
        />
      ))}
    </div>
  );
};

const Schedule = ({
  containerNav,
  container,
  containerOffset,
  days,
  setOpen,
  currentMonth,
  handleNextWeek,
  handlePreviousWeek,
  appointments,
}) => (
  <div
    ref={container}
    className="isolate flex flex-auto flex-col overflow-auto bg-white"
  >
    <div
      style={{ width: "165%" }}
      className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full"
    >
      <div
        ref={containerNav}
        className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 dark:bg-gray-800 sm:pr-8"
      >
        <HeaderDays
          days={days}
          handleNextWeek={handleNextWeek}
          handlePreviousWeek={handlePreviousWeek}
        />
      </div>
      <div className="flex flex-auto dark:bg-gray-800">
        <div className="sticky left-0 z-10 w-14 flex-none bg-white dark:bg-gray-800 ring-1 ring-gray-100" />
        <div className="grid flex-auto grid-cols-1 grid-rows-1 dark:bg-gray-800">
          <HoursLines containerOffset={containerOffset} />
          <DaysLines />
          {days?.length > 0 &&
            days?.map((item) => (
              <WeekEvents setOpen={setOpen} evenmentes={item?.events} />
            ))}
        </div>
      </div>
    </div>
  </div>
);

const WeekView = ({ userdetails, user, appointments }) => {
  const [open, setOpen] = useState(false);
  const [openCrud, setOpenCrud] = useState(false);
  const [afspraak, setAfspraak] = useState({});
  const [openModalEdit, setopenModalEdit] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment().locale("nl-be")); // Current week starting date
  const [days, setDays] = useState(
    generateDaysArrayForWeek(currentDate, selectedDay, appointments)
  );

  const handlePreviousWeek = () => {
    const newDate = currentDate.clone().subtract(1, "weeks");
    setCurrentDate(newDate); // Update the current week
  };

  // Handle moving to the next week
  const handleNextWeek = () => {
    const newDate = currentDate.clone().add(1, "weeks");
    setCurrentDate(newDate); // Update the current week
  };

  useEffect(() => {
    setDays(generateDaysArrayForWeek(currentDate, selectedDay, appointments));
  }, [currentDate, appointments, selectedDay]);

  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);

  const showAfspraak = (afspr) => {
    setAfspraak(afspr);
    setopenModalEdit(true);
  };

  useEffect(() => {
    // Set the container scroll position based on the current time.
    const currentMinute = new Date().getHours() * 60;
    container.current.scrollTop =
      ((container.current.scrollHeight -
        containerNav.current.offsetHeight -
        containerOffset.current.offsetHeight) *
        currentMinute) /
      1440;
  }, []);

  return (
    <div className="flex h-full flex-col dark:bg-gray-700 dark:text-white rounded border dark:border-gray-200">
      <Header
        formattedMonth={currentDate}
        setOpen={setOpen}
        currentMonth={currentMonth}
      />
      <Schedule
        container={container}
        containerNav={containerNav}
        containerOffset={containerOffset}
        days={days}
        appointments={appointments}
        handlePreviousWeek={handlePreviousWeek}
        handleNextWeek={handleNextWeek}
      />
      <CrudEvent openModel={open} setOpenModal={setOpen} />
      <ShowInfoAfspraak
        open={openModalEdit}
        setOpen={setopenModalEdit}
        evenment={afspraak}
      />
    </div>
  );
};

export default WeekView;
