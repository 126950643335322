import {
  faListCheck,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useLayoutEffect, useState } from "react";
import RPModal from "../../Modal/RPModal";
import { AXIOS_GET } from "../../server/api/crud";
import VoedingForm from "./voeding/VoedingForm";

function VoedingDagboek({ swipperRef, user, navigation }) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
  });

  useLayoutEffect(() => {
    const fetchData = async (p_id) => {
      try {
        const response = await AXIOS_GET(`voedsel/${p_id}`);
        if (response.data.data[0]) setFormData(response.data.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (user) {
      const { id } = user;
      fetchData(id);
      //Redirect to home page if user is already logged in
    }
  }, [open
  ]);
  return (
    <div className="flex justify-center items-center mt-16">
      {formData.status == "current" ? (
        <button
          type="button"
          onClick={() => setOpen(true)}
          className="relative block w-3/4 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <div
            className={`ml-8 text-4xl bg-green-50 flex items-center justify-center text-green-800`}
          >
            <FontAwesomeIcon icon={faListCheck} />
          </div>

          <span className="mt-2 block text-lg font-semibold text-gray-900 dark:text-gray-100">
            Het voedingsformulier is al ingevuld. Wilt u nog aanpassingen
            maken? Klik op deze ruimte
          </span>
        </button>
      ) : formData.status == "completed" ? (
        <button
          type="button"
          onClick={() => navigation("/mailbox")}
          className="relative block w-3/4 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <div
            className={`ml-8 text-4xl flex items-center justify-center text-green-800 mb-8`}
          >
            <FontAwesomeIcon icon={faPaperPlane} />
          </div>

          <span className="mt-2 block text-lg font-semibold text-gray-900 dark:text-gray-100">
            Het voedingsformulier is ingevuld en verstuurd. Wenst u nog
            aanpassingen te maken? Stuur dan een e-mail via de mailbox naar de
            arts.
          </span>
        </button>
      ) : (
        <button
          type="button"
          onClick={() => setOpen(true)}
          className="relative block w-3/4 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <svg
            className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-100"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 2H32C33.1 2 34 2.9 34 4V44C34 45.1 33.1 46 32 46H12C10.9 46 10 45.1 10 44V4C10 2.9 10.9 2 12 2Z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 2H24L34 12H10V2Z"
            />
          </svg>

          <span className="mt-2 block text-lg font-semibold text-gray-900 dark:text-gray-100">
            Vul uw voedingsformulier in door op deze ruimte te klikken.
          </span>
        </button>
      )}
      <RPModal
        setOpen={setOpen}
        forms={formData}
        open={open}
        title={"Pre-intake"}
        Component={
          <VoedingForm
            user={user}
            setOpen={setOpen}
            slider={swipperRef}
            formData={formData}
            setFormData={setFormData}
          />
        }
      />
    </div>
  );
}

export default VoedingDagboek;
