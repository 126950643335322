import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AXIOS_DELETE, AXIOS_POST, AXIOS_UPDATE } from "../../server/api/crud";
import Swal from "sweetalert2";
import moment from "moment";
import {
  setAppointment,
  setAppointmentDelete,
  setAppointmentUpdate,
} from "../../redux/appointmentSlice";
import CrudEvent from "./CrudEvent";

export default function ShowInfoAfspraak({ open, setOpen, evenment, user }) {
  const [event, setEvent] = useState(evenment);
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setEvent(evenment);
  }, [evenment]);

  const Wijzigen = (item) => {
    setEvent(item);
    setOpenModal(true);
  };

  // Edit an event
  const handleEdit = (event) => {};

  const handleBevestigen = async (e) => {
    const newObject = { ...event, admin_accept: 1 };

    try {
      const result = await Swal.fire({
        icon: "question",
        title: `Wilt u deze afspraak bevestigen en opslaan?`,
        showCancelButton: true,
        confirmButtonText: "Opslaan",
        cancelButtonText: "Annuleer",
        cancelButtonColor: "gray",
        confirmButtonColor: "#111827",
      });

      if (result.isConfirmed) {
        const response = await AXIOS_UPDATE(
          `afspraak/bevestigd`,
          event?.id,
          newObject
        );

        if (response.status === 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Afspraak is bevestigd`,
            showConfirmButton: false,
            timer: 2500,
          });

          dispatch(setAppointmentUpdate(newObject));
          setOpen(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "Er is een fout opgetreden",
            text: "Kon de afspraak niet bevestigen.",
            confirmButtonText: "OK",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Er is een fout opgetreden",
        text: error.message || "Er ging iets mis.",
        confirmButtonText: "OK",
      });
    }
  };

  // Delete an event
  const handleDelete = async (event) => {
    await AXIOS_DELETE(`afspraak`, event?.id, event).then((respons) => {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: `Afspraak is succesvol verwijderd`,
        showConfirmButton: false,
        timer: 2500,
      });
      setOpen(false);
      dispatch(setAppointmentDelete(event));
    });
    // dispatch(setAppointment(event));
  };

  return (
    <>
      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="container mx-auto p-4">
                <h1 className="text-2xl font-semibold mb-4">Afspraak</h1>

                {/* Event List */}
                <div className="grid gap-4">
                  <div
                    key={event?.id}
                    className={`p-4 border rounded-md ${
                      event?.color || "bg-gray-50"
                    }`}
                  >
                    {event?.admin_accept == 0 && (
                      <h2 className="bg-orange-200 p-2 rounded mb-2 text-orange-800 font-semibold">
                        Deze afspraak is nog niet bevestigd
                      </h2>
                    )}
                    <h2 className="font-semibold">{event?.type}</h2>
                    <p>{event?.user}</p>
                    <p>
                      {moment(event?.date, "YYYY-MM-DD")
                        .locale("nl-be")
                        .format("dddd LL")}
                    </p>
                    <p>
                      {moment(event.startTime, "HH:mm:ss").format("HH:mm")} -{" "}
                      {moment(event.endTime, "HH:mm:ss").format("HH:mm")}
                    </p>

                    <label>Omschrijving: </label>
                    <p>{event?.description}</p>
                    {event?.user_id == user?.id && (
                      <button
                        onClick={() => Wijzigen(event)}
                        className="bg-green-600 text-white mr-4 mt-2 rounded mr-4 py-2 px-4"
                      >
                        Wijzigen
                      </button>
                    )}
                    {event?.admin_accept == 0 && user?.role == 1 && (
                      <button
                        onClick={handleBevestigen}
                        className="bg-green-800 text-white rounded mr-4 mt-2 py-2 px-4"
                      >
                        Bevestigen
                      </button>
                    )}
                      <button
                        onClick={() => handleDelete(event)}
                        className="bg-red-800 text-white ring-red-200 rounded mr-4 mt-2 py-2 px-4"
                      >
                        Afspraak verwijderen
                      </button>
                  </div>
                </div>
              </div>
              {event && (
                <CrudEvent
                  handleDelete={handleDelete}
                  setOpenModal={setOpenModal}
                  openModel={openModal}
                  setOpen={setOpen}
                  afspraak={event}
                />
              )}
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
