import { createSlice } from '@reduxjs/toolkit';
import { getAdmins, getUser, getUserdetails } from '../Localstorage/localstorage';

const mailSlice = createSlice({
  name: 'mail',
  initialState: {
    mailRooms: [],
    mailMessage: null,
    mailMessageItems: [],
    mailMessageItemsFilter: [],
    command: '',
    user: getUser(),
    userdetails: getUserdetails(),
    admins: getAdmins()
  },
  reducers: {
    setMailRooms: (state, action) => {
      // Appends the new item to the existing mailRooms array
      state.mailRooms = [...state.mailRooms, action.payload];
    },
    setMailRoomsLoad: (state, action) => {
        state.mailRooms = action.payload;
    },
    setMailMessage: (state, action) => {
      // This one seems to replace mailMessage entirely, no change needed here
      state.mailMessage = action.payload;
    },
    setMailMessageItems: (state, action) => {
      // Appends the new item to the existing mailMessageItems array
      state.mailMessageItems = [...state.mailMessageItems, action.payload];
    },
    setMailMessageItemsFilter: (state, action) => {
      // Appends the new item to the existing mailMessageItemsFilter array
      state.mailMessageItemsFilter = [...state.mailMessageItemsFilter, action.payload];
    },
    setMailMessageItemsLoad: (state, action) => {
      // Appends the new item to the existing mailMessageItems array
      state.mailMessageItems = action.payload;
    },
    setMailMessageItemsFilterLoad: (state, action) => {
      // Appends the new item to the existing mailMessageItemsFilter array
      state.mailMessageItemsFilter = action.payload;
    },
    setCommand: (state, action) => {
      // This one seems to replace command entirely, no change needed here
      state.command = action.payload;
    },
    setUser: (state, action) => {
      // This one seems to replace user entirely, no change needed here
      state.user = action.payload;
    },
    setUserDetails: (state, action) => {
      // This one seems to replace userDetails entirely, no change needed here
      state.userdetails = action.payload;
    },
    setAdmins: (state, action) => {
      // This one seems to replace admins entirely, no change needed here
      state.admins = action.payload;
    },
  },
  
});

export const {
  setMailRooms,
  setMailMessage,
  setMailMessageItems,
  setMailMessageItemsFilter,
  setMailRoomsLoad,
  setMailMessageItemsFilterLoad,
  setMailMessageItemsLoad,
  setCommand,
  setUser,
  setUserDetails,
  setAdmins,
} = mailSlice.actions;

export default mailSlice.reducer;
