import React, { useLayoutEffect, useState } from "react";
import Calendar from "../../Componenten/Calender/Calendar";
import BannerPlannig from "../../Layout/BannerPlannig";
import MonthView from "../../Componenten/Calender/MonthView";
import WeekView from "../../Componenten/Calender/WeekView";
import DayView from "../../Componenten/Calender/DayView";
import UpcomingAgenda from "../../Componenten/Calender/UpcomingAgenda";
import DoubleMeeting from "../../Componenten/Calender/DoubleMeeting";
import {
  setAppointment,
  setAppointmentLoad,
  setAppointmentLoadInWacht
} from "../../redux/appointmentSlice";
import { useDispatch, useSelector } from "react-redux";
import { AXIOS_GET } from "../../server/api/crud";
import { getUser, getUserdetails } from "../../Localstorage/localstorage";
import PatientenView from "../../Componenten/Calender/PatientenView";

const Items = [
  { id: 0, name: "Maand", viewComponent: MonthView },
  { id: 1, name: "Week", viewComponent: WeekView },
  { id: 2, name: "Dag", viewComponent: DayView  },
  { id: 3, name: "Geplande Afspraken", viewComponent: UpcomingAgenda },
  { id: 4, name: "Patienten", viewComponent: PatientenView },
  { id: 5, name: "Overzicht 2 maanden", viewComponent: DoubleMeeting },
];

const Planning = () => {

  const [view, setView] = useState(0);
  const dispatch = useDispatch();

  const { appointments } = useSelector(
    (state) => state.appointment
  );
  
  const user = getUser();
  const userdetails = getUserdetails();
  //const { user } = useSelector((state) => state.appointment);

  useLayoutEffect(() => {
    // Example: Fetch mailrooms when the component mounts

    if (user.role == 2) {
      async function fetchAfsprakenGebruiker() {
        try {
          const response = await AXIOS_GET(`afspraak/user/${user?.id}`);
          const { appointmentsNotInWacht, appointmentsInWacht } = response.data.data.reduce(
            (acc, item) => {
              if (item.admin_accept == 1) {
                acc.appointmentsNotInWacht.push(item);
              } else {
                acc.appointmentsInWacht.push(item);
              }
              return acc;
            },
            { appointmentsNotInWacht: [], appointmentsInWacht: [] }
          );

          dispatch(setAppointmentLoad(appointmentsNotInWacht));
          dispatch(setAppointmentLoadInWacht(appointmentsInWacht));
        } catch (error) {
          console.error("Error fetching afspraak", error);
        }
      }

      fetchAfsprakenGebruiker();
    } else {
      // Example: Fetch mailrooms when the component mounts
      async function fetchAfsprakenAlles() {
        try {
          const response = await AXIOS_GET(`afspraak/admin/${user?.id}`);
          dispatch(setAppointmentLoad(response.data.data));
        } catch (error) {
          console.error("Error fetching afspraak", error);
        }
      }

      fetchAfsprakenAlles();
    }
  }, []);

  return (
    <div className="h-full dark:bg-gray-800 gap-y-2 h-full gap-y-2">
      <BannerPlannig
        bannerName={"Planning"}
        Changeview={setView}
        index={view}
        views={Items}
      />
      <Calendar user={user} userdetails={userdetails} appointments={appointments} Component={Items[view]} />
    </div>
  );
};

export default Planning;
