import React, { useEffect } from "react";
import Banner from "../../Layout/Banner";

const RepeatSource = () => {
  
  return (
    <div>
      <Banner bannerName={"Hulpbronnen"} />
    </div>
  );
};

export default RepeatSource;
