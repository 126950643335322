import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React, { useMemo } from "react";
import PropTypes from "prop-types";

// Wrapping the component with React.memo to optimize re-renders
const Pagination = React.memo(({ setPageSize, setPage, page, pageSize, pageRanges = [10, 25, 50, 100, 150, 200] }) => {
  // Memoize the logic for the previous and next buttons
  const previous = () => setPage((prev) => (prev <= 0 ? 0 : prev - 1));
  const next = () => setPage((prev) => prev + 1);

  // Memoize the page range calculation to avoid recalculating on each render
  const pageRange = useMemo(() => {
    const start = page * Number(pageSize) + 1;
    const end = page * Number(pageSize) + Number(pageSize);
    return { start, end };
  }, [page, pageSize]);

  // Updated page size options to start from reasonable values
  const pageSizeOptions = useMemo(() => {
    return pageRanges;
  }, []);

  return (
    <div className="flex my-4 ml-4 sm:flex-none ">
      <select
        id="pagesize"
        name="pagesize"
        defaultValue={pageSize}
        onChange={(e) => setPageSize(Number(e.target.value))}
        className="border p-2 text-black dark:text-white bg-white dark:bg-gray-900"
      >
        {pageSizeOptions.map((size) => (
          <option key={size} value={size}>
            {size}
          </option>
        ))}
      </select>
      <button onClick={previous} className="px-4 border border-grey-100">
        <ChevronLeftIcon className="h-6 w-6 dark:text-white" />
      </button>
      <button className="px-4 border border-grey-100 dark:text-white rounded-l">
        {pageRange.start} - {pageRange.end}
      </button>
      <button onClick={next} className="px-4 border border-grey-100">
        <ChevronRightIcon className="h-6 w-6 dark:text-white" />
      </button>
    </div>
  );
});

// Add display name for debugging
Pagination.displayName = 'Pagination';

// Add PropTypes for type checking
Pagination.propTypes = {
  setPageSize: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default Pagination;
