import React, {
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  BanknotesIcon,
  Bars3Icon,
  BellIcon,
  BookOpenIcon,
  CalendarDaysIcon,
  CalendarIcon,
  ChartBarIcon,
  DocumentArrowDownIcon,
  DocumentArrowUpIcon,
  EnvelopeOpenIcon,
  FolderOpenIcon,
  HomeIcon,
  InboxArrowDownIcon,
  QueueListIcon,
  RectangleStackIcon,
  RocketLaunchIcon,
  ScaleIcon,
  UserPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  TableCellsIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
import {
  getUser,
  setAccesToken,
  setUserdetails,
  signOutUser,
} from "../Localstorage/localstorage";
import UserDialog from "../pages/signin/UserDialog";
import { AXIOS_GET } from "../server/api/crud";
import Spinner from "../Componenten/Spinner";

import "moment/locale/nl-be";
import { AuthContext } from "../context/AuthContext";
import Passwoord from "../Componenten/Password/Passwoord";
import RegisterModal from "../pages/signin/RegisterModal";
import SmallSideBar from "./Structure/SmallSideBar";
import SideBar from "./Structure/SideBar";
import CSVReader from "../Componenten/CSVReader";
import AllPatienten from "../Componenten/AllPatienten";
import RP_Avatar from "../Componenten/RP_Avatar";

const navigation = (user) => [
  { name: "Dashboard", href: "/dashboard", icon: HomeIcon, current: true },
  {
    name:
      user.role == 1
        ? "Patienten Dagboek"
        : "Mijn DagBoek",
    href: "/eetdagboek",
    icon: ScaleIcon,
    current: false,
  },
  {
    name: user.role == 1 ? "Patienten Vragenlijsten" : "Mijn Vragenlijsten",
    href: "/healthforms",
    icon: QueueListIcon,
    current: false,
  },
  {
    name: user.role == 1 ? "Afspraken" : "Mijn Afspraken",
    href: "/planning",
    icon: CalendarDaysIcon,
    current: false,
  },
  {
    name: user.role == 1 ? "Berichten" : "Mijn Berichten",
    href: "/mailbox",
    icon: InboxArrowDownIcon,
    current: false,
  },
  {
    name: user.role == 1 ? "Patienten Documenten" : "Mijn Documenten",
    href: "/documents",
    icon: FolderOpenIcon,
    current: false,
    children: [
      /*
      {
        name: "Mijn Intakes",
        href: "/documents/intakes",
        icon: DocumentArrowDownIcon,
        current: false,
      },*/
      {
        name: user.role == 2 ? "Mijn Advies" : "Patienten Advies",
        href: "/documents/advies",  
        icon: BellIcon,
        current: false,
      },
      {
        name: user.role == 2 ? "Mealplans/ Voedingsmiddelentabel/ Recepten" : "Patienten Mealplans/ Voedingsmiddelentabel/ Recepten",
        href: "/documents/plans",
        icon: RectangleStackIcon,
        current: false,
      },
      {
        name: user.role == 2 ? "Mijn Uploads" : "Patienten Uploads",
        href: "/documents/uploads",
        icon: DocumentArrowUpIcon,
        current: false,
      },
    ],
  },
  {
    name: user.role == 1 ? "Patienten Evoluties" : "Mijn Vooruitgang",
    href: "/evolution",
    icon: RocketLaunchIcon,
    current: false,
  },
  ...(user?.role == 2 ? [{
    name: "Specialisten",
    href: "/admins",
    icon: UserGroupIcon,
    current: false,
  }] : []),
  ...(user?.role == 1 && user?.hoofd_admin == 1 ? [{
    name: "Gebruikers",
    href: "/users",
    icon: UserGroupIcon,
    current: false,
  }] : [])
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Layout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPasswoordModal, setOpenPasswoordModal] = useState(false);
  const [openAdminsModal, setopenAdminsModal] = useState(false);
  const [openUsersModal, setopenUsersModal] = useState(false);

  const [openInscrijvingen, setOpenInschrijvingen] = useState(false);
  const [userdetails, setUserDetails] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const user = getUser();
  const { logout, login } = useContext(AuthContext); // Get the login function from AuthContext

  const toggleOpen = (name) => {
    setOpen((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const userNavigation = [
    { name: "Profiel", href: null },
    { name: "Passwoord wijzigen", href: null },
    { name: "Uitloggen", href: null },
  ];

  const fetchData = async (p_id) => {
    try {
  
  const response = await AXIOS_GET(`users/${p_id}`);

      const { first_name, last_name } = response.data.data[0];
      setUserDetails(response.data.data[0]);
      setUserdetails(response.data.data[0]); //-- localstorage

      if (!first_name && !last_name) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataAfter = async (p_id) => {
    try {
      const response = await AXIOS_GET(`users/${p_id}`);
      setUserDetails(response.data.data[0]);
      setUserdetails(response.data.data[0]); //-- localstorage
    } catch (error) {
      SignOUt();
      console.error("Error fetching data:", error);
    }
  };

  useLayoutEffect(() => {
    if (user) {
      const { id } = user;
      fetchData(id);
      //Redirect to home page if user is already logged in
    } else {
      navigate("/signin");
    }
  }, []); // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    if (user && !open) {
      const { id } = user;
      fetchDataAfter(id);
      //Redirect to home page if user is already logged in
    }
  }, [open]); // Empty dependency array ensures this effect runs only once

  function SignOUt() {
    signOutUser();
    logout();
    setAccesToken("");
    navigate("/signin");
    // This forces the page to reload and bypass the cache
    window.location.reload(true);
  }

  if (userdetails)
    return (
      <div className="h-dvh bg-red-500">
        <UserDialog user={user} open={open} setOpen={setOpen} />
        <Passwoord
          user={user}
          open={openPasswoordModal}
          setOpen={setOpenPasswoordModal}
        />
        <RegisterModal open={openAdminsModal} setOpen={setopenAdminsModal} />
        <SmallSideBar
          Fragment={Fragment}
          setSidebarOpen={setSidebarOpen}
          sidebarOpen={sidebarOpen}
        />
        <CSVReader open={openInscrijvingen} setOpen={setOpenInschrijvingen} />
        {/* Static sidebar for desktop */}
        <SideBar
          user={user}
          open={open}
          classNames={classNames}
          location={location}
          navigation={navigation}
          toggleOpen={toggleOpen}
        />

        <div
          className={`lg:pl-80 h-dvh dark:bg-gray-800 dark:text-white  ${
            userdetails?.role_id == 2 ? "bg-white" : "bg-gray-100"
          }`}
        >
          <div className="dark:bg-gray-800 sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <form className="relative flex flex-1" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
              </form>
              <div className="flex items-center gap-x-2 lg:gap-x-2">
                {user?.hoofd_admin == 1 && user?.role == 1 && (
                  <>
                    <button
                      type="button"
                      onClick={() => setopenAdminsModal(true)}
                      className="-m-0.5 p-2 bg-[#34D39933] dark:bg-[#34D39933] rounded-full text-gray-400 hover:text-gray-500"
                    >
                      <span className="sr-only">View notifications</span>
                      <UserPlusIcon
                        className="h-4 w-4 text-green-800 dark:text-white"
                        aria-hidden="true"
                      />
                    </button>
                    <button
                  type="button"
                  onClick={() => setOpenInschrijvingen(true)}
                  className="m-1 p-2 bg-gray-200 rounded-full text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <TableCellsIcon
                    className="h-4 w-4 text-gray-800"
                    aria-hidden="true"
                  />
                </button>
                  </>
                )}
            
                {/* Separator */}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <RP_Avatar avatar={userdetails?.avatar} size={8} alt={` ${userdetails?.full_name}`} />
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="dark:text-gray-100 ml-4 text-sm font-semibold leading-6 text-gray-900"
                        aria-hidden="true"
                      >
                        {userdetails?.full_name || user?.username}
                      </span>
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="dark:bg-gray-900 absolute dark:text-gray-100 right-0 z-10 mt-2.5 w-56 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              /*                               {...item.href && {href:item.href}} */
                              {...(item.name === "Uitloggen"
                                ? { onClick: () => SignOUt() }
                                : item.name === "Profiel"
                                ? {
                                    onClick: () => setOpen(true),
                                  }
                                : {
                                    onClick: () => setOpenPasswoordModal(true),
                                  })}
                              className={classNames(
                                active ? "bg-gray-50 dark:bg-gray-600" : "",
                                "block px-3 py-1 dark:bg-gray-900 dark:text-gray-100 text-sm leading-6 text-gray-900"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <div className="dark:bg-gray-900 h-dvh dark:text-white sm:px-0 lg:px-0">
            <Outlet />
          </div>
        </div>
      </div>
    );

  return <Spinner />;
};

export default Layout;
