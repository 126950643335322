import {
  CalendarDaysIcon,
  EnvelopeOpenIcon,
  InboxIcon,
  ListBulletIcon,
} from "@heroicons/react/20/solid";
import moment from "moment";
import React from "react";
import RP_Avatar from "../RP_Avatar";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DashboardlistPatient = ({ patienten, goToDetails }) => {
  return (
    <div className="rounded p-1  bg-gray-500 shadow">
      <table className="min-w-full divide-y divide-gray-300 rounded">
        <thead>
          <tr className="divide-x divide-gray-200 cursor-pointer">
            <th
              scope="col"
              className="py-3.5 px-4 text-left text-xs font-semibold text-white dark:text-gray-300"
            >
              Naam
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-xs font-semibold text-white dark:text-gray-300"
            >
              <div className="flex gap-3">
                Vragenlijsten
                <div className="h-4 w-4">
                  <ListBulletIcon />
                </div>
              </div>
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-xs font-semibold text-white dark:text-gray-300"
            >
              Eetdagboek
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-xs font-semibold text-white dark:text-gray-300"
            >
              <div className="flex gap-3">
                Afspraak
                <div className="h-4 w-4">
                  <CalendarDaysIcon />
                </div>
              </div>
            </th>
            {/* <th
              scope="col"
              className="px-3 py-3.5 text-left text-xs font-semibold text-white dark:text-gray-300"
            >
              <div className="flex gap-3">
                Mail
                <div className="h-4 w-4">
                  <InboxIcon />
                </div>
              </div>
            </th> */}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white dark:bg-gray-900 dark:text-gray-100">
          {patienten.length > 0 &&
            patienten.map((person) => (
              <tr
                className="divide-x divide-gray-200 cursor-pointer"
                key={person?.userdetails_user_id}
              >
                <td
                  onClick={() => goToDetails(person)}
                  className="whitespace-nowrap py-5 pl-4 pr-3 text-xs"
                >
                  <div className="flex items-center">
                    <div className="h-11 w-11 flex-shrink-0">
                      <RP_Avatar avatar={person?.userdetails_avatar} size={11} alt={` ${person?.userdetails_full_name}`} />
                    </div>
                    <div className="ml-4">
                      <div className="font-medium text-gray-900 dark:text-gray-100">
                        {person?.userdetails_full_name}
                      </div>
                      <div className="mt-1 text-gray-500 dark:text-gray-300">
                        {person?.userdetails_email}
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  onClick={() => goToDetails(person)}
                  className="whitespace-nowrap px-3 py-5 text-xs text-gray-500"
                >
                  <ul>
                    <li>
                      <span className="dark:text-gray-100">
                        Preintake formulier:
                      </span>
                      {person?.vragenlijsten_preintake_verstuur == 1 ? (
                        <span className="text-green-700"> Verstuurd </span>
                      ) : (
                        <span className="text-red-700"> Niet verstuurd </span>
                      )}
                    </li>
                    <li>
                      <span className="dark:text-gray-100">
                        klachtenregistratie formulier:
                      </span>
                      {person?.vragenlijsten_klacht_verstuur == 1 ? (
                        <span className="text-green-700"> Verstuurd </span>
                      ) : (
                        <span className="text-red-700"> Niet verstuurd </span>
                      )}
                    </li>
                    <li>
                      <span className="dark:text-gray-100">
                        VoedingsDagboek formulier:
                      </span>
                      {person?.vragenlijsten_voeding_verstuur == 1 ? (
                        <span className="text-green-700"> Verstuurd </span>
                      ) : (
                        <span className="text-red-700"> Niet verstuurd </span>
                      )}
                    </li>
                  </ul>
                </td>
                <td
                  onClick={() => goToDetails(person)}
                  className="whitespace-nowrap px-3 py-5 text-xs text-gray-500"
                >
                  {person?.eetdagboek_title ? (
                    <span className="dark:text-gray-300 dark:bg-gray-800 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                      {person?.eetdagboek_title}
                    </span>
                  ) : (
                    <span className="bg-gray-50"></span>
                  )}
                </td>
                <td className="whitespace-nowrap px-3 py-5 text-xs text-gray-500">
                  {" "}
                  <div className="flex flex-col">
                    {" "}
                    <span>
                      {person?.appointment_date &&
                        moment(person?.appointment_date).format(
                          "DD/MM/YYYY HH:MM"
                        )}
                    </span>
                    <span>
                      {person?.appointment_date && person?.appointment_type}
                    </span>
                  </div>
                </td>
                {/* <td
                  onClick={() => goToDetails(person)}
                  className="whitespace-nowrap px-3 py-5 text-xs text-gray-500"
                >
                  {" "}
                  <div className="flex gap-3">
                    {" "}
                    <div className="h-6 w-6">
                      <EnvelopeOpenIcon />
                    </div>{" "}
                    <span className="text-white rounded-full bg-red-500 px-2 py-1 ring-1 ring-inset ring-red-600/20">
                      2
                    </span>
                  </div>{" "}
                </td> */}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default DashboardlistPatient;
