import moment from "moment";
import React from "react";
import { CalculateAge } from "../../function";
import { getUserdetails } from "../../Localstorage/localstorage";
import { Attachments } from "../Documenten/Attachments";
import RP_Avatar from "../RP_Avatar";
import { UserCircleIcon } from "@heroicons/react/24/outline";

const UserDashboardInfo = () => {
  const userdetails = getUserdetails();
  return (
    <div className="pb-10 rounded mt-4">
      {/* Page header */}
      <div className="grid grid-cols-2">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
              <>
                {userdetails?.avatar? (
                  <img
                    className={`h-32 w-32 flex-none rounded-full  object-cover`}
                    src={userdetails?.avatar}
                    alt={userdetails?.full_name}
                  />
                ) : (
                  <UserCircleIcon
                    className={`h-32 w-32 text-gray-300 rounded-full  object-cover`}
                    aria-hidden="true"
                  />
                )}
              </>
              
                <span
                  aria-hidden="true"
                  className="absolute inset-0 rounded-full shadow-inner"
                />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                {userdetails?.full_name}
              </h1>
              <p className="text-sm font-medium text-gray-500 dark:text-gray-100">
                <a href="#">Geboortedatum</a> on{" "}
                <time dateTime="2020-08-25">{`${moment(
                  userdetails?.date_of_birth
                ).format("LL")} (${CalculateAge(
                  userdetails?.date_of_birth
                )} jaar)`}</time>
              </p>
            </div>
          </div>
        </div>
        <div>
          <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500 dark:text-green-500">
                Geregistreed op
              </dt>
              <dd className="mt-1 text-sm text-gray-900 dark:text-white">
                {moment(userdetails?.created_at).format("LLLL")}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-400">
                Email address
              </dt>
              <dd className="mt-1 text-sm text-gray-900 dark:text-white">
                {userdetails?.email}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-400">Klacht</dt>
              <dd className="mt-1 text-sm text-gray-900 dark:text-white">
                {userdetails?.klacht}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-400">Phone</dt>
              <dd className="mt-1 text-sm text-gray-900 dark:text-white">
                {userdetails?.phone_number}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-400">Situatie</dt>
              <dd className="mt-1 text-sm text-gray-900 dark:text-white">
                {userdetails?.more_details}
              </dd>
            </div>
            {/*attachments?.length > 0 && <Attachments attachments={attachments}/>*/}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default UserDashboardInfo;
