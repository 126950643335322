import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({ value = {score:null, opmerking:'', body:''}, setValue, editorKey }) => {
   // const [value, setValue] = useState({opmerking:''});
  
    const modules = {
      toolbar: [
        [{ 'header': [] }, { 'font': [] }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['link'],
        ['clean']                                         
      ],
    };
  
    const formats = [
      'header', 'font', 'list', 'bullet',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'color', 'background', 'align',
      'link'
    ];
  
    const handleChange = (content, delta, source, editor) => {

      setValue((prev) => ({...prev,opmerking:editor.getHTML()}));
    };
  
    return (
        <ReactQuill 
          style={{height:'60vh', marginBottom:20}}
          value={value?.opmerking || value?.body}
          onChange={handleChange}
          modules={modules}
          formats={formats}
        />
    );
  };
  
  export default TextEditor;