import { Radio, RadioGroup } from "@headlessui/react";
import React, { useEffect, useState } from "react";

import { radiobutton1 } from "../../klantenregister";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const RPRadioButtonSmall = ({
  legend,
  keyHoofd,
  categorie,
  updateKlachten,
  valueRadio,
  admin = null
}) => {
  const [selectedItems, setSelectedItems] = useState(
    radiobutton1
  );
  const [radio, setRadio] = useState(valueRadio);


  const onChangeRadio = (e) => {
    setRadio( e );
    updateKlachten(categorie,keyHoofd,e);
    setSelectedItems(e);
  }; 

console.log(admin != null || admin != undefined || admin != '', admin)
  return (
    <fieldset className="mb-4">
      <legend className="text-sm font-semibold leading-6 text-gray-900">
        {legend}
      </legend>
      <RadioGroup value={selectedItems} onChange={onChangeRadio} className="mt-2 grid grid-cols-3 gap-3 max-w-2xl sm:grid-cols-6">
        {radiobutton1.map((option) => (
          <Radio
            key={option.id}
            value={option.id}
            disabled={admin != null}
            className={({ focus, checked}) =>
              classNames(
                'cursor-pointer focus:outline-none' ,
                focus ? 'ring-2 ring-green-600 ring-offset-2' : '',
                (checked||(radio == option.id))
                  ? 'bg-green-600 text-white hover:bg-green-500'
                  : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
                'flex items-center justify-center rounded-md px-3 py-3 text-sm font-semibold uppercase sm:flex-1',
              )
            }

          >
            {option.id}
          </Radio>
        ))}
      </RadioGroup>
    </fieldset>
  );
};

export default RPRadioButtonSmall;
