/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import moment from "moment";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { MessageList, Input, Button } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import "./chat.css";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";

moment.locale("fr");

const ChatRoom = () => {
  return (
    <div className="h-100 d-inline-block w-100">
      <div className="message-container h-100">
        <MessageList
          className="message-list"
          lockable={true}
          toBottomHeight={"100%"}
          dataSource={[
            {
              position: "left",
              type: "text",
              title: "Kursat",
              text: "Give me a message list example !",
            },
            {
              position: "right",
              type: "text",
              title: "Emre",
              text: "That's all.",
            },
            {
              position: "left",
              type: "text",
              title: "Kursat",
              text: "Give me a message list example !",
            },
            {
              position: "right",
              type: "text",
              title: "Emre",
              text: "That's all.",
            },
          ]}
        />
      </div>
      <div className="grid justify-items-stretch fixed bottom-0 w-full">
        <div className="bg-white mb-4 flex ">
          <form class="relative flex flex-1 pl-4 pr-2" action="#" method="GET">
            <label for="search-field" class="sr-only">
              Schrijf naar onze support
            </label>
            <input
              id="body"
              class="block h-full w-100 rounded-md border-0 px-2 ring-1 ring-gray-200  pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              placeholder="Schrijf hier in..."
              type="text"
              name="body"
            />
          </form>
          <div className="justify-self-end pr-8">
            <button
              type="button"
              className="inline-flex items-center gap-2 rounded-md px-4 py-4 text-sm font-semibold bg-green-900 shadow-sm ring-1 ring-inset ring-green-700 hover:bg-gray-50"
            >
              {" "}
              <PaperAirplaneIcon
                className="-mr-0.5 h-5 w-5 text-white"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatRoom;
