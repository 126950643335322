// calendarUtils.js

import moment from "moment";

export const titleDayArray = ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag'];

export function generateDaysArrayForEetdagboek(currentMonth, selectedDay, events) {
  const startOfMonth = moment(currentMonth).locale('nl-be').startOf("isoWeek");
  const endOfMonth = moment(currentMonth).locale('nl-be').endOf("month").endOf("isoWeek");
  const days = [];
  let currentDate = moment(startOfMonth).locale('nl-be');

  while (currentDate.isSameOrBefore(endOfMonth)) {
    const isToday = currentDate.isSame(moment(), "day");
    const eventsForDay = events.filter(event => 
      moment(event?.datetime || event?.eetdagboek_datum).locale('nl-be').format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')
    );

    days.push({
      date: currentDate?.format("YYYY-MM-DD"),
      isCurrentMonth: currentDate.isSame(currentMonth, "month"),
      isToday,
      isSelected: selectedDay?.date === currentDate.format("YYYY-MM-DD"),
      events: eventsForDay,
    });
    currentDate.add(1, "day");
  }
  return days;
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
