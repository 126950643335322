import React, { useLayoutEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

const eve = [
  {
    title: "Breakfast",
    startTime: "06:00",
    endTime: "07:00",
    color: "bg-blue-50 text-blue-700 hover:bg-blue-100",
    colStart: 3,
  },
  {
    title: "Flight to Paris",
    startTime: "07:30",
    endTime: "09:30",
    color: "bg-pink-50 text-pink-700 hover:bg-pink-100",
    colStart: 3,
  },
  {
    title: "Meeting with design team at Disney",
    startTime: "10:00",
    endTime: "11:30",
    color: "bg-gray-100 text-gray-700 hover:bg-gray-200",
    colStart: 6,
  },
  {
    title: "Testing",
    startTime: "12:55",
    endTime: "19:55",
    color: "bg-gray-100 text-gray-700 hover:bg-gray-200",
    colStart: 1,
  },
];

// Function to calculate the grid row based on start and end times
const getGridRow = (startTime, endTime) => {
    const [startHour, startMinutes] = moment(startTime,'HH:mm:ss').format('HH:mm').toString().split(":").map(Number);
    const [endHour, endMinutes] = moment(endTime,'HH:mm:ss').format('HH:mm').toString().split(":").map(Number);
  
    // Calculate start and end grid rows
    const startRow = (startHour - 8) * 12 + Math.round(startMinutes / 5) + 2; // Offset by 2 for header
    const endRow = (endHour - 8) * 12 + Math.round(endMinutes / 5) + 2;
  
    // Calculate span between start and end rows
    const span = endRow - startRow;
  
    return `${startRow} / span ${span}`;
  };

const DayEvents = ({selectDate}) => {
  const {userdetails,user,appointments} = useSelector((state) => state.appointment);
  const [events, setEvents] = useState(eve);

  useLayoutEffect(() => {   
    let array = appointments.filter((item) => moment(item.date).locale('nl-be').format('YYYY-MM-DD') === moment(selectDate.date).locale('nl-be').format('YYYY-MM-DD'))
    setEvents(array);
  }, [selectDate]);

  return (
    <ol
      className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 "
      style={{
        gridTemplateRows: "1.75rem repeat(180, minmax(0, 1fr)) auto",
      }}
    >
      {events.map((event, index) => (
         <li
         className="relative mt-px flex"
         //style={{ gridRow: "74 / span 12" }}
         style={{ gridRow: getGridRow(event?.startTime, event?.endTime) }}

       >
          <a
            href="#"
            className={`group absolute inset-1 flex flex-col overflow-y-auto rounded-lg p-2 text-xs leading-5 bg-green-100 dark:bg-green-700`}
          >
            <div><p className="font-semibold">{event.user} - {event.type}</p></div>
            <div>
              <p className="group-hover:text-current">
              <time dateTime={`2022-01-12T${event.startTime}`}>
                {moment(event.startTime, "HH:mm").format("HH:mm")}
              </time>{" "}
              -{" "}
              <time dateTime={`2022-01-12T${event.endTime}`}>
                {moment(event.endTime, "HH:mm").format("HH:mm")}
              </time>
            </p></div>
            
          </a>
        </li>
      ))}
    </ol>
  );
};

export default DayEvents;
