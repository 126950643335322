import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import moment from "moment";
import CrudEvent from "./CrudEvent";
import ShowInfoAfspraak from "./ShowInfoAfspraak";
import { classNames, generateDaysArrayForMonth } from "./calenderUtilties";

// Title array for days of the week
const titleDayArray = [
  "Maandag",
  "Dinsdag",
  "Woensdag",
  "Donderdag",
  "Vrijdag",
  "Zaterdag",
  "Zondag",
];

const Header = ({ currentDate, onPrevious, onNext, setOpen }) => (
  <header className=" flex items-center justify-between border-b border-gray-200 dark:bg-gray-800  px-6 py-4 lg:flex-none">
    <button
      onClick={onPrevious}
      className="-m-1.5 flex p-1.5 text-gray-400 hover:text-gray-500"
    >
      <span className="sr-only">Previous month</span>
      <ChevronLeftIcon className="h-7 w-7" aria-hidden="true" />
    </button>
    <h1 className="text-base font-semibold leading-6 text-gray-90 dark:text-white">
      <time>{moment(currentDate).locale("nl-be").format("MMMM YYYY")}</time>
    </h1>
    <button
      onClick={onNext}
      className="-m-1.5 flex p-1.5 text-gray-400 hover:text-gray-500"
    >
      <span className="sr-only">Next month</span>
      <ChevronRightIcon className="h-7 w-7" aria-hidden="true" />
    </button>
    <ButtonGroup setOpen={setOpen} />
  </header>
);

const ButtonGroup = ({ setOpen }) => (
  <div className="flex items-center">
    <button
      onClick={() => setOpen(true)}
      className="ml-6 rounded-md bg-green-800 px-3 py-2 text-sm font-semibold text-white"
    >
      Afspraak maken
    </button>
    {/* Add Menu for additional actions */}
    <Menu as="div" className="relative ml-6">
      <Menu.Button className="-mx-2 flex items-center p-2 text-gray-400 hover:text-gray-500">
        <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>
      <Transition as={React.Fragment}>
        <Menu.Items className="absolute right-0 z-10 mt-3 w-36 bg-white rounded-md shadow-lg">
          {/* Add Menu Items here for "Day View", "Week View", etc. */}
        </Menu.Items>
      </Transition>
    </Menu>
  </div>
);

const DaysGrid = ({ days, showAfspraak }) => (
  <div className="flex bg-gray-200 text-xs lg:flex-auto">
    <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-5 lg:gap-px">
      {days.map((day) => (
        <Day key={day.date} day={day} showAfspraak={showAfspraak} />
      ))}
    </div>
  </div>
);

const Day = ({ day, showAfspraak }) => (
  <div
    className={classNames(
      day.isCurrentMonth
        ? "bg-white dark:bg-gray-700 dark:text-white"
        : "bg-gray-50 text-gray-500 dark:bg-gray-500 dark:text-white",
      "relative px-3 py-2 sm:h-28 h-32"
    )}
  >
    <time
      dateTime={day.date}
      className={
        day.isToday
          ? " flex h-6 w-6 items-center justify-center rounded-full bg-green-600 font-semibold text-white"
          : undefined
      }
    >
      {day.date.split("-").pop().replace(/^0/, "")}
    </time>
    {day?.events?.length > 0 && (
      <ol className="mt-2">
        {day?.events.slice(0, 2).map((event) => (
          <li
            key={event.id}
            className={classNames(
              "mt-1 px-1 rounded cursor-pointer",
              event.type === "Follow up - 46min"
                ? "bg-yellow-100"
                : "bg-green-100"
            )}
            onClick={() => showAfspraak(event)}
          >
            <a className="group flex">
              <p className="truncate font-medium text-gray-900">
                {event?.type}
              </p>
              <time
                dateTime={event?.datetime}
                className="ml-3 hidden xl:block text-gray-500"
              >
                {moment(event.startTime, "HH:mm:ss").format("HH:mm")} -{" "}
                {moment(event?.endTime, "HH:mm:ss").format("HH:mm")}
              </time>
            </a>
          </li>
        ))}
        {day?.events?.length > 2 && (
          <li className="text-gray-500">+ {day?.events?.length - 2} more</li>
        )}
      </ol>
    )}
  </div>
);

const MonthView = ({ user, userdetails, appointments }) => {
  const [currentDate, setCurrentDate] = useState(
    moment().locale("nl-be").format("YYYY-MM")
  );
  const [days, setDays] = useState(
    generateDaysArrayForMonth(currentDate, null, appointments)
  );
  const [open, setOpen] = useState(false);
  const [afspraak, setAfspraak] = useState({});
  const [openModalEdit, setOpenModalEdit] = useState(false);

  const showAfspraak = (afspr) => {
    setAfspraak(afspr);
    setOpenModalEdit(true);
  };

  useEffect(() => {
    setDays(generateDaysArrayForMonth(currentDate, null, appointments));
  }, [currentDate, appointments]);

  const handlePreviousMonth = () =>
    setCurrentDate(moment(currentDate).subtract(1, "month").format("YYYY-MM"));
  const handleNextMonth = () =>
    setCurrentDate(moment(currentDate).add(1, "month").format("YYYY-MM"));

  return (
    <div className="lg:flex lg:flex-col rounded border dark:border-gray-200">
      <Header
        currentDate={currentDate}
        onPrevious={handlePreviousMonth}
        onNext={handleNextMonth}
        setOpen={setOpen}
      />
      <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold">
          {titleDayArray.map((title, key) => (
            <div
              key={key}
              className="bg-white py-2 dark:bg-gray-700 dark:text-white"
            >
              {title}
            </div>
          ))}
        </div>
        <DaysGrid days={days} showAfspraak={showAfspraak} />
      </div>
      <CrudEvent openModel={open} setOpenModal={setOpen} />
      <ShowInfoAfspraak
        open={openModalEdit}
        setOpen={setOpenModalEdit}
        evenment={afspraak}
        user={user}
      />
    </div>
  );
};

export default MonthView;
