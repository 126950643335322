import React, { useState, useEffect, useRef } from "react";
import { Menu, Transition } from "@headlessui/react";
import moment from "moment";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";
import DayEvents from "./DayEvents";
import CrudEvent from "./CrudEvent";
import LittleCalendar from "./LittleCalendar";
import { generateDaysArrayForDay } from "./calenderUtilties";

// Utility function for class names
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = ({ date, day, setOpen }) => (
  <header className="flex flex-none items-center justify-between border-b border-gray-200 px-6 py-4">
    <div>
      <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
        <time dateTime="2022-01-22">{date}</time>
      </h1>
      <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">{day}</p>
    </div>
    <div className="flex items-center">
      <div className="hidden md:ml-4 md:flex md:items-center">
        <button
          type="button"
          onClick={() => setOpen(true)}
          className="ml-6 rounded-md bg-green-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
        >
          Afspraak maken
        </button>
      </div>
      <Menu as="div" className="relative ml-6 md:hidden">
        <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
          <span className="sr-only">Open menu</span>
          <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
        <Transition as={React.Fragment}>
          <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-transparent shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {/* Menu Items Here */}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  </header>
);

const DayNavigation = ({ containerNav }) => (
  <div
    ref={containerNav}
    className="sticky top-0 z-10 grid flex-none grid-cols-7 bg-transparent text-xs text-gray-500 shadow ring-1 ring-black ring-opacity-5 md:hidden"
  >
    <button type="button" className="flex flex-col items-center pb-1.5 pt-3">
      <span>W</span>
      {/* Default: "text-gray-900", Selected: "bg-gray-900 text-white", Today (Not Selected): "text-green-600", Today (Selected): "bg-green-600 text-white" */}
      <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
        19
      </span>
    </button>
    <button type="button" className="flex flex-col items-center pb-1.5 pt-3">
      <span>T</span>
      <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-green-600">
        20
      </span>
    </button>
    <button type="button" className="flex flex-col items-center pb-1.5 pt-3">
      <span>F</span>
      <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
        21
      </span>
    </button>
    <button type="button" className="flex flex-col items-center pb-1.5 pt-3">
      <span>S</span>
      <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full bg-gray-900 text-base font-semibold text-white">
        22
      </span>
    </button>
    <button type="button" className="flex flex-col items-center pb-1.5 pt-3">
      <span>S</span>
      <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
        23
      </span>
    </button>
    <button type="button" className="flex flex-col items-center pb-1.5 pt-3">
      <span>M</span>
      <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
        24
      </span>
    </button>
    <button type="button" className="flex flex-col items-center pb-1.5 pt-3">
      <span>T</span>
      <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
        25
      </span>
    </button>
  </div>
);

const TimeSlot = ({ containerOffset }) => {
  const hours = Array.from(
    { length: 15 },
    (_, i) => `${(i + 8).toString().padStart(2, "0")}:00`
  );
  return (
    <div
      className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
      style={{ gridTemplateRows: "repeat(15, minmax(4rem, 1fr))" }}
    >
      <div ref={containerOffset} className="row-end-1 h-7"></div>
      {hours.map((hour, index) => (
        <React.Fragment key={index}>
          <div>
            <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-800">
              <span className="text-sm dark:text-white">{hour}</span>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

const EventsArea = ({ selectDate, containerOffset }) => (
  <div className="grid flex-auto grid-cols-1 grid-rows-1">
    <TimeSlot containerOffset={containerOffset} />
    <DayEvents selectDate={selectDate} />
  </div>
);

const DayView = ({ userdetails, user, appointments }) => {
  const [open, setOpen] = useState(false);
  const [dayselected, setDayselected] = useState({});
  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);

  const formattedMonth = moment().locale("nl-be").format("MMMM DD, YYYY");
  const Daylong = moment().locale("nl-be").format("dddd");

  useEffect(() => {
    const currentMinute = new Date().getHours() * 60;
    container.current.scrollTop =
      ((container.current.scrollHeight -
        containerNav.current.offsetHeight -
        containerOffset.current.offsetHeight) *
        currentMinute) /
      1440;
  }, []);

  return (
    <div className="flex h-full flex-col rounded border dark:border-gray-200">
      <Header
        date={
          moment(dayselected.date).format("MMMM DD, YYYY") || formattedMonth
        }
        day={moment(dayselected.date).format("dddd") || Daylong}
        setOpen={setOpen}
      />
      <div className="isolate flex flex-auto overflow-hidden bg-transparent">
        <div ref={container} className="flex flex-auto flex-col overflow-auto">
          <DayNavigation containerNav={containerNav} />
          <div className="flex w-full flex-auto">
            <div className="w-14 flex-none bg-transparent ring-1 ring-gray-100" />
            <EventsArea
              selectDate={dayselected}
              containerOffset={containerOffset}
            />
          </div>
        </div>
        <CrudEvent openModel={open} setOpenModal={setOpen} />
        <LittleCalendar
          currentMonth={moment().format("YYYY-MM")}
          generateDaysArray={generateDaysArrayForDay}
          appointments={appointments}
          selectedDay={dayselected}
          setDayselected={setDayselected}
        />
      </div>
    </div>
  );
};

export default DayView;
