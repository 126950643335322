import React from 'react';
import PropTypes from 'prop-types';
import ReInput from '../../Componenten/Layout/ReInput';
import { getUser } from '../../Localstorage/localstorage';

const SingleNote = ({ onChange, formData, right, user }) => {
    return (
        <div className="col-span-full mt-4">
            <label htmlFor="dag" className="dark:text-white block text-sm font-medium leading-6 text-gray-900">
            Wat heb je vandaag gegeten?            
            </label>
            <div className="mt-2">
            {right == false ? <textarea
                    id="dag"
                    name="dag"
                    value={formData?.dag || ''}
                    onChange={onChange}
                    disabled={user?.role == 1 ? right : !formData?.title}
                    rows={15}
                    className="dark:bg-transparent dark:text-white block w-full rounded-md border-0 py-4 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />: <div dangerouslySetInnerHTML={{ __html: formData?.dag }}
                className={`mt-1 space-y-2 dark:text-white text-sm text-gray-800 p-4`} />}
            </div>
        </div>
    );
};

SingleNote.propTypes = {
    onChange: PropTypes.func.isRequired,
    formData: PropTypes.shape({
        tekst: PropTypes.string,
        title: PropTypes.string
    })
};

SingleNote.defaultProps = {
    formData: {
        tekst: '',
        title: ''
    }
};

const MultiNotes = ({ onChange, formData, right, user }) => {
    const notes = [
        { id: 1, tijd: "6:00 - 10:00", title: "s'Ochtends", name: "ochtend" },
        { id: 2, tijd: "10:00 - 12:00", title: "Voormiddag", name: "voormiddag" },
        { id: 3, tijd: "12:00 - 14:00", title: "s'Middags", name: "middag" },
        { id: 4, tijd: "14:00 - 18:00", title: "Namiddag", name: "namiddag" },
        { id: 5, tijd: "18:00 - 00:00", title: "s'Avonds", name: "avond" }
    ];

    return (
        <>
            {notes?.map((note) => (
                <div key={note.id} className="col-span-full mt-4">
                    <label htmlFor={note.name} className="block dark:text-white text-sm font-medium leading-6 text-gray-900">
                        {note.title} <i>{note.tijd}</i>
                    </label>
                    <div className="mt-2">
                {right == false ?
                            <textarea
                            id={note.name}
                            name={note.name}
                            value={formData[note.name] || ''}
                            onChange={onChange}
                            disabled={user?.role == 1 ? right : !formData?.title}
                            rows={5}
                            className="dark:bg-transparent dark:text-white block w-full rounded-md border-0 py-4 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                  : <div dangerouslySetInnerHTML={{ __html: formData[note.name] }}
                    className={`dark:text-white mt-1 space-y-2 text-sm text-gray-800 p-4`} />}
                      </div>
                </div>
            ))}
        </>
    );
};

MultiNotes.propTypes = {
    onChange: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired
};

const TimesNotes = ({ onChange, formData, right, user  }) => {
    const notes = [
        { id: 1, tijd: "6:00 - 10:00", title: "s'Ochtends", name: "smorgens" },
        { id: 2, tijd: "12:00 - 14:00", title: "s'Middags", name: "smiddags" },
        { id: 3, tijd: "18:00 - 00:00", title: "s'Avonds", name: "savonds" }
    ];

    return (
        <>
            {notes?.map((note) => (
                <div key={note.id} className="col-span-full mt-4">
                    <label htmlFor={note.name} className=" dark:text-white block text-sm font-medium leading-6 text-gray-900">
                        {note.title}
                    </label>
                    {right == false ? <div className="mt-2">
                        <textarea
                            id={note.name}
                            name={note.name}
                            value={formData[note.name] || ''}
                            onChange={onChange}
                            disabled={user?.role == 1 ? right : !formData?.title}
                            rows={4}
                            className="dark:bg-transparent dark:text-white block w-full rounded-md border-0 py-4 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div> : <div dangerouslySetInnerHTML={{ __html: formData[note.name] }}
                className={`dark:text-white mt-1 space-y-2 text-sm text-gray-800 p-4`} />}
                </div>
            ))}
        </>
    );
};

TimesNotes.propTypes = {
    onChange: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired
};

const TypeNote = ({ Eetdagboek, OnChange, admin=false }) => {
    let user = getUser()
    const { type_note } = Eetdagboek;
    const isSingleNote = type_note === 0;

    return (type_note == 1) ? (
        <SingleNote right={admin} user={user} onChange={OnChange} formData={Eetdagboek} />
    ) : (type_note == 2) ?  <TimesNotes right={admin} user={user} onChange={OnChange} formData={Eetdagboek} /> : (
        <MultiNotes right={admin} user={user} onChange={OnChange} formData={Eetdagboek} />
    );
};

TypeNote.propTypes = {
    Eetdagboek: PropTypes.shape({
        type_note: PropTypes.number.isRequired,
        tekst: PropTypes.string,
        title: PropTypes.string,
        ochtend: PropTypes.string,
        voormiddag: PropTypes.string,
        middag: PropTypes.string,
        namiddag: PropTypes.string,
        avond: PropTypes.string
    }).isRequired,
    OnChange: PropTypes.func.isRequired
};

export default TypeNote;
