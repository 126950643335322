// InputComponent.jsx
import React from 'react';

const ReInput = ({ type, id, name, label, value, placeholder,onChange,disabled=false , required = false}) => {

  
  const commonInputProps = {
    id,
    name,
    onChange,
    value,
    placeholder,
    className: 'block w-full text-lg rounded-md border-0 px-4 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-darkblue-600 sm:text-sm sm:leading-6',
  };

  let inputElement = null;

  switch (type) {
    case 'text':
      inputElement = <input disabled={disabled} required={required} type="text" {...commonInputProps} />;
      break;
    case 'email':
      inputElement = <input disabled={disabled} required={required} type="email" {...commonInputProps} />;
      break;
    case 'textarea':
      inputElement = <textarea disabled={disabled} rows={5} {...commonInputProps} />;
      break;
    case 'checkbox':
      inputElement = <input disabled={disabled} required={required} type="checkbox" {...commonInputProps} />;
      break;
    case 'radio':
      inputElement = <input  disabled={disabled} required={required} type="radio" {...commonInputProps} />;
      break;
    case 'date':
        inputElement = <input disabled={disabled} required={required} type="date" {...commonInputProps} />;
    break;
    case 'datetime':
        inputElement = <input disabled={disabled} required={required} type="datetime" {...commonInputProps} />;
    break;
    default:
      inputElement = <input disabled={disabled} required={required} type="text" {...commonInputProps} />;
  }

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2">{inputElement}</div>
    </div>
  );
};

export default ReInput;
