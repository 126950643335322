import React from 'react'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import RP_Avatar from '../RP_Avatar'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const DashboardPatientCards2 = ({patienten, goToDetails}) => {
  return (
<ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
      {patienten.map((person) =>  (
             <li   onClick={()=>goToDetails(person)}  key={person.id} className="overflow-hidden rounded-xl border border-gray-200 cursor-pointer">
             <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
               <RP_Avatar avatar={person?.avatar} size={12} alt={` ${person?.full_name}`} />
               
               <div className="text-sm font-medium leading-6 text-gray-900">
                <p>{person?.first_name}</p>
                <p>{person?.last_name}</p>

               </div>
             </div>
             <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
               <div className="flex justify-between gap-x-4 py-3">
                 <dt className="text-gray-500">Geboortedatum</dt>
                 <dd className="text-gray-700">
                   <time dateTime={person?.create_at}>{moment(person?.date_of_birth).format('LL')}</time>
                 </dd>
               </div>
               <div className="flex justify-between gap-x-4 py-3">
                 <dt className="text-gray-500">Adres</dt>
                 <dd className="flex items-start gap-x-2">
                   <div className="font-medium text-gray-900">{person?.adress_full}</div>
                   <div
                     className={classNames(
                       'rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
                     )}
                   > Patienten nummer: 
                     {person.id}
                   </div>
                 </dd>
               </div>
             </dl>
           </li>))}
</ul>
  )
}


export default DashboardPatientCards2

