import { BASE_URL } from "../constants/constants";


const { io } = require("socket.io-client");
export const IOSOCKETCLIENT = io(BASE_URL);

export const setIOSocketListeners = (channel, socket_channel, listenerCallBacks) => {
  if (channel) {
      if (IOSOCKETCLIENT.hasListeners(socket_channel)) {
          IOSOCKETCLIENT.off(socket_channel);
      }

      IOSOCKETCLIENT.emit('JOINLEAVEROOM', { action: 'join', channel });


      IOSOCKETCLIENT.on(socket_channel, (data) => {
          console.log('Received data on channel:', data); // Debugging line
          listenerCallBacks(data); // Call the listener callback
      });
  }
}