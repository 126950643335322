import React, { useState } from "react";
import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import ShowInfoAfspraakInWacht from "../Componenten/Calender/ShowInfoAfspraakInWacht";
import { getUserdetails } from "../Localstorage/localstorage";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const BannerPlannig = ({
  bannerName = "Dashboard",
  Changeview,
  index,
  views,
}) => {
  const userdetails = getUserdetails();
  const { appointmentsInWacht } = useSelector((state) => state.appointment);

  const [open, setOpen] = useState();
  return (
    <div className="flex mt-4 mx-8">
      <div className="md:flex md:items-center md:justify-between">
        <Menu as="div" className="relative w-50">
          <Menu.Button
            type="button"
            className="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            {views[index].name}
            <ChevronDownIcon
              className="-mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="w-60 absolute right-0 z-10 mt-1 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {views.map((view) => (
                  <Menu.Item key={view.id}>
                    {({ active }) => (
                      <div
                        onClick={() => Changeview(view.id)}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        {view.name}
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      {appointmentsInWacht.length > 0 && (
        <button
          onClick={() => setOpen(true)}
          className="bg-orange-800 text-white rounded ml-4 py-1 px-4 text-xs"
        >
          Afspraken nog niet bevestigd door de specialist (
          {appointmentsInWacht.length}).
        </button>
      )}
      <ShowInfoAfspraakInWacht
        evenments={appointmentsInWacht}
        user={userdetails}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
};

export default BannerPlannig;
