import { createSlice } from '@reduxjs/toolkit';
import { getAdmins, getUser, getUserdetails } from '../Localstorage/localstorage';

const documentenSlice = createSlice({
  name: 'document',
  initialState: {
    documenten:[]
  },
  reducers: {
    setDocumenten: (state, action) => {
      // Appends the new item to the existing mailRooms array
      state.documenten = [...state.documenten, action.payload];
    },
    setDocumentenLoad: (state, action) => {
        state.documenten = action.payload;
    }
  },
  
});

export const {
  setDocumenten,
  setDocumentenLoad
} = documentenSlice.actions;

export default documentenSlice.reducer;
