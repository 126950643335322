// src/components/HongerControle.js
import React, { useLayoutEffect, useState } from "react";
import {
  AXIOS_GET,
  AXIOS_POST,
  AXIOS_UPDATE,
} from "../../../../server/api/crud";
import Swal from "sweetalert2";
import { getUserdetails } from "../../../../Localstorage/localstorage";

// Generate buttons for selectable metric values
const GenerateButtons = ({ data, name, handleMetricChange }) => {
  return Array.from({ length: 11 }, (_, index) => (
    <div
      key={index}
      onClick={() => handleMetricChange(index, name)}
      className={`px-4 py-2 mx-1 font-semibold rounded-md border cursor-pointer ${
        data === index
          ? "bg-green-600 text-white"
          : "bg-transparent text-gray-700 border-gray-300 hover:bg-green-100"
      }`}
    >
      {index}
    </div>
  ));
};

const HongerControle = ({ setSelected, setOpen, voeding, userdetails }) => {
  const [formData, setFormData] = useState({
    honger_score: 9,
    eetlust_probleem: "",
    verlangens_frequentie: "",
    verlangens_reactie: "",
    emoties_verlangens_connectie: "",
    eetgedachten_frequentie: "",
    overeten_frequentie: "",
    reactie_op_overeten: "",
    maaltijd_overslaan: "",
    user_id: userdetails?.user_id,
    voeding_id: voeding?.id,
  });

  const handleMetricChange = (data, name) => {
    setFormData({ ...formData, [name]: data });
  };

  const getData = async () => {
    await AXIOS_GET(`voedsel/hongerenquete/user/${userdetails?.user_id}`).then(
      (res) => {
        if (res.status == 200) {
          if(res.data.data.length > 0)
          setFormData(res.data.data[0]);
        }
      }
    );
  };

  useLayoutEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSliderChange = (e) => {
    setFormData({ ...formData, honger_score: e.target.value });
  };

  const handleNextPage = async () => {
    const id = formData?.id;
    const respons = id
      ? await AXIOS_UPDATE(`voedsel/hongerenquete`, id, formData)
      : await AXIOS_POST(`voedsel/hongerenquete`, formData);

    if (respons.status == 200) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: `Uw HongerEnquete succesvol opgeslagen`,
        showConfirmButton: false,
        timer: 2500,
      });
      setSelected(2);
      AXIOS_UPDATE(`voedsel/status`,voeding?.id, {status:2}).then((respons) => {
        if (respons.status == 200) {
          console.log('status updated');
        }
      }).catch((error)=> {})
    }
  };

  const handlePrevPage = () => {
    alert("Vorige.");
    setSelected(0);
  };

  return (
    <div className="mt-4 p-8 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-6xl">
      <p className="text-center p-4 rounded-md bg-gray-200 ">
        Honger & Eetlust Vragenlijst
      </p>
      <form className="space-y-8 mx-16 my-12">
        {/* Question 1: Hunger Score */}
        <div>
          <label className="block font-medium mb-2 w-96">
            1. Hoe zou jij jouw honger en eetlust scoren?
          </label>
          <div className="flex flex-wrap mb-4">
            <GenerateButtons
              data={formData?.honger_score}
              name={"honger_score"}
              handleMetricChange={handleMetricChange}
            />
          </div>
          <div className="justify-between flex">
            <span>Nooit honger</span>
            <span>Normale eetlust</span>

            <span>Altijd honger</span>
          </div>
        </div>

        {/* Question 2: Appetite Issues */}
        <div>
          <label className="block font-medium mb-2">
            2. Heb jij het gevoel dat je eetlustproblemen hebt?
          </label>
          <select
            name="eetlust_probleem"
            value={formData?.eetlust_probleem}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          >
            <option value="">Selecteer een optie</option>
            <option value="Altijd">Ja, ik kan altijd eten</option>
            <option value="Soms">Soms/met momenten</option>
            <option value="Nee">Neen</option>
          </select>
        </div>

        {/* Question 3: Cravings Frequency */}
        <div>
          <label className="block font-medium mb-2">
            3. Heb je last van cravings?
          </label>
          <select
            name="verlangens_frequentie"
            value={formData?.verlangens_frequentie}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          >
            <option value="">Selecteer een optie</option>
            <option value="Vaak">Ja, vaak/altijd</option>
            <option value="Soms">Soms/met momenten</option>
            <option value="Zelden">Neen/zelden</option>
          </select>
        </div>

        {/* Question 4: Response to Cravings */}
        <div>
          <label className="block font-medium mb-2">
            4. Wat doe je wanneer je cravings hebt?
          </label>
          <select
            name="verlangens_reactie"
            value={formData?.verlangens_reactie}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          >
            <option value="">Selecteer een optie</option>
            <option value="LatenGaan">Ik laat me gaan</option>
            <option value="Gemengd">
              Soms laat ik me gaan, soms houd ik me in
            </option>
            <option value="Onderdrukken">Ik onderdruk het</option>
          </select>
        </div>

        {/* Question 5: Emotional Connection */}
        <div>
          <label className="block font-medium mb-2">
            5. Ervaar je soms een connectie tussen jouw emoties en cravings?
          </label>
          <div className="flex space-x-4">
            <label>
              <input
                type="radio"
                name="emoties_verlangens_connectie"
                value="Ja"
                checked={formData?.emoties_verlangens_connectie === "Ja"}
                onChange={handleChange}
              />
              Ja
            </label>
            <label>
              <input
                type="radio"
                name="emoties_verlangens_connectie"
                value="Nee"
                checked={formData?.emoties_verlangens_connectie === "Nee"}
                onChange={handleChange}
              />{" "}
              Neen
            </label>
          </div>
        </div>

        {/* Question 6: Thoughts About Eating */}
        <div>
          <label className="block font-semibold mb-2">
            6. Hoe vaak denk je aan eten/het vermijden van eten?
          </label>
          <select
            name="eetgedachten_frequentie"
            value={formData?.eetgedachten_frequentie}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          >
            <option value="">Selecteer een optie</option>
            <option value="Vaak">Vaak</option>
            <option value="Soms">Soms</option>
            <option value="Nooit">Nooit</option>
          </select>
        </div>

        {/* Question 7: Overeating Frequency */}
        <div>
          <label className="block font-semibold mb-2">
            7. Hoe vaak eet u tot op het punt dat je helemaal vol zit?
          </label>
          <select
            name="overeten_frequentie"
            value={formData?.overeten_frequentie}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          >
            <option value="">Selecteer een optie</option>
            <option value="Vaak">Vaak</option>
            <option value="Soms">Soms</option>
            <option value="Nooit">Nooit</option>
          </select>
        </div>

        {/* Question 8: Response to Overeating */}
        <div>
          <label className="block font-semibold mb-2">
            8. Wat doe je als je je hebt overeten?
          </label>
          <textarea
            name="reactie_op_overeten"
            value={formData?.reactie_op_overeten}
            onChange={handleChange}
            rows={8}
            className="w-full p-2 border rounded"
            placeholder="Beschrijf uw reactie..."
          />
        </div>

        {/* Question 9: Skipping Meals */}
        <div>
          <label className="block font-semibold mb-2">
            9. Hoe vaak sla je een maaltijd over of vast je bewust?
          </label>
          <select
            name="maaltijd_overslaan"
            value={formData?.maaltijd_overslaan}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          >
            <option value="">Selecteer een optie</option>
            <option value="Vaak">Vaak</option>
            <option value="Soms">Soms</option>
            <option value="Nooit">Nooit</option>
          </select>
        </div>
      </form>
      <div className="flex justify-between">
        <button
          onClick={() => setOpen(false)}
          className="mt-6 bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded"
        >
          Annuleer
        </button>
        <div className="flex gap-x-2">
          <button
            onClick={handlePrevPage}
            className="mt-6 bg-green-700 hover:bg-green-800 text-white py-2 px-4 rounded"
          >
            Vorige
          </button>
          <button
            onClick={handleNextPage}
            className="mt-6 bg-green-700 hover:bg-green-800 text-white py-2 px-4 rounded"
          >
            Volgende
          </button>
        </div>
      </div>
    </div>
  );
};

export default HongerControle;
