import { PencilSquareIcon, TrashIcon } from "@heroicons/react/20/solid";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import EditDocument from "./Calender/EditDocument";



export default function DashboardDocumenten({
  attachments,
  user,
  deleteDocument,
  type = 'documenten',
  setRefresh = () => {},
}) {

  const [open, setOpen] = useState(false);
  const [document, setDocument] = useState(null)

  const updateFile = (item) => {
    setDocument(item);
  }

  useEffect(() => {
    if(document){
      setOpen(true);
    }
  }, [document])

  useEffect(() => {
    if(!open)
    setRefresh(true);
  }, [open])




  return (
    <div className="px-4 sm:px-6 lg:px-8 h-dvh">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
            Documenten (aantal documenten geupload {attachments?.length > 0 ? attachments.length : 0})
          </h1>
          <p className="mt-2 text-sm text-gray-700 dark:text-gray-200">
            Hier vindt u de lijst van alle documenten die u hier heeft geüpload.{" "}
          </p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              {attachments?.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-300 ">
                  <thead className="bg-gray-50 dark:bg-gray-900">
                    <tr>
                      {user?.role_id == 2 && type != 'advies' && (
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      )}
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                      >
                        type
                      </th>
                    
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Dowload/Delete</span>
                        </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-transparent dark:bg-gray-700">
                    {attachments.map((document,key) => (
                      <tr key={key}>
                        {user?.role_id == 2 && type != 'advies' && (
                          <td className="relative whitespace-nowrap w-10 py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                            <div
                              onClick={()=>updateFile(document)}
                              className="text-green-600  flex justify-start hover:text-green-900"
                            >
                              <PencilSquareIcon className="h-6 w-6" />
                            </div>
                          </td>
                        )}
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-white">
                          {document?.title ||
                            "Gelieve een title in te geven..."}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-white">
                          {document?.bestand}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-white">
                          {document?.type}
                        </td>
              
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <div className="inline-flex">
                            <a
                            href={document?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-medium flex justify-end text-blue-600 hover:text-blue-500 mr-4"
                          >
                            <DocumentArrowDownIcon className="h-6 w-6" />
                          </a>
                          {user?.role_id == 2 && type != 'advies' && ( <div
                              onClick={()=>deleteDocument(document)}
                                className="text-red-600  flex justify-end hover:text-green-900"
                            >
                              <TrashIcon className="h-6 w-6" />
                            </div>  )}
                            </div>
                          
                          </td>
                      
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="text-center p-8 dark:bg-gray-800 bg-gray-100">
                  <p>Er zijn gedocumenten gevonden</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <EditDocument setOpen={setOpen} open={open} attachment={document}/>

    </div>
  );
}
