"use client";

import { useEffect, useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import TextEditor from "../TextEditor";
import {
  getAdmins,
  getUser,
  getUserdetails,
} from "../../Localstorage/localstorage";
import Select2 from "../Select2";
import TextEditorAll from "../TextEditorAll";
import uuid from "react-uuid";
import { useMail } from "../../pages/Mail/MailContext";
import { AXIOS_POST } from "../../server/api/crud";
import { useDispatch, useSelector } from "react-redux";
import { setMailMessage, setMailMessageItems, setMailMessageItemsFilter, setMailRooms } from "../../redux/mailSlice";
import SelectPatienten from "../SelectPatienten";
import SelectAdmins from "../SelectAdmins";

const categories = [
  "Intake formulier (Vragenlijsten)",
  "Pretintake formulier (Vragenlijsten)",
  "Klachternformulier formulier (Vragenlijsten)",
  "Mijn Afspraken",
  "Daschboard",
  "Mijn dagelijkse gezondheidscontrole (eetdagboek)",
  "Mijn Documenten",
  "Mijn Voortuigang",
  "Andere",
];

const RPDrawerMessaging = ({
  messages,
  chatroom,
  open = true,
  setOpen = null,
}) => {
  const dispatch = useDispatch();
  const {
    mailRooms,
    mailMessage,
    command,
  } = useSelector((state) => state.mail);
  
  const user = getUser();
  const userdetails = getUserdetails();
  const {
    patienten,
    admins,
  } = useSelector((state) => state.user);

const gebruikers = user.role == 2 ? admins[0] : patienten[0];

  const [selected, setSelected] = useState(gebruikers);
  const [formData, setFormData] = useState({});
  const [category, setCategory] = useState(categories[0])
  const [mailNewMessage, setNewmailMessage] = useState({});

  const saveMessage = async () => {
    try {
      const idMessage = uuid(); // Generate unique ID for the mailmessage
      const currentDate = moment().format("YYYY-MM-DD");
      const currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss"); // Correct format
  
      // Create a base message item (detail) object
      const newItemMessage = {
        ...formData,
        id: uuid(),
        mailmessage_id: command === "insert" ? idMessage : mailMessage?.id,
        author_id: userdetails?.user_id,
        author: userdetails?.full_name,
        avatar: userdetails?.avatar,
        datetime: currentDateTime,
      };
  
      if (command === "insert") {
        // Create the mailmessage (master) object
        const newMailMessage = {
          id: idMessage, // Use the generated ID for the mailmessage
          subject: mailNewMessage?.subject,
          sender: user.role == 2 ? userdetails?.full_name : selected?.full_name,
          sender_id: user.role == 2 ? userdetails?.user_id : selected?.user_id,
          receiver: user.role == 1 ? userdetails?.full_name : selected.full_name,
          receiver_id: user.role == 1 ? userdetails?.user_id : selected.user_id,
          category: category,
          date: currentDate,
          datetime: currentDateTime,
          status: "Send",
          preview: "",
        };
  
        // Insert the mailmessage (master record) first
        const responseMailMessage = await AXIOS_POST(
          `mailbox/mailmessage`,
          newMailMessage
        );
  
        if (responseMailMessage?.status) {
          dispatch(setMailMessage(newMailMessage));
          dispatch(setMailRooms(newMailMessage));
  
          // Proceed to insert the messageitem (detail record) after the mailmessage
          const responseNewItemMessage = await AXIOS_POST(
            `mailbox/message`,
            newItemMessage
          );
  
          if (responseNewItemMessage?.status) {
            dispatch(setMailMessageItemsFilter(newItemMessage));
            dispatch(setMailMessageItems(newItemMessage));
          } else {
            console.error("Error inserting message item:", responseNewItemMessage);
          }
        } else {
          console.error("Error inserting mail message:", responseMailMessage);
        }
      } else {
        // If updating, directly insert the message item
        const responseNewItemMessage = await AXIOS_POST(
          `mailbox/message`,
          newItemMessage
        );
  
        if (responseNewItemMessage?.status) {
          console.log("Message item inserted successfully:", responseNewItemMessage);
          dispatch(setMailMessageItemsFilter(newItemMessage));
          dispatch(setMailMessageItems(newItemMessage));
        } else {
          console.error("Error inserting message item:", responseNewItemMessage);
        }
      }
  
      // Reset form data after successful insertions
      setFormData({});
      setNewmailMessage({});
      setOpen(false);
    } catch (error) {
      console.error("Error saving message:", error);
    }
  };
  
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewmailMessage((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10 bg-red">
      <div className="fixed inset-0" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-2xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <button
                type="button"
                onClick={() => setOpen(false)}
                className={`absolute rounded-md right-0 m-4 bg-gray-100 text-green-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-white`}
              >
                <span className="absolute -inset-2.5" />
                <span className="sr-only">Close panel</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
              <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                {command == "insert" && (
                  <div
                    className={`${
                      user.role == 1 ? "bg-white" : "bg-gray-100"
                    } px-4 py-6 sm:px-6`}
                  >
                    <div className="messages-center">
                      <DialogTitle className="text-base font-semibold leading-6 text-white">
                        <div className="mt-2">
                          <h1 className="text-lg">{chatroom?.subject}</h1>
                          <i className="text-xs">{chatroom?.author}</i>
                        </div>
                        <div>
                        <label
                          htmlFor="location"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Categorie
                        </label>
                        <select
                          id="category"
                          name="category"
                          defaultValue={category}
                          onChange={(e)=> setCategory(e.target.value)}
                          className="mt-2 block w-full rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          {
                            categories.map((category,index)=> <option key={index} >{category}</option>)
                          }
                        </select>
                      </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-2">
                            Subject
                          </label>
                          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-lg">
                            <input
                              type="text"
                              name="subject"
                              placeholder="Subject"
                              value={mailNewMessage?.subject}
                              onChange={handleChange}
                              className="block flex-1 border-0 bg-transparent py-3 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </DialogTitle>
                      <div className="ml-0 mt-4 messages-center gap-x-8 flex h-7 flex-cols">
                      {user?.role == 2 && admins?.length > 0 && (
                         <SelectAdmins selected={selected ? selected : admins[0]} setSelected={setSelected} data={admins} />
                        )}
                 {user?.role == 1 && patienten?.length > 0 && (
                          <SelectPatienten selected={selected ? selected :  patienten[0]} setSelected={setSelected} data={patienten} />
                        )}
                      </div>

                    </div>
                    <p className="text-green-100 text-xs">
                      <time dateTime={mailMessage?.datum}>
                        {moment(formData?.datum)
                          .locale("nl-be")
                          .format("DD/MM/YYYY hh:mm")}
                      </time>
                    </p>
                  </div>
                )}
                <div className={`${command !== "insert" && "mt-16"} px-6 relative`}>
                  <TextEditorAll
                    value={formData?.body}
                    editorKey={"body"}
                    setValue={setFormData}
                    key={"opmerking"}
                  />
                </div>
                <div className="flex flex justify-between messages-end relative mt-10">
                  <div className="flex flex-shrink-0 justify-end px-6 py-4">
                    <button
                      type="button"
                      onClick={() => setOpen(false)}
                      className={`rounded-md bg-white px-3 py-2 h-10 text-sm font-semibold text-green-900 shadow-sm ring-1 ring-inset ${
                        user.role == 1 ? "ring-gray-700" : "ring-green-800"
                      } hover:ring-green-400`}
                    >
                      Annuleer
                    </button>
                    <button
                      onClick={saveMessage}
                      type="submit"
                      className={`ml-2 rounded-md ${
                        user.role == 1 ? "bg-gray-700" : "bg-green-800"
                      } px-3 py-2 h-10 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ${
                        user.role == 1 ? "ring-gray-700" : "ring-green-800"
                      } hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500`}
                    >
                      Opslaan
                    </button>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default RPDrawerMessaging;
