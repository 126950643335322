import axios from 'axios';
import { BASE_URL, BASE_URL_API } from '../../constants/constants';
import { getAccesToken, setAccesToken } from '../../Localstorage/localstorage';

// Timezone for requests
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Build headers function to include the access token
const buildHeaders = () => {
  const accessToken = getAccesToken();  // Retrieve access token from storage
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;  // Add access token to Authorization header
  }
  return headers;
};

// Refresh Access Token Function
const refreshAccessToken = async () => {
  try {
    const response = await axios.post(`${BASE_URL_API}/refresh-token`, {}, { withCredentials: true });
    const { accessToken } = response.data;
    setAccesToken(accessToken); // Update access token in local storage
    return accessToken;
  } catch (error) {
    console.error("Failed to refresh access token:", error);
    throw error;
  }
};

// Axios instance with interceptor for automatic token refresh
const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;  // Mark the request to avoid infinite loop

      try {
        const newAccessToken = await refreshAccessToken();  // Refresh access token
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;  // Update header with new token
        return axiosInstance(originalRequest);  // Retry the original request
      } catch (refreshError) {
        console.error("Refresh token failed. Redirecting to login.");
        // Handle redirect to login or logout the user
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

// Axios requests setup
export const AXIOS_WITHOUT_ACCESSTOKEN = (p_url, data) => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/klantenportaal/${p_url}`,
    data: data,
  });
};

export const AXIOS_GET = async (p_url) => {
  const headers = buildHeaders();
  return axiosInstance.get(`${BASE_URL_API}/${p_url}`, { headers });
};

export const AXIOS_POST = async (p_url, data) => {
  const headers = buildHeaders();
  return axiosInstance.post(`${BASE_URL_API}/${p_url}`, data, { headers });
};

export const AXIOS_DELETE = async (p_url, id, data = {}) => {
  const headers = buildHeaders();
  return axiosInstance.delete(`${BASE_URL_API}/${p_url}/${id}`, {
    headers,
    data: { ...data, timeZone: timezone },
  });
};

export const AXIOS_UPDATE = async (p_url, id, data) => {
  const headers = buildHeaders();
  return axiosInstance.patch(`${BASE_URL_API}/${p_url}/${id}`, { ...data, timeZone: timezone }, { headers });
};

export const AXIOS_UPDATE_MORE = async (p_url, ids, data) => {
  const headers = buildHeaders();
  const idsString = Array.isArray(ids) ? ids.join(',') : ids;
  return axiosInstance.patch(`${BASE_URL_API}/${p_url}/${idsString}`, { ...data, timeZone: timezone }, { headers });
};

export const AXIOS_UPLOAD_IMAGE = async (p_url, id, imageFile) => {
  const headers = buildHeaders();
  const formData = new FormData();
  formData.append('avatar', imageFile);
  return axiosInstance.post(`${BASE_URL_API}/${p_url}/upload/${id}`, formData, {
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const AXIOS_UPLOAD_DOCUMENTS = async (p_url, formData) => {
  const headers = buildHeaders();
  return axiosInstance.post(`${BASE_URL_API}/${p_url}`, formData, {
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const AXIOS_UPLOAD_DOCUMENT = async (p_url, formData) => {
  const headers = buildHeaders();
  return axiosInstance.post(`${BASE_URL_API}/${p_url}`, formData, {
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
  });
};
