import { UserCircleIcon } from "@heroicons/react/24/outline";
import React from "react";

const RP_Avatar = ({avatar,size, alt = "profiel foto"}) => {
  return (
    <>
      {avatar ? (
        <img
          className={`h-${size} w-${size} flex-none rounded-full  object-cover`}
          src={avatar}
          alt={alt}
        />
      ) : (
        <UserCircleIcon
          className={`h-${size} w-${size} text-gray-300 rounded-full  object-cover`}
          aria-hidden="true"
        />
      )}
    </>
  );
};

export default RP_Avatar;
