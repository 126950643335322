import { createSlice } from '@reduxjs/toolkit';
import { getAdmins, getUser, getUserdetails } from '../Localstorage/localstorage';

const commentSlice = createSlice({
  name: 'mail',
  initialState: {
    comments:[],
  },
  reducers: {
    setComments: (state, action) => {
      // Appends the new item to the existing comments array
      state.comments = [...state.comments, action.payload];
    },
    setCommentsLoad: (state, action) => {
        state.comments = action.payload;
    },
    setCommentsUpdate: (state, action) => {
      state.comments = state.comments.map(comment => 
        comment.id === action.payload.id 
            ? { ...comment, ...action.payload }  // Merge existing comment with updatedComment
            : comment // Leave other comments unchanged
    );
  },
  },
  
});

export const {
  setComments,
  setCommentsLoad,
  setCommentsUpdate
} = commentSlice.actions;

export default commentSlice.reducer;
