import { ArrowUpRightIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import moment from "moment/min/moment-with-locales";
import React from "react";
import { getUser } from "../../../Localstorage/localstorage";


const DagBoek = ({ toonEdit, item, editTekst}) => {
const user = getUser();
  return (
    <li
      onClick={() => toonEdit(item)}
      key={item?.eetdagboek_id}
      className="flex items-center justify-between px-4 rounded shadow-sm bg-white gap-x-6 py-5 mb-2 cursor-pointer"
    >
      <div className="min-w-0">
        <div className="flex items-start gap-x-3">
          <p className="text-sm font-semibold leading-6 text-gray-900">
            {item?.eetdagboek_title}
          </p>
        </div>
        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
          <p className="whitespace-nowrap">
            <time dateTime={item?.eetdagboek_datum}>{moment(item?.eetdagboek_datum).locale('nl-be').format('DD/MM/YYYY LT')}</time>
          </p>
{/*           <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
            <circle cx={1} cy={1} r={1} />
          </svg>
          <p className="truncate">Created by {item?.userdetails_full_name}</p> */}
        </div>
      </div>
      <div className="flex flex-none items-center gap-x-4">
      <button
        type="button"
        {...(user.role === 2 ? { onClick: () => editTekst(item, 'wijzigen') } : {})}
        className="rounded-full bg-green-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
       {user.role == 2 ? <PencilSquareIcon className="h-5 w-5" aria-hidden="true" /> : <ArrowUpRightIcon className="h-5 w-5" aria-hidden="true"  />}
      </button>	
      </div>
    </li>
  );
};

export default DagBoek;
