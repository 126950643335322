import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import React from 'react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  

export default function RPCardOne({item}) {
  return (
    <li key={item.name} className="col-span-1 flex rounded-md shadow-sm mt-4">
    <div >
         <span
              className={classNames(
                item.iconBackground,
                item.iconForeground,
                'inline-flex rounded-lg p-3',
              )}
            >
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </span>
    </div>
    <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-gray-200 bg-white">
      <div className="flex-1 truncate px-4 py-2 text-sm">
        <a href={item.href} target="_blank" rel="noopener noreferrer" className="font-medium text-gray-900 hover:text-gray-600">
          {item.title}
        </a>
      </div>
    </div>
  </li>
  )
}
