import React, { useState, useEffect } from "react";
import moment from "moment";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

// Helper function for class names (you can also use a library like clsx)
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const LittleCalendarBordeless = ({
  currentMonth,
  selectedDay,
  generateDaysArray,
  setDayselected,
  appointments,
}) => {
  const [currentDate, setCurrentDate] = useState(moment(currentMonth));
  const [days, setDays] = useState([]);

  useEffect(() => {
    setDays(generateDaysArray(currentDate, selectedDay, appointments));
  }, [currentDate, selectedDay,appointments]);

  const handlePreviousMonth = () => {
    setCurrentDate(currentDate.clone().subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setCurrentDate(currentDate.clone().add(1, "month"));
  };


  return (
    <div className="md:pr-14">
      <div className="flex items-center">
        <h2 className="flex-auto text-sm font-semibold text-gray-900 dark:text-gray-300">
          {moment(currentDate).locale('nl-be').format('MMMM YYYY')}
        </h2>
        <button
          type="button"
          onClick={handlePreviousMonth}
          className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          onClick={handleNextMonth}
          className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500 dark:text-white">
        <div>Ma</div>
        <div>Di</div>
        <div>Wo</div>
        <div>DO</div>
        <div>Vr</div>
        <div>Za</div>
        <div>Zo</div>
      </div>
      <div className="mt-2 grid grid-cols-7 text-sm">
        {days.map((day, dayIdx) => (
          <div
            key={day.date}
            className={classNames(
              dayIdx > 6 && "border-t border-gray-200",
              "py-2"
            )}
          >
            <button
              type="button"
              onClick={()=>setDayselected(day)}
              className={classNames(
                day.isSelected && "text-white",
                day?.events?.length > 0 && "bg-green-200",
                !day.isSelected && day.isToday && "bg-green-600 text-white",
                !day.isSelected &&
                  !day.isToday &&
                  day.isCurrentMonth &&
                  "text-gray-900",
                !day.isSelected &&
                  !day.isToday &&
                  !day.isCurrentMonth &&
                  "text-gray-400",
                day.isSelected && day.isToday && "bg-indigo-600",
                day.isSelected && !day.isToday && "bg-gray-900",
                !day.isSelected && "hover:bg-gray-200",
                (day.isSelected || day.isToday) && "font-semibold",
                "mx-auto flex h-8 w-8 items-center justify-center rounded-full"
              )}
            >
              <time dateTime={day?.date} className={
                classNames(
                  "mx-auto flex h-7 w-7 items-center justify-center dark:text-white rounded-full",
                  day.isSelected && day.isToday && "bg-green-600",
                  day?.events?.length > 0 && "bg-green-300 dark:bg-green-700 dark:text-green-100",
                  day.isToday && "bg-green-800",
                  day.isCurrentMonth ? "dark:text-white" : "dark:text-green-400"
                 
                )
              }>
                {day?.date.split("-").pop().replace(/^0/, "")}
              </time>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LittleCalendarBordeless;


