import { useCallback, useState } from "react";
import Swal from "sweetalert2";
import { AXIOS_UPDATE } from "../../../server/api/crud";
import PersoonlijkeInfo from "./details/PersoonlijkeInfo";
import HulpVraag from "./details/HulpVraag";
import Gezondheidsprofiel from "./details/Gezondheidsprofiel";
import { savePreIntakeForm, updateOpmerking } from "../../../apiCalls/preintake";

const PreIntakeForm = ({
  formData,
  setFormData,
  setOpen,
  user,
  admin = null,
}) => {
  const isadmin = admin == null ? false : true;
  const [errors, setErrors] = useState({});
  const [editeer, setEditeer] = useState(isadmin);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else if (type === "select-multiple") {
      const selectedOptions = Array.from(e.target.selectedOptions).map(
        (option) => option.value
      );
      setFormData((prevData) => ({
        ...prevData,
        [name]: selectedOptions,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    validateField(name, value);
  };

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "age":
        if (!value || isNaN(value) || value <= 0)
          error = "Voer een geldige leeftijd in.";
        break;
      case "weight":
        if (!value || isNaN(value) || value <= 0)
          error = "Voer een geldig gewicht in.";
        break;
      case "height":
        if (!value || isNaN(value) || value <= 0)
          error = "Voer een geldige lengte in.";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: error });
  };

  const onChangeRadio = (name, e) => {
    //setRadio({ ...radio, value: e });
    setFormData((prevData) => ({
      ...prevData,
      [name]: e,
    }));
  };

  const saveFormulier = useCallback(async (e) => {
    e.preventDefault();

    await savePreIntakeForm(e, user,formData, admin, setOpen,errors,false);
  },[user,formData,admin,setOpen,errors]);

  const definitiefOpslaanFormulier = useCallback(async (e) => {
    e.preventDefault();
    await savePreIntakeForm(e, user,formData, admin, setOpen,errors,true);
  },[user,formData,admin,setOpen,errors]);

  const OpmerkingPlaatsen = useCallback(async (e) => {
    e.preventDefault();
    console.log(user)
   updateOpmerking(user,formData, admin, setOpen);
  },[user,formData,admin,setOpen]);

  return (
    <div className="">
      <h2 className="text-center text-2xl">Pre-intake vragenlijst</h2>
      <form>
        <PersoonlijkeInfo
          editeer={editeer}
          errors={errors}
          formData={formData}
          handleChange={handleChange}
          onChangeRadio={onChangeRadio}
        />
        <HulpVraag
          editeer={editeer}
          formData={formData}
          handleChange={handleChange}
          onChangeRadio={onChangeRadio}
        />
        <Gezondheidsprofiel
          editeer={editeer}
          formData={formData}
          handleChange={handleChange}
          onChangeRadio={onChangeRadio}
        />
        {admin != null && (
          <div className="mt-8">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Opmerking
            </label>
            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
              <textarea
                rows={5}
                name="opm_admin"
                value={formData.opm_admin}
                onChange={handleChange}
                rows="5"
                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        )}
      </form>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        {admin == null ? (
          <>
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-green-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
              onClick={(e) => definitiefOpslaanFormulier(e)}
            >
              Opslaan en Verzenden
            </button>
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-yellow-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 sm:ml-3 sm:w-auto"
              onClick={(e) => saveFormulier(e)}
            >
              Opslaan en later verder doen
            </button>
          </>
        ) : (
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-green-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
            onClick={OpmerkingPlaatsen}
          >
            Opmerking plaatsen
          </button>
        )}
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => setOpen(false)}
        >
          Annuleer
        </button>
      </div>
    </div>
  );
};

export default PreIntakeForm;
