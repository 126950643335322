import { configureStore } from '@reduxjs/toolkit';
import mailReducer from './mailSlice'; // Assuming all state is in mailSlice
import appointmentReducer from './appointmentSlice';
import userReducer from './userSlice';
import documentReducer from './documentenSlice';
import commentReducer from './commentSlice';
import userCalendlyReducer from './userCalendlySlice';

export const store = configureStore({
  reducer: {
    mail: mailReducer,
    appointment:appointmentReducer,
    user:userReducer,
    document:documentReducer,
    comment:commentReducer,
    calendlyafspraak:userCalendlyReducer
  },
});
