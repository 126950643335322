import React, { useState } from "react";
import { DocumentIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import FileCardAdvies from "../../Componenten/MiniCards/FileCardAdvies";
import FilterNavigation from "../../Componenten/Dashboard/FilterNavigation";
import DashboardDocumenten from "../../Componenten/DashboardDocumenten";

const filters = [
  { value: 1, name: "list", icon: TableCellsIcon, color: "text-green-500" },
  { value: 2, name: "block", icon: DocumentIcon, color: "text-green-500" },
];

const PlansClient = ({
  userdetails,
  attachments,
  setAttachments,
  type = "uploads",
}) => {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(filters[0]);

  return (
    <div className="mx-auto max-w-6xl lg:max-w-full lg:px-4 pt-10 h-full dark:bg-gray-800 h-dvh">
      <div className="px-4 flex items-center justify-between">
        <FilterNavigation
          selectedRadio={view}
          setSelectedRadio={setView}
          options={filters}
        />
      </div>
      {view?.value === 1 ? (
        <DashboardDocumenten
          attachments={attachments}
          type="advies"
          user={userdetails}
        />
      ) : (
        <div className="grid grid-cols-3 xxl:grid-cols-4 p-4">
          {attachments?.length > 0 ? (
            attachments.map((item) => (
              <FileCardAdvies item={item} userdetails={userdetails} />
            ))
          ) : (
            <div className="text-center p-8 dark:bg-gray-800 bg-gray-100 border-1 border-gray-700">
              <p>Er zijn gedocumenten gevonden</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PlansClient;
