import React from 'react'
import DagBoek from './DagBoek'

const DagBoekList = ({dagboeken, editTekst,showText}) => {
  return (
    <ul  className="divide-y divide-gray-100 w-auto">
    {dagboeken.map((dagboek, index) => (
      <DagBoek key={index} editTekst={editTekst} toonEdit={showText} item={dagboek}/>
    ))}
    </ul>
  )
}

export default DagBoekList
