import React from "react";
import Banner from "../../Layout/Banner";

const Payment = () => {
  return (
    <div>
      <Banner bannerName={"Mijn Betalingen"} />
    </div>
  );
};

export default Payment;
