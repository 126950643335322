import { UserCircleIcon } from "@heroicons/react/24/solid";
import ReInput from "../../Componenten/Layout/ReInput";
import { useRef } from "react";
import ImageUpload from "../../Componenten/ImageUpload/ImageUpload";
import { getUser } from "../../Localstorage/localstorage";
import moment from "moment";

const UserInfo = ({ formData, handleChangeInput, setFormData }) => {
  const fileInputRef = useRef(null);
  let user = getUser();

  return (
    <div className="">
      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <ImageUpload
          user={user}
          fileInputRef={fileInputRef}
          formData={formData}
          setFormData={setFormData}
        />
        <div className="col-span-3">
          <ReInput
            type="date"
            id="date_of_birth"
            name="date_of_birth"
            label="Geboortedatum"
            placeholder="Vul uw geboortedatum in"
            value={moment(formData?.date_of_birth).format('YYYY-MM-DD')}
            onChange={handleChangeInput}
          />
          <div className="mt-3">
            <ReInput
              type="email"
              id="email"
              name="email"
              label="Email"
              required={true}
              placeholder="Vul uw emailaddress in"
              value={formData?.email}
              onChange={handleChangeInput}
            />
          </div>
        </div>
      </div>

      {user?.role == 1 ? (
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <ReInput
              type="text"
              id="beroep"
              name="beroep"
              label="Beroep"
              required={true}
              placeholder="Vul je beroep in"
              value={formData?.beroep}
              onChange={handleChangeInput}
            />
          </div>
        </div>
      ) : (
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <ReInput
              type="text"
              id="klacht"
              name="klacht"
              label="Klacht"
              required={true}
              placeholder="Vul je klacht in"
              value={formData?.klacht}
              onChange={handleChangeInput}
            />
          </div>
        </div>
      )}
      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-3">
          {/* First Name */}
          <ReInput
            type="text"
            id="first_name"
            name="first_name"
            label="Voornaam"
            required={true}
            placeholder="Vul uw voornaam in"
            value={formData?.first_name}
            onChange={handleChangeInput}
          />
        </div>
        <div className="sm:col-span-3">
          <ReInput
            type="text"
            id="last_name"
            name="last_name"
            label="Familienaam"
            required={true}
            placeholder="Vul uw familienaam in"
            value={formData?.last_name}
            onChange={handleChangeInput}
          />
        </div>
      </div>
      {/* Add input component for Last Name here */}

      {/* Date of Birth */}
      {/* Add input component for Date of Birth here */}

      {/* Phone Number */}

      <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <ReInput
            type="text"
            id="phone_number"
            name="phone_number"
            label="Telefoonnummer"
            placeholder="Vul uw Telefoonnummer in"
            value={formData?.phone_number}
            onChange={handleChangeInput}
          />
        </div>
        <div className="sm:col-span-3">
          <ReInput
            type="text"
            id="cellphone_number"
            name="cellphone_number"
            label="Gsmnummer"
            placeholder="Vul uw gsmnummer in"
            value={formData?.cellphone_number}
            onChange={handleChangeInput}
          />
        </div>
      </div>
      <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-3">
          {/* Zipcode */}
          <ReInput
            type="text"
            id="zipcode"
            name="zipcode"
            label="Postcode"
            placeholder="Vul uw postcode in"
            value={formData?.zipcode}
            onChange={handleChangeInput}
          />
        </div>
        <div className="sm:col-span-3">
          <ReInput
            type="text"
            id="city"
            name="city"
            label="Stad"
            placeholder="Vul uw stad in"
            value={formData?.city}
            onChange={handleChangeInput}
          />
        </div>
      </div>
      <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <ReInput
            type="text"
            id="street"
            name="street"
            label="Straat"
            placeholder="Vul de straat in"
            value={formData?.street}
            onChange={handleChangeInput}
          />
        </div>
        <div className="sm:col-span-3">
          {/* House Number */}
          <ReInput
            type="text"
            id="housenr"
            name="housenr"
            label="Huisnummer"
            placeholder="Vul uw huisnummer in"
            value={formData?.housenr}
            onChange={handleChangeInput}
          />
        </div>
      </div>

      {/* More Details */}
      <div className="mt-6">
        <ReInput
          type="textarea"
          id="more_details"
          name="more_details"
          label="More Details"
          placeholder="Wil u nog is speciaal meedelen?"
          value={formData?.more_details}
          onChange={handleChangeInput}
        />
      </div>
    </div>
  );
};

export default UserInfo;
