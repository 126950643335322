import React from "react";
import { imgrepeat } from "../image";
import "./loading.css"; // We'll create this CSS file for the loading animation

const Spinner2 = ({ progress = 100 }) => {
  return (
    <div className="flex items-center justify-center w-auto min-h-screen bg-white z-50">
      <div className="w-72">
        <div role="status">
          <img
            className="mx-auto h-20 w-auto loading-image"
            src={imgrepeat.logo_green}
            alt="RepeatHealth"
          />
          <span className="sr-only">Loading...</span>
        </div>
        <div className="w-auto bg-gray-200 rounded mt-4">
          <div
            className="bg-green-800 text-xs font-medium text-white text-center p-0.5 leading-none rounded"
            style={{ width: `${progress}%` }}
          >
            {progress}%
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spinner2;
