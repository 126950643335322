import { Radio, RadioGroup } from "@headlessui/react";
import React from "react";
import classNames from "../../../utils";

const PersoonlijkeInfo = ({
  formData,
  editeer,
  handleChange,
  onChangeRadio,
  errors
}) => {

  const changeCheckbox = (e, name) => 
    {
      const value = e.target.value;
      const currentValues = formData[name] || '';
      let newValues;
      if (currentValues.includes(value)) {
        // Remove value if it exists
        newValues = currentValues.replace(value, '');
      } else {
        // Add value if it doesn't exist
        newValues = currentValues ? currentValues + ',' + value : value;
      }
      onChangeRadio(name, newValues);
    }
  ;
  return (
    <div className="mt-4 p-8 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-6xl">
      <p className="text-center p-4 rounded-md bg-gray-200">
        Persoonlijke informatie
      </p>
      <div className="flex justify-center ">
        <p className="rounded-md ring-1 ring-inset ring-gray-300 w-2/4 p-4 mt-4">
          We starten met vragen die ons meer vertellen over wie je bent, wat
          jouw activiteiten, omgevingsfactoren en gewoonten zijn. Zo kunnen we
          rekening houden met jou als persoon bij het opstellen van jouw advies
          en de verdere begeleiding.
        </p>
      </div>
      <div className="space-y-8 mx-16 my-8">
        {/* 1. Naam */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            1. Naam
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="text"
              name="name"
              value={formData.name}
              disabled={true}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 2. Hoe bent u bij ons terechtgekomen? */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            2. Hoe bent u bij ons terechtgekomen?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="text"
              name="how_found"
              disabled={editeer}
              value={formData.how_found}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 3. Hoe oud bent u? */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            3. Hoe oud bent u?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="text"
              name="age"
              disabled={editeer}
              value={formData?.age}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
          {errors?.age && <p className="text-red-500 text-xs mt-1">{errors?.age}</p>}
        </div>
        {/* 4. Hoeveel weegt u? */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            4. Hoeveel weegt u?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="number"
              name="weight"
              disabled={editeer}
              value={formData.weight}
              step={0.01}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
          {errors?.weight && <p className="text-red-500 text-xs mt-1">{errors?.weight} : is geen decimale cijfer. Gelieve een decimale cijfer in te voeren. </p>}
        </div>
        {/* 5. Hoe groot bent u? */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            5. Hoe groot bent u?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <input
              type="text"
              name="height"
              disabled={editeer}
              value={formData.height}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
          {errors?.height && <p className="text-red-500 text-xs mt-1">{errors?.height}</p>}
        </div>
        {/* 6. Wat is uw beroep? */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            6. Wat is uw beroep?
          </label>
          <RadioGroup
            disabled={editeer}
            value={formData?.occupation}
            name={"occupation"}
            onChange={(e) => onChangeRadio("occupation", e)}
            className="mt-2 grid grid-cols-3 gap-3 sm:grid-cols-3"
          >
            {[
              { value: "Bediende", label: "Bediende" },
              { value: "Zelfstandige", label: "Zelfstandige" },
              {
                value: "Zelfstandige in bijberoep",
                label: "Zelfstandige in bijberoep",
              },
              { value: "Student", label: "Student" },
              {
                value: "Ik combineer meerdere jobs/studie",
                label: "Ik combineer meerdere jobs/studie",
              },
              { value: "Ik ben op pensioen", label: "Ik ben op pensioen" },
              {
                value: "Ik ben werkloos/werkzoekende",
                label: "Ik ben werkloos/werkzoekende",
              },
              {
                value: "Ik ben thuisgeschreven door de arts",
                label: "Ik ben thuisgeschreven door de arts",
              },
            ].map((option, index) => (
              <Radio
                key={option?.value}
                disabled={editeer}
                value={option?.value}
                name={"occupation"}
                className={({ focus, checked }) =>
                  classNames(
                    "cursor-pointer focus:outline-none",
                    focus ? "ring-2 ring-indigo-600 ring-offset-2" : "",
                    checked
                      ? "bg-[#01bd64] text-white hover:bg-[#01bd64]"
                      : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                    "flex items-center justify-center rounded-md px-3 py-3 text-xs font-semibold uppercase sm:flex-1"
                  )
                }
              >
                {option?.label}
              </Radio>
            ))}
          </RadioGroup>
        </div>
        {/* 7. Doet u aan sport, recreatief of beroepsmatig? Zo ja, hoeveel uren per week? */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            7. Doet u aan sport, recreatief of beroepsmatig? Zo ja, hoeveel uren
            per week?
          </label>
          <RadioGroup
            disabled={editeer}
            value={formData?.sport_hours}
            name={"sport_hours"}
            onChange={(e) => onChangeRadio("sport_hours", e)}
            className="mt-2 grid grid-cols-2 gap-3 sm:grid-cols-2"
          >
            {[
              "Minder dan 2,5 uur",
              "2,5 - 5 uur",
              "5 - 10 uur",
              "12 - 20 uur",
            ].map((option, index) => (
              <Radio
                key={option}
                disabled={editeer}
                value={option}
                name={"sport_hours"}
                className={({ focus, checked }) =>
                  classNames(
                    "cursor-pointer focus:outline-none",
                    focus ? "ring-2 ring-indigo-600 ring-offset-2" : "",
                    checked
                      ? "bg-[#01bd64] text-white hover:bg-[#01bd64]"
                      : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                    "flex items-center justify-center rounded-md px-2 py-2 text-xs font-semibold uppercase sm:flex-1"
                  )
                }
              >
                {option}
              </Radio>
            ))}
          </RadioGroup>
        </div>
        {/* 8. Hoeveel uur per week doet u aan andere soorten lichamelijke
    activiteit, zoals huishoudelijk werk, lopen naar werk of school,
    renovatiewerken, verplaatsen op het werk, tuinieren, etc.? */}
        <div>
          <label className="block text-sm font-medium text-gray-700 text-wrap">
            8. Hoeveel uur per week doet u aan andere soorten lichamelijke
            activiteit, zoals huishoudelijk werk, lopen naar werk of school,
            renovatiewerken, verplaatsen op het werk, tuinieren, etc.?
          </label>

          <RadioGroup
            disabled={editeer}
            value={formData?.other_activity_hours}
            name={"other_activity_hours"}
            onChange={(e) => onChangeRadio("other_activity_hours", e)}
            className="mt-2 grid grid-cols-2 gap-3 sm:grid-cols-2"
          >
            {[
              "Minder dan 2,5 uur",
              "2,5 - 5 uur",
              "5 - 10 uur",
              "12 - 20 uur",
            ].map((option, index) => (
              <Radio
                key={option}
                disabled={editeer}
                value={option}
                name={"other_activity_hours"}
                className={({ focus, checked }) =>
                  classNames(
                    "cursor-pointer focus:outline-none",
                    focus ? "ring-2 ring-indigo-600 ring-offset-2" : "",
                    checked
                      ? "bg-[#01bd64] text-white hover:bg-[#01bd64]"
                      : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                    "flex items-center justify-center rounded-md px-2 py-2 text-xs font-semibold uppercase sm:flex-1"
                  )
                }
              >
                {option}
              </Radio>
            ))}
          </RadioGroup>
        </div>
        {/* 9. In wat voor omgeving vertoeft u? Waar woont u? (stad, buitenwijken,
    appartement, huis, co-huisvesting, kot, etc.) */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            9. In wat voor omgeving vertoeft u? Waar woont u? (stad,
            buitenwijken, appartement, huis, co-huisvesting, kot, etc.)
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <textarea
              rows={5}
              name="environment"
              disabled={editeer}
              value={formData.environment}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 10. Wie woont bij u ? Vink alles aan wat van toepassing is. */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            10. Wie woont bij u ? Vink alles aan wat van toepassing is.
          </label>
          <div className="mt-2 grid grid-cols-2 gap-3 sm:grid-cols-4">
            {[
              { value: "echtgenoot", label: "Echtgenoot of partner" },
              { value: "kinderen", label: "Kind(eren)" },
              { value: "andere_familieleden", label: "Andere familieleden" },
              { value: "huisgenoten", label: "Huisgenoot(en)" },
              { value: "huisdieren", label: "Huisdier(en)" },
            ].map((option) => (
              <label
                key={option.value}
                className={classNames(
                  "relative flex cursor-pointer items-center justify-center rounded-md px-3 py-3 text-xs font-semibold uppercase",
                  formData?.household?.includes(option.value)
                    ? "bg-green-700 text-white hover:bg-green-800"
                    : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                )}
              >
                <input
                  type="checkbox"
                  name="household"
                  value={option.value}
                  checked={formData.household?.includes(option.value)}
                  disabled={editeer}
                  onChange={(e) =>  changeCheckbox(e,'household')}
                  className="sr-only"
                />
                <span>{option.label}</span>
              </label>
            ))}
          </div>
        </div>
        {/* 11. Hebt u kinderen? Zo ja, hoeveel en wat is hun leeftijd?*/}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            11. Hebt u kinderen? Zo ja, hoeveel en wat is hun leeftijd?
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <textarea
              rows={5}
              name="children"
              disabled={editeer}
              value={formData.children}
              onChange={handleChange}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 12. Wie doet de meeste boodschappen in uw huishouden? Vink alles aan
            wat van toepassing is en noteer ook even waar (supermarkt/markt) de
            inkopen gedaan worden. */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            12. Wie doet de meeste boodschappen in uw huishouden? Vink alles aan
            wat van toepassing is en noteer ook even waar (supermarkt/markt) de
            inkopen gedaan worden.
          </label>
          <div className="mt-2 grid grid-cols-2 gap-3 sm:grid-cols-4">
            {[
              { value: "Ikzelf", label: "Ikzelf" },
              {
                value: "Echtgenoot of partner (s)",
                label: "Echtgenoot of partner (s)",
              },
              { value: "Huisgenoot (en)", label: "Huisgenoot (en)" },
              {
                value: "Andere familieleden",
                label: "Andere familieleden",
              },
              { value: "Kind (eren)", label: "Kind (eren)" },
              { value: "Supermarkt: ...", label: "Supermarkt: ..." },
            ].map((option) => (
              <label
                key={option.value}
                className={classNames(
                  "relative flex cursor-pointer items-center justify-center rounded-md px-3 py-3 text-xs font-semibold uppercase",
                  formData.shopping?.includes(option.value)
                    ? "bg-green-700 text-white hover:bg-green-800"
                    : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                )}
              >
                <input
                  type="checkbox"
                  name="shopping"
                  value={option.value}
                  checked={formData.shopping?.includes(option.value)}
                  disabled={editeer}
                  onChange={(e) => changeCheckbox(e,'shopping')}
                  className="sr-only"
                />
                <span>{option.label}</span>
              </label>
            ))}
          </div>
          <div className="mt-4 flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
            <textarea
              rows={5}
              name="shopping_places"
              disabled={editeer}
              value={formData.shopping_places}
              onChange={handleChange}
              placeholder="Waar (supermarkt/markt)"
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* 13. Wie kookt het meeste in uw huishouden? Vink alles aan wat van toepassing is. */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            13. Wie kookt het meeste in uw huishouden? Vink alles aan wat van
            toepassing is.
          </label>
          <div className="mt-2 grid grid-cols-2 gap-3 sm:grid-cols-4">
            {[
              { value: "Ikzelf", label: "Ikzelf" },
              {
                value: "Echtgenoot of partner (s)",
                label: "Echtgenoot of partner (s)",
              },
              { value: "Huisgenoot (en)", label: "Huisgenoot (en)" },
              {
                value: "Andere familieleden",
                label: "Andere familieleden",
              },
              { value: "Kind (eren)", label: "Kind (eren)" },
            ].map((option) => (
              <label
                key={option.value}
                className={classNames(
                  "relative flex cursor-pointer items-center justify-center rounded-md px-3 py-3 text-xs font-semibold uppercase",
                  formData.cooking?.includes(option.value)
                    ? "bg-green-700 text-white hover:bg-green-800"
                    : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                )}
              >
                <input
                  type="checkbox"
                  name="cooking"
                  value={option.value}
                  checked={formData.cooking?.includes(option.value)}
                  disabled={editeer}
                  onChange={(e) => changeCheckbox(e,'cooking')}
                  className="sr-only"
                />
                <span>{option.label}</span>
              </label>
            ))}
          </div>
        </div>
        {/* 14. Wie beslist over de meeste menu's/maaltijden in uw huishouden? Vink alles aan wat van toepassing is. */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            14. Wie beslist over de meeste menu's/maaltijden in uw huishouden?
            Vink alles aan wat van toepassing is.
          </label>
          <div className="mt-2 grid grid-cols-2 gap-3 sm:grid-cols-4">
            {[
              { value: "Ikzelf", label: "Ikzelf" },
              {
                value: "Echtgenoot of partner (s)",
                label: "Echtgenoot of partner (s)",
              },
              { value: "Huisgenoot (en)", label: "Huisgenoot (en)" },
              {
                value: "Andere familieleden",
                label: "Andere familieleden",
              },
              { value: "Kind (eren)", label: "Kind (eren)" },
            ].map((option) => (
              <label
                key={option.value}
                className={classNames(
                  "relative flex cursor-pointer items-center justify-center rounded-md px-3 py-3 text-xs font-semibold uppercase",
                  formData.menu_decision?.includes(option.value)
                    ? "bg-green-700 text-white hover:bg-green-800"
                    : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                )}
              >
                <input
                  type="checkbox"
                  name="menu_decision"
                  value={option.value}
                  checked={formData.menu_decision?.includes(option.value)}
                  disabled={editeer}
                  onChange={(e) => changeCheckbox(e,'menu_decision')}
                  className="sr-only"
                />
                <span>{option.label}</span>
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersoonlijkeInfo;
