import {
  AcademicCapIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import RPCardOne from "../../Componenten/Cards/RPCardOne";

const vragenlijsten = [
  {
    title: "Een Gezondheidstijdlijn (Pre-intake vragenlijst)",
    href: "#",
    icon: ClockIcon,
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
  },
  {
    title: "Klachtenregistratie vragenlijst",
    href: "#",
    icon: CheckBadgeIcon,
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
  },
  {
    title: "Voedingsdagboek",
    href: "#",
    icon: UsersIcon,
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
  },
];

function BeforePreIntake({swipperRef, user}) {
  return (
    <div className="flex justify-center">

      <div className="p-6 bg-white rounded-lg shadow-md w-3/4 m-8">
        <h2 className="text-2xl font-semibold  text-gray-800 text-center text-wrap">
          Dankjewel voor boeken van een intake gesprek.
        </h2>
        <div className="flex justify-center mt-4">
        <p className="mt-4 text-wrap text-gray-500 text-sm px-16">
          Om jou zo goed en zo persoonlijk mogelijk te helpen bij jouw
          gezondheidsdoelen- of klachten vragen we jou om 3 vragenlijsten in te
          vullen.
        </p>
        </div>
        <div className="flex justify-center">
          <ul
            role="list"
            className="mt-3 gap-5 rounded-lg p-4 w-full px-32 sm:grid-cols-3 sm:gap-6 lg:grid-cols-4"
          >
            {vragenlijsten.map((type,index) => (
              <RPCardOne key={index} item={type} />
            ))}
          </ul>
        </div>
        <div className="flex justify-center">
          <p className="mt-4 text-wrap text-gray-500 text-sm px-16">
            Het intakegesprek is bedoeld om elkaar beter te leren kennen. We
            gaan dan ook uitgebreid door deze vragenlijsten. We bespreken dan
            ook jouw hulpvraag en gezondheidsdoelen. Elk advies wordt opgemaakt
            naargelang jouw gezondheidssituatie.
          </p>
        </div>
      </div>
    </div>
  );
}

export default BeforePreIntake;
