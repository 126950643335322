import React, { useCallback, useEffect, useState } from "react";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import Swal from "sweetalert2";
import { Avatar } from "@mui/joy";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../firebase/firebase";

const gePath = (inputString) => {
// Find the position of the word 'bestand'
const position = inputString.indexOf('bestand');

// If 'bestand' exists in the string, extract from it to the end
let  result = '';
if (position !== -1) {
   result = inputString.substring(position);
} 

return result;
}

const ImageUpload = ({ formData, setFormData, fileInputRef, user }) => {
  const [image, setImage] = useState(formData?.avatar);
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [progress, setProgress] = useState(0);

  const onValueChangeFile = useCallback(
    async (event) => {
      const item = event.target;
      const selectedFile = item.files[0];
      
      try {
        // Create a reference to Firebase Storage with the user's name and file name
        const fileRef = ref(
          storage,
          `${user?.username}-${user?.id}/user/${selectedFile.name}`
        );

        // Upload the file to Firebase Storage
        await uploadBytes(fileRef, selectedFile);

        // Get the file's URL after upload
        const fileURL = await getDownloadURL(fileRef);

        // Example: Call a function or endpoint to save the fileURL in your database
       // await handleUpload(fileURL)
         setAvatar(fileURL);

       // await saveFileURLToDatabase(fileURL);
        Swal.fire(`Bestand ${selectedFile?.name} is opgeladen`, "", "success");
      } catch (error) {
        Swal.fire("Upload failed", "", "error");
      } finally {
        setLoading(false);
      }
    },
    [user]
  );

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (avatar) {
      handleUpload();
      setLoading(false);
    }
  }, [ avatar]);

  const handleUpload = async (file = avatar) => {

    if (!avatar) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `Foto is niet opgeladen`,
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }
      // Call the AXIOS_UPLOAD_IMAGE function, passing the necessary parameters
      //const response = await AXIOS_UPLOAD_IMAGE("users", user.id, avatar);

      // Assuming your backend returns the updated user details, including the new avatar URL
    setFormData((prev) => ({
      ...prev,
      avatar: file || avatar, // Update with the URL
    }));

    Swal.fire({
      position: "top-end",
      icon: "success",
      title: `Foto is opgeladen`,
      showConfirmButton: false,
      timer: 2500,
    });
 
      
  };

  return (
    <div className="col-span-3">
      <input
        type="file"
        id="avatar"
        name="avatar"
        accept="image/*"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={onValueChangeFile}
      />
      <label
        htmlFor="photo"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Photo
      </label>
      <div className="mt-2 flex items-center gap-x-3">
        {formData?.avatar ? (
          <img
            className="h-40 w-40 flex-none rounded-full bg-gray-800 object-cover"
            onClick={handleButtonClick}
            size="lg"
            src={formData?.avatar}
            alt="profiel foto"
          />
        ) : (
          <UserCircleIcon
            className="h-40 w-40 text-gray-300"
            onClick={handleButtonClick}
            aria-hidden="true"
          />
        )}
        <button
          type="button"
          onClick={handleUpload}
          className="rounded-md bg-gray-100 px-3 py-2 text-sm font-semibold shadow hover:bg-white/20"
        >
          Wijzig uw profielfoto
        </button>
      </div>
    </div>
  );
};

export default ImageUpload;
