import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditorAll = ({ value, setValue,p_height= '50vh', editorKey }) => {
   // const [value, setValue] = useState({opmerking:''});

   const handleChange = (content, delta, source, editor) => {

    setValue((prev) => ({...prev,[editorKey]:editor.getHTML()}));
  };
  
    const modules = {
      toolbar: [
        [{ 'header': [] }, { 'font': [] }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['link'],
        ['clean']                                         
      ],
    };
  
    const formats = [
      'header', 'font', 'list', 'bullet',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'color', 'background', 'align',
      'link'
    ];
  
    
  
    return (
        <ReactQuill 
          className='h-96 pb-20'
          value={value}
          onChange={handleChange}
          modules={modules}
          formats={formats}
        />
    );
  };
  
  export default TextEditorAll;