import React, { useState, useEffect } from "react";
import moment from "moment";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

const LittleCalendar = ({
  currentMonth,
  selectedDay,
  generateDaysArray,
  setDayselected,
  appointments,
  events,
}) => {
  const [currentDate, setCurrentDate] = useState(moment(currentMonth));
  const [days, setDays] = useState([]);
  const formaMonth = currentDate.format("MMMM YYYY");

  useEffect(() => {
    setDays(generateDaysArray(currentDate, selectedDay, appointments));
  }, [currentDate, selectedDay]);

  const handlePreviousMonth = () => {
    setCurrentDate(currentDate.clone().subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setCurrentDate(currentDate.clone().add(1, "month"));
  };

  return (
    <div className="sticky top-8 hidden w-1/2 max-w-md flex-none border-l border-gray-100 px-8 py-10 md:block">
      <div className="flex items-center text-center text-gray-900">
        <button
          type="button"
          onClick={handlePreviousMonth}
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-sm font-semibold dark:text-white">
          {formaMonth}
        </div>
        <button
          type="button"
          onClick={handleNextMonth}
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
        <div>Ma</div>
        <div>Di</div>
        <div>Woe</div>
        <div>Do</div>
        <div>Vr</div>
        <div>Za</div>
        <div>Zo</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {days.map((day, dayIdx) => (
          <button
            key={day.date}
            type="button"
            onClick={() => setDayselected(day)}
            className={classNames(
              "py-1.5 hover:bg-gray-100 focus:z-10",
              day.isCurrentMonth
                ? (day.isSelected && !day.isToday
                    ? "bg-gray-100"
                    : "bg-transparent") + " dark:bg-gray-800"
                : "dark:text-white bg-gray-50 dark:bg-gray-500",
              day.isSelected &&
                `font-semibold ${
                  day.events.length > 0
                    ? "bg-green-100 dark:bg-green-900"
                    : "bg-gray-100"
                }`,
              day.isToday && "text-white",
              !day.isCurrentMonth && !day.isToday && "text-gray-400",
              dayIdx === 0 && "rounded-tl-lg",
              dayIdx === 6 && "rounded-tr-lg",
              dayIdx === days.length - 7 && "rounded-bl-lg",
              dayIdx === days.length - 1 && "rounded-br-lg"
            )}
          >
            <time
              dateTime={day.date}
              className={classNames(
                "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                day.isSelected && day.isToday && "bg-green-600",
                day?.events?.length > 0 && "bg-green-300 dark:bg-green-700",
                day.isToday && "bg-green-800"
              )}
            >
              {day.date.split("-").pop().replace(/^0/, "")}
            </time>
          </button>
        ))}
      </div>
    </div>
  );
};

export default LittleCalendar;

// Helper function for class names (you can also use a library like clsx)
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
