import React, { useCallback, useState } from "react";
import Swal from "sweetalert2";
import { getUser, getUserdetails } from "../../Localstorage/localstorage";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { AXIOS_UPDATE } from "../../server/api/crud";
import RP_Avatar from "../RP_Avatar";

const Passwoord = ({ user, open, setOpen }) => {
  const userdetails = getUserdetails();
  const [updatePassword, setUpdatePassword] = useState({
    old_password: user?.password,
    password: "",
    confirm_password: "",
  });

  const [confirmColor, setConfirmColor] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatePassword((prev) => ({ ...prev, [name]: value }));
    if ((name == "confirm_password" && value === updatePassword.password)) {
      setConfirmColor(true);
    } else {
      setConfirmColor(false);
    }
  };

  const handleChangePasswoord = useCallback(async () => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

    if (!passwordRegex.test(updatePassword.password)) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `Wachtwoord moet minimaal 6 karakters lang zijn, minstens één hoofdletter, één nummer en één speciaal karakter bevatten`,
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }

    if (!updatePassword.password || updatePassword.password.trim() === "") {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `Wachtwoord mag niet leeg zijn`,
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }

    if (updatePassword.password.length < 6) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `Wachtwoord moet minimaal 6 karakters lang zijn`,
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }

    if (updatePassword.password != updatePassword.confirm_password) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `Wachtwoorden zijn niet identiek`,
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }

    try {
      await AXIOS_UPDATE(`auth/password`, user?.id, {
        id: user.id,
        password: updatePassword.old_password,
        email: user?.email,
        newpassword: updatePassword.password,
      }).then((respons) => {
        if (respons.statusText == "OK") {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Uw wachtwoord is gewijzigd`,
            showConfirmButton: false,
            timer: 2500,
          });
          setOpen(false);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `Wachtwoord komt niet overeen. Gelieve deze correct in te voeren.`,
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
    } catch (error) {
      Swal.fire(
        "Er is een fout opgetreden bij het wijzigen van uw wachtwoord",
        "",
        "error"
      );
      console.error("Error during sign-in:", error);
    }
  }, [updatePassword, user]);

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="flex w-full flex-col justify-center items-center lg:px-8">
            <RP_Avatar avatar={userdetails?.avatar} size={20} alt={` ${userdetails?.full_name}`} />

              <div className="sm:mx-auto sm:w-full sm:max-w-sm">
               
                <h1 className="mt-4 text-center text-2xl leading-9 tracking-tight text-gray-900">
                  {userdetails?.full_name||"Geen naam"}
                </h1>
                <h2 className="mt-2 text-center text-lg font-bold leading-9 tracking-tight text-gray-900">
                  Wachtwoord wijzigen
                </h2>
              </div>
              <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="space-y-10">
                  <div>
                    <div className="mt-2">
                      <input
                        id="old_password"
                        name="old_password"
                        type="password"
                        placeholder="Gelieve de huidige wachtwoord in te voeren"
                        value={updatePassword?.old_password}
                        disabled
                        required
                        className="block w-full text-lg rounded-md border-0 px-4 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-darkblue-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        value={updatePassword?.password}
                        placeholder="Gelieve de nieuwe wachtwoord in te voeren"
                        onChange={handleChange}
                        required
                        className="block w-full text-lg rounded-md border-0 px-4 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-darkblue-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <div className="mt-2">
                      <input
                        id="confirm_password"
                        name="confirm_password"
                        type="password"
                        value={updatePassword?.confirm_password}
                        placeholder="Bevestig de nieuwe wachtwoord"
                        {...(!confirmColor && {
                          title: "Wachtwoord is niet gelijk",
                        })}
                        onChange={handleChange}
                        autoComplete="current-confirm_password"
                        required
                        className={`block w-full text-lg rounded-md border-0 px-4 py-4 text-gray-900 shadow-sm  ring-inset ${
                          confirmColor
                            ? "ring-green-500 ring-2"
                            : "ring-red-500 ring-2"
                        } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-darkblue-600 sm:text-sm sm:leading-6`}
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      onClick={handleChangePasswoord}
                      className="flex w-full justify-center text-lg rounded-md bg-green-900 px-4 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Wijzigen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default Passwoord;
