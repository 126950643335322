import { useState, useEffect, useCallback } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import "tailwindcss/tailwind.css";
import { getUser, getVooruitgang } from "../../Localstorage/localstorage";
import moment from "moment";
import { useSelector } from "react-redux";
import Select2 from "../../Componenten/Select2";
import { vooruitgangTabs } from "../../function";
import { AXIOS_GET } from "../../server/api/crud";
import SelectPatienten from "../../Componenten/SelectPatienten";

// Register Chart.js components
ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Filler,
  Tooltip,
  Legend
);

const calculateAverage = (data) => {
  const total = data?.reduce((sum, item) => sum + item.score, 0) || 0;
  return data?.length ? parseFloat((total / data.length).toFixed(2)) : 0;
};

const EvolutionAdmin = () => {
  const user = getUser();
  const [metricData, setMetricData] = useState({
    slaap: [],
    energie: [],
    stress: [],
    cravings: [],
  });
  const [opmerking, setOpmerking] = useState("");

  const { patienten } = useSelector((state) => state.user);

  const [fromdata, setFromdata] = useState(null);
  const vooruitgang = getVooruitgang();
  const tabs = vooruitgangTabs(user, vooruitgang);

  const [selectedMetric, setSelectedMetric] = useState(tabs[0]);
  const [averageScore, setAverageScore] = useState({
    slaap: 0,
    energie: 0,
    stress: 0,
    cravings: 0,
  })
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isNietOpGeslaan, setNietIsOpgeslaan] = useState(0);
  const [selected, setSelected] = useState(patienten[0]);

  const fetchVooruitgangData = useCallback(
    async (userId) => {
      try {
        const response = await AXIOS_GET(
          `vooruitgang/uservooruitgang/${userId}`
        );

        if (response.status !== 201) {
          console.warn("Unexpected response status:", response.status);
          return; // Exit early if the status isn't as expected
        }

        // Destructure data from the response
        const {
          slaapDatas = [],
          energieDatas = [],
          stressDatas = [],
          cravingsDatas = [],
        } = response.data;

        // Map and format the datasets only if they contain data
        const formatData = (data) =>
          data?.map((item) => ({
            ...item,
            date: moment(item.date).format("YYYY-MM-DD"),
          })) || [];

          console.log(slaapDatas, energieDatas, stressDatas, cravingsDatas)

        const dataVooruitgang = {
          slaap: formatData(slaapDatas),
          energie: formatData(energieDatas),
          stress: formatData(stressDatas),
          cravings: formatData(cravingsDatas),
        };

         // Calculate total scores using reduce
         const averageScores = {
          slaap: calculateAverage(slaapDatas),
          energie: calculateAverage(energieDatas),
          stress: calculateAverage(stressDatas),
          cravings: calculateAverage(cravingsDatas),
        };

        // Update states
        setMetricData(dataVooruitgang);
        setAverageScore(averageScores);

        // Update state efficiently
        setMetricData(dataVooruitgang);

        // Set selected metric based on data availability
        const defaultMetric = selectedMetric; // Select the first available slaap data or default to tabs[0]
        setSelectedMetric(defaultMetric);
      } catch (error) {
        console.error("Error fetching data (vooruitgang):", error);
      }
    },
    [tabs] // Ensure tabs is included in dependencies
  );

  useEffect(() => {
    const gebruiker = selected?.user_id;
    if (gebruiker) fetchVooruitgangData(gebruiker);
  }, [selected]);
  // Check if dark mode is active
  useEffect(() => {
    const checkDarkMode = () => {
      setIsDarkMode(
        window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: light)").matches
      );
    };

    checkDarkMode();
    window.addEventListener("resize", checkDarkMode);

    return () => window.removeEventListener("resize", checkDarkMode);
  }, []);

  // Chart options configuration
  const chartOptions = {
    color: isDarkMode ? "#4B5563" : "#374151", // Gray-300 for dark mode, Gray-700 for light mode
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: isDarkMode ? "#4B5563" : "white",
        },
        grid: {
          color: isDarkMode ? "#4B5563" : "#E5E7EB",
        },
      },
      y: {
        ticks: {
          color: isDarkMode ? "#4B5563" : "white",
        },
        grid: {
          color: isDarkMode ? "#4B5563" : "#E5E7EB",
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: isDarkMode ? "#4B5563" : "white",
        },
      },
      tooltip: {
        callbacks: {
          // Customize the title of the tooltip
          title: function (tooltipItems) {
            return `${tooltipItems[0].label}`;
          },
          // Customize the label of the tooltip
          label: function (tooltipItem) {
            const index = tooltipItem?.dataIndex; // Get the index of the data point
            const dataset = tooltipItem?.dataset; // Access the dataset
            const rawData = metricData[selectedMetric.key][index]; // Access the corresponding data object

            return `Score: ${tooltipItem?.raw} ${
              rawData?.uitleg ? " | Meer uitleg : " + rawData?.uitleg : ""
            }`;
          },
          // Optionally add footer
          footer: function (tooltipItems) {
            return `Extra Info: ${tooltipItems.length} points`;
          },
        },
        enabled: false, // Disable the default tooltip
        external: function (context) {
          // Create the custom tooltip element if it doesn't exist
          let tooltipEl = document.getElementById("chartjs-tooltip");
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.padding = "0px";
            tooltipEl.style.pointerEvents = "none";
            tooltipEl.style.transition = "all 0.2s ease";
            document.body.appendChild(tooltipEl);
          }

          const tooltipModel = context?.tooltip;

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
          }

          // Set tooltip content
          if (tooltipModel?.body) {
            const bodyLines = tooltipModel?.body.map((item) => item?.lines);
            const index = tooltipModel?.dataPoints[0]?.dataIndex; // Index of the point
            const rawData = metricData[selectedMetric.key][index]; // Custom data

            let innerHtml = `
            <div class="text-xs" ><strong class="dark:text-white" >Date:</strong> <span class="dark:text-white">${tooltipModel?.title[0]}</span></div>
            <div class="text-xs flex items-center gap-x-1">
              <div class="bg-[#818CF833] dark:bg-[#34D39933] border-2 border-[#818CF8] dark:border-[#34D399] w-3 h-3 rounded"></div>  
              <strong class="dark:text-white">Score:</strong> <span class="dark:text-white">${tooltipModel?.dataPoints[0].raw}</span>
            </div>`;

          if (rawData?.uitleg) {
            innerHtml += `
            <div class="text-xs mt-2 dark:text-white"><strong>More Info:</strong> </div>
            <div class="text-xs dark:text-white">${rawData?.uitleg}</div>`;
          }

          tooltipEl.innerHTML =
            '<div class="max-w-56 p-2 bg-white rounded border-4 dark:border-[#34D39933]  border-[#818CF833] dark:bg-gray-700 shadow dark:bg-gray-800">' +
            innerHtml +
            "</div>";
          }

          // Set tooltip position
          const canvas = context.chart.canvas;
          const position = canvas.getBoundingClientRect();

          tooltipEl.style.opacity = "1";
          tooltipEl.style.left =
            position.left +
            window.pageXOffset +
            tooltipModel.caretX -
            90 +
            "px";
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + "px";
        },
      },
    },
  };
  // Tab content based on the selected metric

  return (
    <div className="bg-white dark:bg-gray-800 p-6 h-dvh rounded-lg shadow-md w-full">
      <h2 className="text-xl font-bold mb-6">Vooruitgang</h2>

      {/* Tabs */}
      <div className="flex justify-between">
        <div className="mb-4 w-1/3">
        {patienten?.length > 0 && (
          <SelectPatienten
            selected={selected ? selected : patienten[0]}
            setSelected={setSelected}
            data={patienten}
          />
        )}
        </div>
        <div className="flex mb-4 h-12">
          {tabs.map((tab) => (
            <button
              key={tab?.key}
              className={`px-6 py-2 text-lg font-medium ${
                selectedMetric.key === tab?.key
                  ? "border rounded dark:bg-gray-700 dark:text-white bg-green-200 text-green-800 shadow-md"
                  : "dark:text-gray-200 text-gray-600 hover:text-green-500"
              }`}
              onClick={() => setSelectedMetric(tab)}
            >
              {tab?.naam}
            </button>
          ))}
        </div>
      </div>

      {/* Line Chart */}
      <div className="h-96 mb-2 pb-16 border p-4 rounded">
        <Line
          data={{
            labels: metricData[selectedMetric.key]?.map(
              (item) => `Dag ${moment(item.date).format("L")}`
            ),
            datasets: [
              {
                label: `${
                  selectedMetric?.key?.charAt(0)?.toUpperCase() +
                  selectedMetric?.key?.slice(1)
                } Niveau`,
                data: metricData[selectedMetric.key]?.map((item) => item.score),
                borderColor: isDarkMode ? "#818CF8" : "#34D399", // Indigo-400 for dark mode, Green-400 for light mode
                backgroundColor: isDarkMode
                  ? "rgba(129, 140, 248, 0.2)" // Lighter indigo for dark mode
                  : "rgba(52, 211, 153, 0.2)", // Lighter green for light mode
                fill: true,
                tension: 0.3,
              },
            ],
          }}
          options={chartOptions}
        />
      </div>

      {/* Metric Input Section */}
      <div className="p-6 bg-transparent rounded-lg shadow-md border">
        <h3 className="text-lg font-semibold mb-2">
          {tabs.find((tab) => tab.key === selectedMetric.key)?.naam}
        </h3>
        <p className="text-md mb-2">
          Gemiddelde score : <span className={`${averageScore[selectedMetric?.key] > 5 ? 'bg-green-600 text-white' : 'bg-red-600 text-white'} p-1 rounded-md`}>{averageScore[selectedMetric?.key]}</span>
        </p>
        <p className="text-xs dark:text-gray-400 text-gray-500 mb-3 dark:text-gray-100 mt-1">
          {selectedMetric.key === "slaap"
            ? "0 = Slechte nachtrust, 10 = Geslapen als een roos"
            : selectedMetric.key === "energie"
            ? "0 = Geen energie, 10 = Zeer energiek"
            : selectedMetric.key === "stress"
            ? "0 = Geen stress, 10 = Extreme stress"
            : "0 = Goede verzadiging, 10 = Continue cravings"}
        </p>
        {/*  <div className="grid grid-cols-1 mt-4">
          <textarea
            type="text"
            rows={9}
            placeholder="Opmerking"
            className="border p-2 dark:bg-gray-900 dark:text-white"
            value={opmerking}
            onChange={(e) => setOpmerking(e.target.value)}
            required
          />
        </div> */}
      </div>
    </div>
  );
};

export default EvolutionAdmin;
