import React, { useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import moment from "moment";
import DoubleMeetingEvents from "./DoubleMeetingEvents";

// Function to generate an array of days for a specific month using moment
const generateMonthDays = (start, end, currentMonth, events) => {
  const days = [];
  let currentDate = moment(start).startOf("isoWeek");
  let endDate = moment(end).endOf("isoWeek");

  while (currentDate.isSameOrBefore(endDate)) {
    const isCurrentMonth = currentDate.month() === currentMonth;
    const isToday = isCurrentMonth && currentDate.isSame(moment(), "day");

    const eventsForDay = events?.filter(event => 
      moment(event.datetime).locale('nl-be').format('YYYY-MM-DD') === currentDate.locale('nl-be').format('YYYY-MM-DD')
    );

    days.push({
      date: currentDate.format("YYYY-MM-DD"),
      isCurrentMonth,
      isToday,
      events:eventsForDay
    });

    // Move to the next day
    currentDate.add(1, "day");
  }

  return days;
};

const generateMonths = (startMonth, numberOfMonths,events) => {
  const months = [];
  let currentDate = moment(startMonth);
  for (let i = 0; i < numberOfMonths; i++) {
    const firstDayOfMonth = moment(currentDate).startOf("month");
    const lastDayOfMonth = moment(currentDate).endOf("month");

    // Calculate the number of days from the previous month needed to complete the week
    const daysFromPreviousMonth = firstDayOfMonth.diff(currentDate, "days");
    const daysFromNextMonth = 6 - lastDayOfMonth.diff(currentDate, "days");

    // Include days from the previous month that fall within the same week
    for (let j = 0; j < daysFromPreviousMonth; j++) {
      const prevMonthDay = moment(firstDayOfMonth).subtract(
        daysFromPreviousMonth - j,
        "days"
      );
      // Filter events that match the current date
      months.push({
        name: firstDayOfMonth.format("MMMM"),
        days: {
          date: prevMonthDay.format("YYYY-MM-DD"),
          isCurrentMonth: false,
          isToday: false,
        },
      });
    }

    // Include days from the current month
    const monthDays = generateMonthDays(
      firstDayOfMonth,
      lastDayOfMonth,
      currentDate.month(),
      events
    );
    months.push({
      name: firstDayOfMonth.format("MMMM"),
      days: monthDays,
    });

    // Include days from the next month that fall within the same week
    for (let k = 1; k <= daysFromNextMonth; k++) {
      const nextMonthDay = moment(lastDayOfMonth).add(k, "days");
      months.push({
        name: firstDayOfMonth.format("MMMM"),
        days: {
          date: nextMonthDay.format("YYYY-MM-DD"),
          isCurrentMonth: false,
          isToday: false,
        },
      });
    }

    currentDate = moment(firstDayOfMonth).add(1, "month"); // Move to the next month
  }
  return months;
};

// Function to return CSS classes dynamically
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Maanden = ({aantal = 2, appointments}) => {
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [update, setupdate] = useState(0);
  const numberOfMonths = aantal;
  const [months, setMonths] = useState(generateMonths(currentMonth, numberOfMonths, appointments))

  const handlePreviousMonth = () => {
    setCurrentMonth(prev => prev.subtract(1, "month"));
    setupdate(prev =>  prev - 1);
  };

  const handleNextMonth = () => {
    setCurrentMonth(prev => prev.add(1, "month"));
    setupdate(prev =>  prev + 1);
  };

  useEffect(() => {
    setMonths(generateMonths(currentMonth, numberOfMonths,appointments));
  }, [currentMonth, update])

  return (
  <div className="relative grid grid-cols-1 gap-x-14 md:grid-cols-2">
  <button
    type="button"
    onClick={handlePreviousMonth}
    className="absolute -left-1.5 -top-1 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
  >
    <span className="sr-only">Previous month</span>
    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
  </button>
  <button
    type="button"
    onClick={handleNextMonth}
    className="absolute -right-1.5 -top-1 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
  >
    <span className="sr-only">Next month</span>
    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
  </button>
  {months.map((month, monthIdx) => {
    const firstDayOfMonth = moment(month.days[0].date).startOf("week");
    const startingDayIndex = firstDayOfMonth.day(); // Get the day index
    const numCols = 7; // Number of columns in the grid

    return (
      <section
        key={monthIdx}
        className={classNames(
          monthIdx === months.length - 1 && "hidden md:block",
          "text-center"
        )}
      >
        <h2 className="text-sm font-semibold text-gray-900 dark:text-white">
          {month.name}
        </h2>
        <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500 dark:text-gray-200">
          {Array.from({ length: numCols }).map((_, dayIdx) => (
            <div key={dayIdx}>
              {moment()
                .day((dayIdx + startingDayIndex) % numCols)
                .format("ddd")}
            </div>
          ))}
        </div>
        <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200 dark:bg-white">
          {month.days.map((day, dayIdx) => (
            <button
              key={day.date}
              type="button"
              className={classNames(
                day.isCurrentMonth
              ? (day.isSelected && !day.isToday
                  ? "bg-gray-100"
                  : "bg-transparent") + " dark:bg-gray-800"
                : "dark:text-white bg-gray-50 dark:bg-gray-500",
                day.isSelected &&
                `font-semibold ${
                  day.events.length > 0
                    ? "bg-green-100 dark:bg-red-900"
                    : "bg-gray-100"
                }`,
                dayIdx === 0 && "rounded-tl-lg",
                dayIdx === 6 && "rounded-tr-lg",
                dayIdx === month.days.length - 7 && "rounded-bl-lg",
                dayIdx === month.days.length - 1 && "rounded-br-lg",
                "relative py-1.5 hover:bg-gray-100 focus:z-10"
              )}
            >
              <time
                dateTime={day.date}
                className={classNames(
                  "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                  day.isSelected && day.isToday && "bg-green-600",
                day?.events?.length > 0 && "bg-green-300 dark:bg-yellow-700",
                  day.isToday && "bg-green-800 text-white",
                 
                )}
              >
                {day.date.split("-").pop().replace(/^0/, "")}
              </time>
            </button>
          ))}
        </div>
      </section>
    );
  })}
</div>);
}

const DoubleMeeting = ({appointments}) => {
  return (
    <div className="mt-4">
      <Maanden aantal={2} appointments={appointments}/>
      <DoubleMeetingEvents events={appointments}/>
    </div>
  );
};

export default DoubleMeeting;
