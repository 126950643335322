import React, { useState, useEffect } from "react";
import moment from "moment";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import CrudEvent from "./CrudEvent";

const LittleCalendarUpcoming = ({
  currentMonth,
  dayselected,
  generateDaysArray,
  setDayselected,
  appointments,
}) => {
  const [currentDate, setCurrentDate] = useState(moment(currentMonth));
  const [days, setDays] = useState([]);
  const [open, setOpen] = useState(false);
  const formattedMonth = currentDate.format("MMMM YYYY");

  useEffect(() => {
    setDays(generateDaysArray(currentDate, dayselected, appointments));
  }, [currentDate, dayselected, appointments]);

  const handlePreviousMonth = () => {
    setCurrentDate(currentDate.clone().subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setCurrentDate(currentDate.clone().add(1, "month"));
  };

  return (
    <div className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
      <div className="flex items-center text-gray-900">
        <button
          type="button"
          onClick={handlePreviousMonth}
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-sm font-semibold dark:text-white">{formattedMonth}</div>
        <button
          type="button"
          onClick={handleNextMonth}
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500 dark:text-white dark:bg-gray-700">
        <div>M</div>
        <div>D</div>
        <div>W</div>
        <div>D</div>
        <div>V</div>
        <div>Z</div>
        <div>Z</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {days.map((day, dayIdx) => (
          <button
            key={day.date}
            type="button"
            onClick={() => setDayselected(day)}
            className={classNames(
              "py-1.5 hover:bg-gray-100 focus:z-10",
              day.isCurrentMonth
              ? (day.isSelected && !day.isToday
                  ? "bg-gray-100"
                  : "bg-transparent") + " dark:bg-gray-800"
                : "dark:text-white bg-gray-50 dark:bg-gray-500",
                day.isSelected &&
                `font-semibold ${
                  day.events.length > 0
                    ? "bg-green-100 dark:bg-green-900"
                    : "bg-gray-100"
                }`,
              day.isToday && "text-white",
              !day.isCurrentMonth && !day.isToday && "text-gray-400",
              dayIdx === 0 && "rounded-tl-lg",
              dayIdx === 6 && "rounded-tr-lg",
              dayIdx === days.length - 7 && "rounded-bl-lg",
              dayIdx === days.length - 1 && "rounded-br-lg"
            )}
          >
            <time
              dateTime={day.date}
              className={classNames(
                "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                day.isSelected && day.isToday && "bg-green-600",
                day?.events?.length > 0 && "bg-green-300 dark:bg-green-700",
                day.isToday && "bg-green-800"
              )}
            >
              {day.date.split("-").pop().replace(/^0/, "")}
            </time>
          </button>
        ))}
      </div>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="mt-8 w-full rounded-md bg-green-800 px-3 py-2 text-sm font-semibold text-white shadow hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
      >
        Afspraak maken
      </button>
      <CrudEvent openModel={open} setOpenModal={setOpen} />
    </div>
  );
};

export default LittleCalendarUpcoming;

// Helper function for class names
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
