import React, { useEffect, useState } from "react";
import { CardAdmin } from "../../Componenten/Cards/CardAdmin";
import { getAdmins } from "../../Localstorage/localstorage";
import CrudEventAdmin from "../../Componenten/Calender/CrudEventAdmin";

const Admins = ({}) => {
  const admins = getAdmins();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null)

  const getSelected = (item) => {
      setSelected(item);
  }

  useEffect(() => {
    if(selected != null)
    setOpen(true)
  }, [selected])
  

  return (
    <div className="bg-gray-100 p-4 min-h-screen h-fit dark:bg-gray-800 dark:border-gray-700">
      <div className="min-w-0 flex-1 mb-4">
        <h2 className="text-2xl font-bold leading-7 dark:text-gray-100  text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Onze specialitesten
        </h2>
      </div>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3"
      >
        {admins?.length > 0 &&
          admins.map((admin) => (
            <li
              key={admin.email}
              className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow cursor-pointer"
            >
              <CardAdmin admin={admin} getSelected={getSelected} />
            </li>
          ))}
      </ul>
      <CrudEventAdmin admin={selected} openModel={open} setOpenModal={setOpen}/>
    </div>
  );
};

export default Admins;
