const PORT = process.env['PORT'] || 8080;
const BASE_URL = 'https://repeatconnect.com';
const BASE_URL_API = `${BASE_URL}/api/klantenportaal`;
const IMAGE_URL_API = 'http://localhost:80';
const DOCUMENTS_URL_API = 'http://localhost:8075';
const PAGESIZE = 25;
const ACCESS_TOKEN_SECRET="jsdljfmlkqsdjklfjqdsfqsdfqjsdmfnsjqdnfjksdlnfkjqsndfjknqsdjkfnsdkj-fudhsbfj-sdjkfhbkjdhbfvuezbhyuiavoeyzrez123413241234132&ééyéijezhdfjhdlsfjqhdsklfqsdfqdsf"
const REFRESH_TOKEN_SECRET="jsdljfmlkqsdjdsjfjdskfjsdklfjlsdjflsdfqsdfsdF88EEEZ00Z0ZERrRRZ000Z99Z99ZZz))))sd)fsdfsdjfhqkjshfldsflfjqdsfqsdfqjsdmfnsjqdnfjksdlnfkjqsndfjknqsdjkfnsdkj-fudhsbfj-sdjkfhbkjdhbfvuezbhyuiavoeyzrez123413241234132&ééyéijezhdfjhdlsfjqhdsklfqsdfqdsf"
const TOKEN_EXPIRY="2h"
const REFRESH_TOKEN_EXPIRY="7"

const MAKE_SOCKET_CHANNEL = (id, channel) =>{
 if(!id) return channel 
 else return `${channel}_${id}`;
} 

const SOCKET_CHANNELS = {
  COMMENTS:'RP_COMMENTS',
  USERS:'RP_USERS',
  PREINTAKE:'RP_PREINTAKE',
  EETDAGBOEK:'RP_EETDAGBOEK',
  MAIL:'RP_MAIL',
  MAIL_ITEM:'RP_MAIL_ITEM',
  AFSPRAKEN:'RP_AFSPRAKEN',
  VRAGENLIJSTEN:'RP_VRAGENLIJSTEN',
  KLACHTENFORMULIER:'RP_KLACHTENFORMULIER',
  DOCUMENTEN:'RP_DOCUMENTEN',
  VOORUITGANG:'RP_VOORUITGANG',
}


module.exports = {
  SOCKET_CHANNELS,
  MAKE_SOCKET_CHANNEL,
  PORT,
  BASE_URL,
  BASE_URL_API,
  IMAGE_URL_API,
  DOCUMENTS_URL_API,
  PAGESIZE,
  ACCESS_TOKEN_SECRET,
  REFRESH_TOKEN_EXPIRY,
  REFRESH_TOKEN_SECRET,
  TOKEN_EXPIRY
};
