import React, { useCallback, useEffect, useRef, useState } from "react";
import { PaperClipIcon, PhotoIcon } from "@heroicons/react/24/outline";
import { AXIOS_UPDATE, AXIOS_UPLOAD_DOCUMENT } from "../../server/api/crud";
import Swal from "sweetalert2";
import { getUser, getUserdetails } from "../../Localstorage/localstorage";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../firebase/firebase";

const UploadBestandInput = ({ linkbackend, formData, editkey, setFormData, setLoading }) => {

  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const user = getUser();

  const onValueChangeFile = useCallback(
    async (event) => {
      setLoading(true);
      const item = event.target;
      const selectedFile = item.files[0];
      //setFile(selectedFile);
      try {
        // Create a reference to Firebase Storage with the user's name and file name
        const fileRef = ref(
          storage,
          `${user?.username}-${user?.id}/eetdagboek/${selectedFile.name}`
        );



        // Upload the file to Firebase Storage
        await uploadBytes(fileRef, selectedFile);

        // Get the file's URL after upload
        const fileURL = await getDownloadURL(fileRef);

        // Example: Call a function or endpoint to save the fileURL in your database
        //await setFormData(prev => ({...prev, url:fileURL}))
        let newObject = {...formData, url:fileURL}
        await handleUpload(newObject,selectedFile.name);
      } catch (error) {
        console.error("Upload failed:", error);
        Swal.fire("Upload failed", "", "error");
      } finally {
        setLoading(false);
      }
    },
    [user]
  );


  const handleButtonClick = () => {
    //if(linkbackend)
    fileInputRef.current.click();
  };

  const handleUpload = useCallback(async (newObject, name) => {

    if (!newObject?.url) {
      alert("Please choose an image first!");
      return;
    }

    try {

      let id = formData?.id;
  
      const response = await AXIOS_UPDATE(linkbackend, id, newObject);

      // Assuming your backend returns the updated user details, including the new avatar URL
      if (response.data.success) {
       // setLoading(false);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: `Bestand ${name} is opgeladen`,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `Bestand ${name}  is niet opgeladen`,
          showConfirmButton: false,
          timer: 2500,
        });
        // console.error('Upload failed:', response.data.message);
      }
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `Fout bij het opladen van uw Bestand ${file?.name} `,
        showConfirmButton: false,
        timer: 2500,
      });

      console.error("Error uploading image:", error);
    }
  },[]);


  return (
    <div className="flex">
      <input
        type="file"
        id="bestand"
        name="bestand"
        accept="image/*,pdf"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={onValueChangeFile}
      />
      {formData?.url &&
        <a href={formData?.url} target="_blank" rel="noopener noreferrer">
          <div className="border rounded-full p-2 dark:bg-green-800 cursor-pointer hover:border-gray-600 hover:bg-gray-500 mr-2">
            <span className="gap-x-2 flex cursor-pointer">
              <PaperClipIcon className="dark:text-white h-4 w-4" />
            </span>
          </div>
        </a>
}
        <div
          onClick={handleButtonClick}
          className="border rounded-full p-2 dark:bg-blue-800 cursor-pointer hover:border-gray-600 hover:bg-gray-500"
        >
          <span className="gap-x-2 flex cursor-pointer">
            <PhotoIcon className="dark:text-white h-4 w-4"/>
          </span>
        </div>
    </div>
  );
};

export default UploadBestandInput;
