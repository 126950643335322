import React from "react";
import { imgrepeat } from "../image";
import './loading.css'; // We'll create this CSS file for the loading animation


const Spinner = () => {
  return (
    <div className="flex  items-center justify-center w-auto min-h-screen bg-white z-50">
    <div role="status">
    <img
            className="mx-auto h-20 w-auto loading-image"
            src={imgrepeat.logo_green}
            alt="RepeatHealth"
          />
        <span className="sr-only">Loading...</span>
    </div>
</div>

  );
};

export default Spinner;
