import Swal from "sweetalert2";
import { nvl } from "../function";
import { AXIOS_GET, AXIOS_POST, AXIOS_UPDATE } from "../server/api/crud";

export const fetchDataAlleGebruikers = async (page = 1, pageSize = 50, setLoading, setError, setData, desiredColumns, role=999, type="all") => {
    setLoading(true);
    setError("");

    // Calculate the correct offset based on page and pageSize
    const offset = (page);

    try {
      const response = await AXIOS_GET(
        `users/allgebruikers?&role=${role}&type=${type}&limit=${pageSize}&offset=${offset}`
      );

      if (response.data.success) {
        let newArray = response.data.data
          .map((item) => ({ ...item, toegang: nvl(item.toegang, 0) }));


        // Filter the data to include only the desired columns and remove duplicates
        const filteredData = newArray
          .map((row) =>
            desiredColumns.reduce((acc, col) => {
              if (row[col] !== undefined) {
                acc[col] = row[col];
              }
              return acc;
            }, {})
          );
          // Filter the data to include only the desired columns and remove duplicates

        setData(filteredData); // For debugging
        // setData(response.data.data); // Save fetched data in state
      } else {
        setError("Failed to fetch data.");
      }
    } catch (err) {
      // Handle network or unexpected errors
      console.error("Error fetching data:", err);

      // Use error response if available
      const errorMessage =
        err.response?.data?.message || "An error occurred while fetching data.";
      setError(errorMessage);
    } finally {
      setLoading(false); // Ensure loading is stopped in both success and error cases
    }
  };

export const fetchDataAlleGebruikersFilter = async (page = 1, pageSize = 50, setLoading, setError, setData, desiredColumns, role=999, type="all", search="") => {
    setLoading(true);
    setError("");

    // Calculate the correct offset based on page and pageSize
    const offset = (page);

    try {
      const response = await AXIOS_GET(
        `users/allgebruikers/filter?&role=${role}&type=${type}&limit=${pageSize}&offset=${offset}&search=${search}`
      );

      if (response.data.success) {
        let newArray = response.data.data
          .map((item) => ({ ...item, toegang: nvl(item.toegang, 0) }));
          console.log(newArray);

        // Filter the data to include only the desired columns and remove duplicates
        const filteredData = newArray
          .map((row) =>
            desiredColumns.reduce((acc, col) => {
              if (row[col] !== undefined) {
                acc[col] = row[col];
              }
              return acc;
            }, {})
          );
          // Filter the data to include only the desired columns and remove duplicates
          console.log(filteredData);
        setData(filteredData); // For debugging
        // setData(response.data.data); // Save fetched data in state
      } else {
        setError("Failed to fetch data.");
      }
    } catch (err) {
      // Handle network or unexpected errors
      console.error("Error fetching data:", err);

      // Use error response if available
      const errorMessage =
        err.response?.data?.message || "An error occurred while fetching data.";
      setError(errorMessage);
    } finally {
      setLoading(false); // Ensure loading is stopped in both success and error cases
    }
};

export const updateToegang = async (item, page,
    pageSize,
    setLoading,
    setError,
    setData,
    desiredColumns,
    role,
    type) => {

    if (nvl(item?.inscription, 0) == 0) {
      await Swal.fire({
        position: "center",
        icon: "error",
        title: `Patient nog niet ingeschreven.
         Gelieve de patient in te schrijven vooraleer u hem/haar toegang geef tot de klantenportaal`,
        showConfirmButton: false,
        timer: 3000,
      });

      return;
    }

    const toegang = item?.toegang == 0 ? 1 : 0;


    try {
      const respons = await AXIOS_UPDATE(`auth/toegang`, item?.id, {
        toegang: toegang,
      });
      if (respons.status == 200) {
        await Swal.fire({
          position: "center",
          icon: "success",
          title: `Patient heeft toegang tot de repeatconnect klantenportaal`,
          showConfirmButton: false,
          timer: 3000,
        });

        fetchDataAlleGebruikers(
          page,
          pageSize,
          setLoading,
          setError,
          setData,
          desiredColumns,
          role,
          type
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

export const handleSignUp = async (item, page, pageSize, setLoading, setError, setData, desiredColumns, role, type) => {
    const usernameRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$|^[a-zA-Z0-9_]+$/;
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{3,}$/;

    const { email, password, first_name, last_name, phone, id } = item;

    // Validation checks for username and email
    if (!email || email.trim() === "") {
      alert("Gebruikersnaam mag niet leeg zijn");
      return;
    }

    if (!usernameRegex.test(email)) {
      alert("Ongeldige gebruikersnaam of e-mailadres");
      return;
    }

    // Validation checks for password
    if (!password || password.trim() === "") {
      alert("Wachtwoord mag niet leeg zijn");
      return;
    }

    /* if (!passwordRegex.test(password)) {
      alert(
        "Wachtwoord moet minimaal 3 karakters lang zijn, minstens één hoofdletter, één nummer en één speciaal karakter bevatten"
      );
      return;
    }*/

    // Show confirmation prompt
    const result = await Swal.fire({
      title: `Wenst u deze ${first_name} ${last_name} in te schrijven`,
      showCancelButton: true,
      confirmButtonText: "Ja",
      cancelButtonColor: "gray",
      cancelButtonText: "Annuleer",
      confirmButtonColor: "darkgreen",
      denyButtonColor: "red",
    });

    if (result.isConfirmed) {
      try {
        // First, sign up the user
        const signUpResponse = await AXIOS_POST("auth/signup", {
          id,
          username: email,
          password,
          role: 2,
          email,
        });

        if (signUpResponse.data.affectedRows === 1) {
          // Notify the user about successful registration
          await Swal.fire({
            position: "center",
            icon: "success",
            title: `Bedankt voor de registratie.
              Gelieve u aan te melden met deze gebruikersnaam: ${email}`,
            showConfirmButton: false,
            timer: 2500,
          });

          // Wait for 2 seconds before updating user details
          setTimeout(async () => {
            const newObject = {
              first_name: first_name,
              last_name: last_name,
              full_name: `${first_name} ${last_name}`,
              email,
              phone_number: phone,
            };

            try {
              const updateResponse = await AXIOS_UPDATE("users", id, newObject);
              if (updateResponse.data.data.affectedRows === 1) {
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: `Bedankt voor het bij vullen van de gegevens.`,
                  showConfirmButton: false,
                  timer: 2500,
                });

                //setOpen(false); // Close the modal or perform any action as needed
                fetchDataAlleGebruikers(page,pageSize, setLoading, setError, setData, desiredColumns, role, type)
              }
            } catch (error) {
              console.error("Error updating user details:", error);
              alert(
                "Er is een fout opgetreden bij het bijwerken van de gegevens."
              );
            }
          }, 2000); // Delay update by 2 seconds
        }
      } catch (error) {
        console.error("Error during sign-up:", error);
        alert("Er is een fout opgetreden bij het registreren.");
      }
    }
  };

export const fetchPatienten = async (limit = 100, offset = 0, query = '', setLoading, setError, setData) => {
    setLoading(true);
    setError("");

    try {
      const response = await AXIOS_GET(`users/all/patienten/filter?limit=${limit}&offset=${offset}&search=${query}`);
      if (response.data.success) {
        setData(response.data.data);
        console.log("response.data.data",response.data.data)
      } else {
        setError("Failed to fetch patients.");
      }
    } catch (err) {
      console.error("Error fetching patients:", err);
      const errorMessage = err.response?.data?.message || "An error occurred while fetching patients.";
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
};  

export const fetchAdmins = async (limit = 50, offset = 0, query = '', setLoading, setError, setData) => {
    setLoading(true);
    setError("");

    try {
      const response = await AXIOS_GET(`users/all/admins/filter?limit=${limit}&offset=${offset}&search=${query}`);
      if (response.data.success) {
        setData(response.data.data);
      } else {
        setError("Failed to fetch administrators.");
      }
    } catch (err) {
      console.error("Error fetching administrators:", err);
      const errorMessage = err.response?.data?.message || "An error occurred while fetching administrators.";
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
}; 

export const fetchAllUsersDetails = async (limit = 4, offset = 0, query = '', setLoading, setError, setData) => {
    setLoading(true);
    setError("");

    try {
      const response = await AXIOS_GET(`users/alldetails/filter?limit=${limit}&offset=${offset}&search=${query}`);
      if (response.data.success) {
        setData(response.data.data);
      } else {
        setError("Failed to fetch user details.");
      }
    } catch (err) {
      console.error("Error fetching user details:", err);
      const errorMessage = err.response?.data?.message || "An error occurred while fetching user details.";
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
}; 