import { createSlice } from '@reduxjs/toolkit';
import { getAdmins, getUser, getUserdetails } from '../Localstorage/localstorage';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: getUser(),
    userdetails: getUserdetails(),
    useralldatas:[],
    admins: [],
    patienten:[],
    users:[]
  },
  reducers: {
    setUser: (state, action) => {
      // Appends the new item to the existing user array
      state.user = action.payload;
    },
    setUserDetails: (state, action) => {
      // This one seems to replace mailMessage entirely, no change needed here
      state.userdetails = action.payload;
    },
    setUserAllDatas: (state, action) => {
      // This one seems to replace mailMessage entirely, no change needed here
      state.useralldatas = action.payload;
    },
    setAdmins: (state, action) => {
      // Appends the new item to the existing mailMessageItems array
      state.admins = [...state.admins, action.payload];
    },
    setPatienten: (state, action) => {
      // Appends the new item to the existing mailMessageItemsFilter array
      state.patienten = [...state.patienten, action.payload];
    },
    setGebruikers: (state, action) => {
      // Appends the new item to the existing mailMessageItemsFilter array
      state.users = [...state.users, action.payload];
    },
    setAdminsLoad: (state, action) => {
      // Set new admins
      state.admins = action.payload;
    },
    setPatientenLoad: (state, action) => {
      // Set new patienten
      state.patienten = action.payload;
    },
    setGebruikersLoad: (state, action) => {
      // Set new users
      state.users =  action.payload;
    },
  },
  
});

export const {
setAdmins,
setAdminsLoad,
setGebruikers,
setPatienten,
setGebruikersLoad,
setPatientenLoad,
setUser,
setUserDetails
} = userSlice.actions;

export default userSlice.reducer;
