
//Upgrade this after each deployment
const APP_VERSION = '23';

export  function getRxautoFirstPage(){
    //clearLocalStorage();
    // signOutUser()
    const cacheAppVersion =  getAppVersion() || '1';
    
    if(cacheAppVersion.toString() != APP_VERSION){
         clearLocalStorage();
         signOutUser();
         setAppVersion();
    }
    const user =  getUser();
    let toPage = '/';
    //Check if user still exist in firebase
    
    return toPage;
}

export  function signOutUser() {
    localStorage.clear();
}

export function clearLocalStorage(){
    localStorage.clear();
}



export  function getAdmins(){
    const data = localStorage.getItem('REPEAT_ADMINS');
    return JSON.parse(data);
}

export  function setAdmins(p_user){
    if(p_user){
        localStorage.setItem('REPEAT_ADMINS',JSON.stringify(p_user));
    }
}

export  function getAccesToken(){
    const data = localStorage.getItem('REPEAT_ACCESSTOKEN');
    return JSON.parse(data);
}

export  function setAccesToken(p_user){
    if(p_user){
        localStorage.setItem('REPEAT_ACCESSTOKEN',JSON.stringify(p_user));
    }
}


export  function setUser(p_user){
    if(p_user){
        localStorage.setItem('REPEAT_USER',JSON.stringify(p_user));
    }
}

export  function getUser(){
    const data = localStorage.getItem('REPEAT_USER');
    return JSON.parse(data);
}

export  function setVooruitgang(p_user){
    if(p_user){
        localStorage.setItem('REPEAT_VOORUITGANG',JSON.stringify(p_user));
    }
}

export  function getVooruitgang(){
    const data = localStorage.getItem('REPEAT_VOORUITGANG');
    return JSON.parse(data);
}

export  function setVoedingID(p_user){
    if(p_user){
        localStorage.setItem('REPEAT_VOEDING_ID',JSON.stringify(p_user));
    }
}

export  function getVoedingID(){
    const data = localStorage.getItem('REPEAT_VOEDING_ID');
    return JSON.parse(data);
}


export  function setUserdetails(p_states){
    if(p_states){
        localStorage.setItem('REPEAT_USERDETAILS',JSON.stringify(p_states));
    }
}

export  function getUserdetails(){
    const data = localStorage.getItem('REPEAT_USERDETAILS');
    return JSON.parse(data);
}


export  function setStates(p_states){
    if(p_states){
        localStorage.setItem('REPEAT_STATES',JSON.stringify(p_states));
    }
}

export  function getStates(){
    const data = localStorage.getItem('REPEAT_STATES');
    return JSON.parse(data);
}

export  function getPrevious(id){
    const data = localStorage.getItem(`REPEAT_${id}`);
    return JSON.parse(data);
}

export  function setPrevious(id,object){
    if(object){
        localStorage.setItem(`REPEAT_${id}`,JSON.stringify(object));
    }
}

export  function setKlacht(p_user){
    if(p_user){
        localStorage.setItem('REPEAT_KLACHT',JSON.stringify(p_user));
    }
}

export  function getKlacht(){
    const data = localStorage.getItem('REPEAT_KLACHT');
    return JSON.parse(data);
}


export  function setCache(p_cache_name,p_object){
    localStorage.setItem(`REPEAT_${p_cache_name}`,JSON.stringify(p_object));
}

export  function getCache(p_cache_name){
    const data = localStorage.getItem(`REPEAT_${p_cache_name}`);
    return JSON.parse(data);
}


 function getAppVersion() {
    const data = localStorage.getItem(`REPEAT_APP_VERSION`);
    return data;
}

 function setAppVersion() {
    localStorage.setItem(`REPEAT_APP_VERSION`,APP_VERSION);
}
