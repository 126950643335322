import React, { useLayoutEffect, useState } from 'react'
import Beantwoorden from './Beantwoorden'
import { useDispatch, useSelector } from 'react-redux';
import { AXIOS_GET } from '../server/api/crud';
import { setComments, setCommentsLoad } from '../redux/commentSlice';
import moment from 'moment';
import RP_Avatar from './RP_Avatar';

const Commentaar = ({admin,user, setNoties}) => {

  const dispatch = useDispatch();
  const { comments } = useSelector((state)=> state.comment); 
  const [updateComment, setUpdateComment] = useState({})

  useLayoutEffect(() => {
    const ophalenData = async () => {
      try {

        await AXIOS_GET(`comments/user/${user?.user_id}`).then((response)=>{
            const commentaar = response.data.data;
              dispatch(setCommentsLoad(commentaar));
        }); 
            
      } catch (error) {
        // Handle the error
        // Swal.fire("Er is een fout opgetreden bij het opslaan", "", "error");
        // alert('Er is een fout opgetreden bij het inloggen'); // Changed alert message for generic error handling
        console.error('Error during sign-in:', error); // Log the error for debugging purposes
      }
    };

    ophalenData();    
  }, [])

  return (
    <section aria-labelledby="notes-title dark:bg-gray-800">
    <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg dark:bg-gray-800">
      <div className="divide-y divide-gray-200 dark:bg-gray-700">
        <div className="px-4 py-5 sm:px-6">
          <h2 id="notes-title" className="text-lg font-medium text-gray-900 dark:text-white">
            Notities tussen collega's
          </h2>
        </div>
        <div className="px-4 py-6 sm:px-6 dark:bg-gray-700">
          {comments.length > 0 ?<ul role="list" className="space-y-8">
            {comments.map((notitie) => (
              <li key={notitie.id}>
                <div className="flex space-x-3">
                  <div className="flex-shrink-0">
                    <RP_Avatar avatar={notitie?.avatar} size="10" alt={` ${notitie?.admin}`} />
                  </div>
                  <div>
                    <div className="text-sm">
                      <a href="#" className="font-medium text-gray-900 dark:text-white">
                        {notitie.admin}
                      </a>
                    </div>
                    <div
                        dangerouslySetInnerHTML={{ __html: notitie.body }}
                        className="mt-1 text-sm text-gray-700 max-h-96 overflow-scroll dark:text-white"
                    />
                    <div className="mt-2 space-x-2 text-sm">
                      <span className="font-medium text-gray-500 dark:text-white">{moment(notitie?.datetime).fromNow()}</span>{' '}
                      <span className="font-medium text-gray-500 dark:text-white">&middot;</span>{' '}
                      {(admin?.user_id == notitie.admin_id) && 
                      <button onClick={()=>setUpdateComment(notitie)} type="button" className="font-medium text-gray-900 dark:text-white">
                        Bewerken
                      </button>}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul> : <p> Er zijn nog geen commentaren toegevoegd. </p>}
        </div>
      </div>
    <Beantwoorden commentaar={updateComment} setUpdateComment={setUpdateComment} admin={admin} user={user}/>
    </div>
  </section>
  )
}

export default Commentaar