import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select2 from "../../../Componenten/Select2";
import InfoCards from "./InfoCards";
import { AXIOS_GET } from "../../../server/api/crud";
import SelectPatienten from "../../../Componenten/SelectPatienten";

export const DashboardHealth = () => {
  const { patienten, admins } = useSelector((state) => state.user);
  const [selected, setSelected] = useState(patienten[0]);

  const [vragenlijst, setVragenlijst] = useState({});

  const fetchData = async (p_id) => {
    try {
      const response = await AXIOS_GET(`vragenlijsten/${p_id}`);
      setVragenlijst(response.data.data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (selected) {
      fetchData(selected?.user_id);
    }
  }, [selected]);

  return (
    <div className="p-8 h-dvh dark:bg-gray-900">
      <div>
        {patienten?.length > 0 && (
          <SelectPatienten
            selected={selected ? selected : patienten[0]}
            setSelected={setSelected}
            data={patienten}
          />
        )}
      </div>
      <InfoCards vragenlijst={vragenlijst} user={selected} />
    </div>
  );
};
