import React, { useCallback, useEffect, useState } from "react";
import TabsFilter from "../../Componenten/TabsFilter";
import Pagination from "../../Componenten/Pagination";
import {
  fetchDataAlleGebruikers,
  handleSignUp,
  updateToegang,
} from "../../apiCalls/usersApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorClosed, faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import SelectGebruikers from "../../Componenten/SelectGebruikers";

const desiredColumns = [
  "toegang",
  "inscription",
  "username",
  "first_name",
  "last_name",
  "phone",
  "email",
  "id",
  "notes",
];

const Gebruikers = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [role, setRole] = useState(999);
  const [type, setType] = useState("all");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState("Alle gebruikers");


  useEffect(() => {
    fetchDataAlleGebruikers(
      page,
      pageSize,
      setLoading,
      setError,
      setData,
      desiredColumns,
      role,
      type
    );
  }, [page, pageSize, role, type]);


  const signUp = useCallback(async (item) => {
    handleSignUp(item, page, pageSize, setLoading, setError, setData, desiredColumns, role, type)
  }, [page, pageSize, role, type]);

  const toegangPlatorm = useCallback(async (item) => {
    updateToegang(item, page, pageSize, setLoading, setError, setData, desiredColumns, role, type)
  }, [page, pageSize, role, type]);

  return (
    <div className="dark:bg-gray-900">
      <SelectGebruikers
        setData={setData}
        data={data}
        setPage={setPage}
        setPageSize={setPageSize}
        setLoading={setLoading}
        setError={setError}
        role={role}
        type={type}
        desiredColumns={desiredColumns}
        setType={setType}
        setRole={setRole}
        page={page}
        pageSize={pageSize}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div>
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50 dark:bg-gray-900">
            <tr>
              {/* Fixed width column for toegang icon */}
              {/* Dynamically render table headers based on data */}
              {data.length > 0 &&
                Object.keys(data[0]).map((key, index) => (
                  <>
                    {index < 7 ? (
                      <th
                        scope="col"
                        className={`${
                          index == 0 ? "w-10" : "w-48"
                        } py-3.5 pl-3 dark:text-white text-left text-sm font-medium`}
                      >
                        {key}
                      </th>
                    ) : null}
                  </>
                ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-800">
            {data.length > 0 &&
              data.map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, i) => (
                    <>
                      {i < 7 ? (
                        i === 0 ? (
                          <td
                            className="w-10 py-3.5 pl-3"
                            onClick={() =>
                              toegangPlatorm(row)
                            }
                          >
                            {value > 0 ? (
                              <FontAwesomeIcon
                                title="Heeft toegang tot de portaal"
                                className="h-6 w-6 text-green-600 cursor-pointer"
                                icon={faDoorOpen}
                              />
                            ) : (
                              <FontAwesomeIcon
                                title="Geen toegang tot de portaal"
                                className="h-6 w-6 dark:text-gray-500 cursor-pointer"
                                icon={faDoorClosed}
                              />
                            )}
                          </td>
                        ) : (
                          <td
                            className="w-48 py-3.5 pl-3 dark:text-white text-left text-sm font-medium"
                            key={i}
                          >
                            {i === 1 ? (
                              <button
                                className="border p-2 border-grey-200 bg-grey-900 rounded"
                                disabled={value === 1}
                                onClick={() => signUp(row)}
                              >
                                {value === 0 ? "Inschrijven" : "ingeschreven"}
                              </button>
                            ) : (
                              value
                            )}
                          </td>
                        )
                      ) : null}
                    </>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
        {data.map((item) => (
          <div>{item.firstname}</div>
        ))}
      </div>
    </div>
  );
};

export default Gebruikers;
