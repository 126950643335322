import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import React from "react";

const Banner = ({ bannerName = "Dashboard", onOpslaan,onWijzigen }) => {

  return (
    <div>
      <div className="mb-4 px-4 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {bannerName}
          </h2>
        </div>
        <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          {((bannerName !== 'Dashboard' && bannerName !== 'Eetdagboek'))&& <button
            type="button"
            onClick={onWijzigen}
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Wijzigen
          </button>}
          { bannerName !== 'Dashboard' && (
          <button
            type="button"
            onClick={onOpslaan}
            className="ml-3 inline-flex gap-2 items-center rounded-md bg-[#111827] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Opslaan
            <CloudArrowDownIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
          </button>)}
        </div>
      </div>
    </div>
  );
};

export default Banner;
