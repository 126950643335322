import React, { useMemo } from "react";

// Wrapping the component with React.memo to optimize re-renders
const TabsFilter = React.memo(({ data, activeTab }) => {
  return (
    <div className="flex py-4 ml-4 sm:flex-none">
        {data.length > 0 && data.map((item, index) => (
        <button key={index} onClick={item.onClick} className={`p-2 ${activeTab === item.name ? "bg-gray-500 text-white shadow" : "bg-white"} mr-2 dark:bg-gray-800 dark:text-white rounded`}>
          {item.name}
        </button>
      ))}
    </div>
  );
});

export default TabsFilter;
