import { Radio, RadioGroup } from '@headlessui/react'
import React from 'react'
import classNames from '../../../utils'


const HulpVraag = ({formData, editeer,handleChange,onChangeRadio}) => {
  const changeCheckbox = (e, name) => 
    {
      const value = e.target.value;
      const currentValues = formData[name] || '';
      let newValues;
      if (currentValues.includes(value)) {
        // Remove value if it exists
        newValues = currentValues.replace(value, '');
      } else {
        // Add value if it doesn't exist
        newValues = currentValues ? currentValues + ',' + value : value;
      }
      onChangeRadio(name, newValues);
    }
  return (
    <div className="mt-4 p-8 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-6xl">
    <p className="text-center p-4 rounded-md bg-gray-200 ">Hulpvraag</p>
    <div className="space-y-8 mx-16 my-12">
      {/* 1. Waarbij kunnen wij u helpen? */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          1. Waarbij kunnen wij u helpen?
        </label>
        <div className="mt-2 grid grid-cols-2 gap-3 sm:grid-cols-2">
          {[
            {
              value: "Specifieke gezondheidsklachten",
              label: "Specifieke gezondheidsklachten",
            },
            {
              value: "Vitaliteit verhogen/meer energie",
              label: "Vitaliteit verhogen/meer energie",
            },
            {
              value: "Algemeen gezonde voedingsgewoonten creëren",
              label: "Algemeen gezonde voedingsgewoonten creëren",
            },
            {
              value: "Duurzaam gewichtsafname (vetverbranding)",
              label: "Duurzaam gewichtsafname (vetverbranding)",
            },
            {
              value: "Duurzaam gewichtstoename",
              label: "Duurzaam gewichtstoename",
            },
            { value: "Fitheid verhogen", label: "Fitheid verhogen" },
            { value: "Spieropbouw", label: "Spieropbouw" },
            {
              value: "Atletische prestaties optimaliseren",
              label: "Atletische prestaties optimaliseren",
            },
          ].map((option, index) => (
            <label
              key={option.value}
              className={classNames(
                "cursor-pointer focus:outline-none relative flex items-center justify-center rounded-md px-3 py-3 text-xs font-semibold uppercase",
                formData?.help_needed?.includes(option.value)
                  ? "bg-green-700 text-white hover:bg-green-800"
                  : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              )}
            >
              <input
                type="checkbox"
                disabled={editeer}
                name="help_needed"
                value={option.value}
                checked={formData?.help_needed?.includes(option.value)}
                onChange={(e) => changeCheckbox(e,'help_needed')}
                className="sr-only"
              />
              {option.label}
            </label>
          ))}
        </div>
      </div>
      {/* 2. Welk van de bovenstaande hulpvragen zijn voor jou het belangrijkste/meest noodzakelijke en waarom? */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          2. Welk van de bovenstaande hulpvragen zijn voor jou het
          belangrijkste/meest noodzakelijke en waarom?
        </label>
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
          <input
            type="text"
            name="belang_meest_hulp"
            disabled={editeer}
            value={formData.belang_meest_hulp}
            onChange={handleChange}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      {/*3. Indien u specifieke klachten/gezondheidsproblemen hebt: */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          3. Indien u specifieke <b>klachten/gezondheidsproblemen</b>{" "}
          hebt:
        </label>
        <ul className="ml-6">
          {/* 3a. Wat is uw hoofdklacht ? */}
          <li>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                3a. Wat is uw hoofdklacht ?
              </label>
              <div className="mb-4 flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                <input
                  type="text"
                  name="main_complaint"
                  disabled={editeer}
                  value={formData.main_complaint}
                  onChange={handleChange}
                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </li>
          {/* 3b. Hebt u andere klachten hebt u ? Zo ja, welke? */}
          <li>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                3b. Hebt u andere klachten hebt u ? Zo ja, welke?
              </label>
              <div className="mb-4 flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                <input
                  type="text"
                  name="other_complaints"
                  disabled={editeer}
                  value={formData.other_complaints}
                  onChange={handleChange}
                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </li>
          {/* 3c .Wanneer zijn deze klachten begonnen? */}
          <li>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                3c. Wanneer zijn deze klachten begonnen?
              </label>
              <RadioGroup
                disabled={editeer}
                value={formData?.complaint_start}
                onChange={(e) => onChangeRadio("complaint_start", e)}
                className="mt-2 space-y-2"
              >
                {[
                  'Al van kinds af aan',
                  'Meer dan 10 jaar geleden',
                  'Tussen 10 en 5 jaar geleden',
                  'Minder dan 5 jaar geleden',
                  'Enkele maanden geleden'
                ].map((option) => (
                  <Radio
                    key={option}
                    value={option}
                    className={({ checked }) =>
                      classNames(
                        "relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none",
                        checked
                          ? "bg-[#01bd64] text-white"
                          : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300"
                      )
                    }
                  >
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
            </div>
          </li>
        </ul>
      </div>
      {/* 4. Wat zijn uw persoonlijke verwachtingen? */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          4. Wat zijn uw persoonlijke verwachtingen?
        </label>
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
          <textarea rows={5}
            name="personal_expectations"
            disabled={editeer}
            value={formData.personal_expectations}
            onChange={handleChange}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      {/* 5. Wat verwacht u van mij als diëtist en orthomoleculair therapeut? */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          5. Wat verwacht u van mij als diëtist en orthomoleculair
          therapeut?
        </label>
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
          <textarea rows={5}
            name="expect_fr_diet_orth"
            disabled={editeer}
            value={formData.expect_fr_diet_orth}
            onChange={handleChange}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      {/* 6. Wat bent u gewillig/bereid te veranderen om uw doelen te bereiken? */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          6. Wat bent u gewillig/bereid te veranderen om uw doelen te
          bereiken?
        </label>
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
          <textarea rows={5}
            name="willingness_to_change"
            disabled={editeer}
            value={formData.willingness_to_change}
            onChange={handleChange}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      {/* 7. Hebt u  in het verleden iets geprobeerd om uw gewoontes, uw gezondheid, uw eetgewoonten te veranderen? (Zo ja, wat?) */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          7. Hebt u in het verleden iets geprobeerd om uw gewoontes, uw
          gezondheid, uw eetgewoonten te veranderen? (Zo ja, wat?)
        </label>
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
          <textarea rows={5}
            name="past_attempts"
            disabled={editeer}
            value={formData.past_attempts}
            onChange={handleChange}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      {/* 8. Welke van deze zaken werkten goed voor u? (Zelfs als je ze nu misschien niet meer doet) */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          8. Welke van deze zaken werkten goed voor u? (Zelfs als je ze nu
          misschien niet meer doet)
        </label>
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
          <input
            type="text"
            name="worked_well"
            disabled={editeer}
            value={formData.worked_well}
            onChange={handleChange}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      {/* 9. Welke van deze zaken werkten niet goed voor u?*/}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          9. Welke van deze zaken werkten niet goed voor u?
        </label>
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
          <input
            type="text"
            name="worked_not_well"
            disabled={editeer}
            value={formData.worked_not_well}
            onChange={handleChange}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      {/* 10. Als u zou overwegen om verdere wijzigingen aan te brengen in uw eetgewoonten en levensstijl, wat zou dat kunnen zijn? */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          10. Als u zou overwegen om verdere wijzigingen aan te brengen in
          uw eetgewoonten en levensstijl, wat zou dat kunnen zijn?{" "}
        </label>
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
          <input
            type="text"
            name="future_changes"
            disabled={editeer}
            value={formData.future_changes}
            onChange={handleChange}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      {/* 11. Wat heeft u tot nu toe geblokkeerd of tegengehouden om deze dingen te veranderen?*/}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          11. Wat heeft u tot nu toe geblokkeerd of tegengehouden om deze
          dingen te veranderen?
        </label>
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
          <input
            type="text"
            name="obstacles"
            disabled={editeer}
            value={formData.obstacles}
            onChange={handleChange}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          />
        </div>
        {/* 12. Hoe zou u uw huidige voedingsgewoonten beoordelen op een schaal van 0 tot 10? */}
        <div>
          <label className="mt-2 block text-sm font-medium text-gray-700 mb-2">
            12. Hoe zou u uw huidige voedingsgewoonten beoordelen op een
            schaal van 0 tot 10?
          </label>
          <RadioGroup
            disabled={editeer}
            value={formData?.diet_habits_rating}
            name={"diet_habits_rating"}
            onChange={(e) => onChangeRadio("diet_habits_rating", e)}
            className="mt-2 grid grid-cols-6 gap-3 sm:grid-cols-12"
          >
            {Array.from({ length: 11 }, (_, i) => (
              <Radio
                key={i}
                disabled={editeer}
                value={i}
                name={"diet_habits_rating"}
                className={({ focus, checked }) =>
                  classNames(
                    "cursor-pointer focus:outline-none",
                    focus ? "ring-2 ring-indigo-600 ring-offset-2" : "",
                    checked
                      ? "bg-[#01bd64] text-white hover:bg-[#01bd64]"
                      : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                    "flex items-center justify-center rounded-md px-2 py-2 text-xs font-semibold uppercase sm:flex-1"
                  )
                }
              >
                {i}
              </Radio>
            ))}
          </RadioGroup>
        </div>
      </div>
    </div>
  </div>
  )
}

export default HulpVraag