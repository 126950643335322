import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ArrowUturnLeftIcon, ChevronDownIcon, ChevronUpIcon, EllipsisVerticalIcon, FolderArrowDownIcon, PencilIcon, UserPlusIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import TopSectionMail from "./TopSectionMail";
import { useMail } from "./MailContext";
import { useDispatch, useSelector } from "react-redux";
import { setCommand, setMailMessageItems, setMailMessageItemsLoad } from "../../redux/mailSlice";
import moment from "moment";
import { getUser, getUserdetails } from "../../Localstorage/localstorage";
import ListChats from "../../Componenten/ListChats";

const MailList = ({ setOpen}) => {
 const dispatch = useDispatch();
 const userdetails = getUserdetails();

 const {
  mailMessage,
  mailMessageItemsFilter,
  mailMessageItems} = useSelector((state) => state.mail);

  useEffect(() => {
    let filterMessageItems = mailMessageItemsFilter.filter((mail) => mail?.mailmessage_id === mailMessage?.id);
    
    dispatch(setMailMessageItemsLoad(filterMessageItems));
  }, [mailMessage,mailMessageItemsFilter])


  const openModal = (p_type = 'insert') => {
      dispatch(setCommand(p_type))
      setOpen(true);
  } 
  return (
    <section
      aria-labelledby="message-heading"
      className="flex h-full min-w-0 flex-1 flex-col overflow-hidden xl:order-last "
    >
    {/* Top section */}
    <TopSectionMail setOpenModal={openModal} />

    <div className="min-h-0 flex-1 overflow-y-auto">
      <div className="bg-white dark:bg-gray-700 pb-6 pt-5 shadow dark:bg-gray-800">
        <div className="px-4 sm:flex sm:items-baseline sm:justify-between sm:px-6 lg:px-8">
          <div className="sm:w-0 sm:flex-1">
            <h1
              id="message-heading"
              className="text-lg font-medium text-gray-900 dark:text-white"
            >
              {mailMessage?.subject}
            </h1>
            <p className="mt-1 truncate text-sm text-gray-500  dark:text-gray-200">
              {userdetails.role_id == 1 ? `Patient: ${mailMessage?.sender}` : `Spécialist: ${mailMessage?.receiver}`}
            </p>
          </div>

          <div className="mt-4 flex items-center justify-between sm:ml-6 sm:mt-0 sm:flex-shrink-0 sm:justify-start">
            <span className="inline-flex items-center rounded bg-gray-100 px-3 py-0.5 text-sm font-medium text-cyan-800 dark:bg-gray-600 dark:text-white">
              {mailMessage?.category}
            </span>
           {/*  <Menu
              as="div"
              className="relative ml-3 inline-block text-left"
            >
              <div>
                <MenuButton className="-my-2 flex items-center rounded-full bg-white p-2 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-600">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon
                    aria-hidden="true"
                    className="h-5 w-5"
                  />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="py-1">
                  <MenuItem>
                    <button
                      type="button"
                      className="flex w-full justify-between px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                      <span>Copy email address</span>
                    </button>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="#"
                      className="flex justify-between px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                      <span>Previous conversations</span>
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="#"
                      className="flex justify-between px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                      <span>View original</span>
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu> */}
          </div>
        </div>
      </div>
      {/* Thread section*/}
      <ListChats chats={mailMessageItems} user={userdetails}/>
    </div>
  </section>
  );
};

export default MailList;
