import React from "react";
import { getUser } from "../../Localstorage/localstorage";
import Spinner from "../../Componenten/Spinner";
import EetdagboekAdmin from "./EetdagboekAdmin";
import EetdagboekClient from "./EetdagboekClient";


const Eetdagboek = ({}) => {
  const user = getUser();

  if(user.role == 1) return <EetdagboekAdmin/>;
  if(user.role == 2) return <EetdagboekClient/>;

  return (
    <Spinner/>
  );
};

export default Eetdagboek;
