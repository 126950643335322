import React from "react";
import Banner from "../../Layout/Banner";

const Quiz = () => {
  return (
    <div>
      <Banner bannerName={"Quiz"} />
    </div>
  );
};

export default Quiz;
