import React, { useCallback, useState } from "react";
import { AXIOS_DELETE, AXIOS_POST, AXIOS_UPDATE } from "../../server/api/crud";
import DashboardDocumenten from "../../Componenten/DashboardDocumenten";
import UploadDocumenten from "../../Componenten/Calender/UploadDocumenten";
import FilterNavigation from "../../Componenten/Dashboard/FilterNavigation";
import { DocumentIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import FileCard from "../../Componenten/MiniCards/FileCard";
import Swal from "sweetalert2";
import { deleteObject, ref } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import FileCardAdvies from "../../Componenten/MiniCards/FileCardAdvies";
import FileCardPlans from "../../Componenten/MiniCards/FileCardPlans";
import { useSelector } from "react-redux";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import RP_Avatar from "../../Componenten/RP_Avatar";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const PlansAdmin = ({
  userdetails,
  attachments,
  setRefresh,
  setAttachments,
  type = "uploads",
}) => {
  const [open, setOpen] = useState(false);
  const [select, setSelect] = useState(attachments[0] || undefined);

  const handleChange = useCallback(
    async (p_id, e) => {
      if (!select) return;

      const checks = e.target.checked;

      const result = await Swal.fire({
        icon: "question",
        title: `Wilt u deze patiënt recht gegevens aan deze bestand ${select?.bestand}?`,
        showCancelButton: true,
        confirmButtonText: "Ja",
        cancelButtonText: "Neen",
        confirmButtonColor: "#111827",
        cancelButtonColor: "green",
      });

      if (!result.isConfirmed) return;

      const updatedUsers = checks
        ? [...(select.users || []), p_id] // Add `p_id`
        : select?.users?.filter((user) => user !== p_id); // Remove `p_id`

      const updatedDocument = { users: updatedUsers };

      try {
        const resp = await AXIOS_UPDATE(
          `documents/admin`,
          select.id,
          updatedDocument
        );
        if (resp.status === 200) {
          const klant = patienten?.find((item) => item.user_id === p_id);
          Swal.fire(
            `Bestand: ${select?.bestand} is nu zichtbaar voor de patiënt ${klant?.full_name}`,
            "",
            "success"
          );

          // Update `attachments` and `select` state
          setAttachments((prev) =>
            prev.map((item) =>
              item.id === select.id ? { ...item, users: updatedUsers } : item
            )
          );
          setSelect((prev) => ({ ...prev, users: updatedUsers }));
        } else {
          Swal.fire("Er is iets misgegaan bij het opslaan", "", "error");
        }
      } catch (error) {
        console.error("Error updating document:", error);
        Swal.fire("Er is een fout opgetreden tijdens de update", "", "error");
      }
    },
    [select]
  );

  const deleteDocument = async (item) => {
    const result = await Swal.fire({
      title: "Bent u zeker deze document te willen verwijderen",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Ja",
      denyButtonText: "Neen",
      confirmButtonColor: "red",
      denyButtonColor: "gray",
    });

    const id = item.id;
    if (result.isConfirmed) {
      await AXIOS_DELETE("documents/admin", id)
        .then((result) => {
          if (result.status === 200) {
            // Create a reference to the file to delete
            const fileRef = ref(storage, item?.url);

            // Delete the file from Firebase Storage
            deleteObject(fileRef);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: `Bestands zijn goed verwijderd`,
              showConfirmButton: false,
              timer: 2500,
            });

            setRefresh(true);
          }
        })
        .catch((e) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `er is iets mis gelopen`,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    }
  };

  const { patienten } = useSelector((state) => state.user);

  return (
    <div className="mx-auto max-w-6xl pt-10 h-max dark:bg-gray-900">
      <div className=" flex px-4">
        <div className="w-4/6">
          <div className="px-8">
            <button
              type="button"
              onClick={() => setOpen(true)}
              className="block rounded-md bg-green-800 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
              Voeg een document toe
            </button>
            <div className="col-span-2 grid grid-cols-2 mt-4">
              {attachments?.length > 0 ? (
                attachments.map((item) => (
                  <div
                    onClick={() => setSelect(item)}
                    className={classNames(
                      item?.id == select?.id ? "border-2 border-green-500" : ""
                    )}
                  >
                    <FileCardAdvies
                      item={item}
                      deleteDocument={deleteDocument}
                      userdetails={userdetails}
                    />
                  </div>
                ))
              ) : (
                <div className="text-center p-8 dark:bg-gray-800 bg-gray-100 border-2 border-gray-700 w-full grid grid-cols-1">
                  <p>Er zijn adviezen documenten gevonden</p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="w-2/6 h-dvh dark:border-gray-700  border-l-2 bg-gray-100 rounded dark:bg-gray-800">
          <div className="grid grid-cols-3 gap-y-4 mt-4 justify-items-center">
            {patienten?.length > 0 &&
              patienten.map((patient) => (
                <div
                  className="dark:bg-gray-700 p-2 rounded border-2 relative cursor-pointer"
                  title={patient?.full_name}
                >
                  <div className="p-1 rounded absolute left-2">
                    <input
                      type="checkbox"
                      checked={select?.users?.some(
                        (item) => item === patient?.user_id
                      )}
                      onChange={(e) => handleChange(patient?.user_id, e)}
                      class="h-4 w-4 text-white bg-green-500 "
                    />
                  </div>
                  <div className="p-2">
                    <RP_Avatar avatar={patient?.avatar} size={20} alt={` ${patient?.full_name}`} />
                     
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <UploadDocumenten
        setRefresh={setRefresh}
        attachments={attachments}
        user={userdetails}
        type={type}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
};

export default PlansAdmin;
