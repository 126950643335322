import React from 'react'
import Login from './Login'

const Signin = () => {
  return (
    <div>
      <Login/>
    </div>
  )
}

export default Signin
