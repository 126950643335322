import { faFilePdf,faFileImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DocumentArrowDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { typeFile } from "../../function";



const FileCard = ({ item, deleteDocument = () => {}, userdetails }) => {
    return (
        <div className="p-3  relative">
          <div className="absolute right-4 top-4 flex gap-x-2">
            <a href={item?.url} target="_blank" rel="noopener noreferrer">
              <DocumentArrowDownIcon className="h-6 w-6 dark:text-blue-400 text-blue-600" />
            </a>
            {userdetails?.role_id == 2 && (
              <div onClick={()=>deleteDocument(item)}>
                <XMarkIcon className="h-6 w-6" />
              </div>
            )}
          </div>
    
          <div className="dark:bg-gray-800 ring-1 ring-gray-300 rounded dark:ring-gray-700 flex py-2 px-1 cursor-pointer">
            <FontAwesomeIcon icon={typeFile(item?.type)} className="h-20 w-20 justify-self-center text-gray-700 mr-2" />
            <div>
              <div title={item?.title||'Geen titel'}>
                <span className="dark:text-white text-sm">{item?.title||'Geen titel'}</span>
              </div>
              <div title={item?.bestand}>
                <span className="dark:text-gray-300 text-sm">{item?.bestand?.length > 20 ? item?.bestand?.slice(0, 20)+'...' : item?.bestand }</span>
              </div>
              <div>
                <span className="dark:text-gray-400 text-xs">{item?.type}</span>
              </div>
            </div>
          </div>
        </div>
      );
};

export default FileCard;
