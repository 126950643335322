import React, { useState } from "react";
import { imgrepeat } from "../../image";
import { useNavigate } from "react-router-dom";
import { AXIOS_POST } from "../../server/api/crud";
import Swal from "sweetalert2";
import uuid from "react-uuid";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";

const RegisterModal = ({ open, setOpen }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmColor, setConfirmColor] = useState(false);
  const [confirmpassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleSignUp = async () => {
    const usernameRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$|^[a-zA-Z0-9_]+$/;
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{3,}$/;

    // Validation checks for username
    if (!username || username.trim() === "") {
      alert("Gebruikersnaam mag niet leeg zijn");
      return;
    }

    if (!usernameRegex.test(username)) {
      alert("Ongeldige gebruikersnaam of e-mailadres");
      return;
    }

    // Validation checks for password
    if (!password || password.trim() === "") {
      alert("Wachtwoord mag niet leeg zijn");
      return;
    }

    if (!passwordRegex.test(password)) {
      alert(
        "Wachtwoord moet minimaal 3 karakters lang zijn, minstens één hoofdletter, één nummer en één speciaal karakter bevatten"
      );
      return;
    }

    if (confirmpassword.trim() !== password.trim()) {
      alert("Wachtwoorden zijn niet gelijk");
      return;
    }

    try {
      
      const id = uuid();
      await AXIOS_POST("auth/signup", {
        id: id,
        username: username,
        password: password,
        role: 1,
        email: null,
      }).then((res) => {
        if (res.data.affectedRows === 1) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `Bedankt voor de registratie.
            Gelieve u aan te melden met deze gebruikersnaam: ${username}`,
            showConfirmButton: false,
            timer: 2500,
          });
          setOpen(false)
        }
      });
    } catch (error) {
      alert("Er is een fout opgetreden bij het registreren");
      console.error("Error during sign-up:", error);
    }
  };

  const ConfirmPassword = (value) => {
    setConfirmPassword(value);
    if (value === password) {
      setConfirmColor(true);
    } else {
      setConfirmColor(false);
    }
  };
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />{" "}
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="flex h-1/2 w-full flex-col justify-center items-center lg:px-8">
              <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                  className="mx-auto h-20 w-auto"
                  src={imgrepeat.logo_green}
                  alt="RepeatHealth"
                />
                <h1 className="mt-10 text-center text-4xl font-bold leading-9 tracking-tight text-gray-900">
                  Repeatconnect.
                </h1>
                <h2 className="mt-2 text-center text-lg font-bold leading-9 tracking-tight text-gray-900">
                  Voeg een nieuwe personeelslid toe
                </h2>
              </div>

              <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="space-y-10">
                  <div>
                    <div className="mt-2">
                      <input
                        id="username"
                        name="username"
                        type="username"
                        placeholder="Gebruikersnaam"
                        value={username}
                        autoComplete="username"
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        className="block w-full text-lg rounded-md border-0 px-4 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-darkblue-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        $
                        value={password}
                        placeholder="Wachtwoord"
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="current-password"
                        required
                        className="block w-full text-lg rounded-md border-0 px-4 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-darkblue-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <div className="mt-2">
                      <input
                        id="confirm_password"
                        name="confirm_password"
                        type="password"
                        value={confirmpassword}
                        placeholder="Bevestig Wachtwoord"
                        {...(!confirmColor && {
                          title: "Wachtwoord is niet gelijk",
                        })}
                        onChange={(e) => ConfirmPassword(e.target.value)}
                        autoComplete="current-confirm_password"
                        required
                        className={`block w-full text-lg rounded-md border-0 px-4 py-4 text-gray-900 shadow-sm  ring-inset ${
                          confirmColor
                            ? "ring-gray-300 ring-1"
                            : "ring-red-500 ring-2"
                        } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-darkblue-600 sm:text-sm sm:leading-6`}
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      onClick={handleSignUp}
                      className="flex w-full justify-center text-lg rounded-md bg-green-900 px-4 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Aanmelden
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default RegisterModal;
