import { Transition } from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import { AXIOS_POST, AXIOS_UPDATE } from "../server/api/crud";
import { useDispatch } from "react-redux";
import { setComments, setCommentsUpdate } from "../redux/commentSlice";
import Swal from "sweetalert2";
import moment from "moment";
import TextEditorAll from "./TextEditorAll";
import RP_Avatar from "./RP_Avatar";
import { createComment } from "../apiCalls/commentsApi";

const Beantwoorden = ({user, admin,commentaar, setUpdateComment}) => {
  const [newComments, setNewComments] = useState({
    id: '',
    user: user?.userdetails_full_name,
    user_id: user.user_id,
    admin: admin?.full_name,
    admin_id: admin?.user_id,
    author_id: admin?.user_id,
    avatar: admin?.avatar,
    body: "",
    datetime: null
  });

  useEffect(() => {
    if(commentaar?.id){
      setNewComments(commentaar)
    }
 
  }, [commentaar])
  

  const dispatch = useDispatch();

  const resetForm = () => {
    setNewComments({
      id: '',
      user: user?.full_name,
      user_id: user.user_id,
      admin: admin?.full_name,
      admin_id: admin?.user_id,
      author_id: admin?.user_id,
      avatar: admin?.avatar,
      body: "",
      datetime: null
    })
  }

  const handleSubmit = async (e) => {
    createComment(e,newComments,commentaar,setUpdateComment,resetForm, dispatch, setComments, setCommentsUpdate);  
  };

  return (
    <div className="bg-gray-50 px-4 py-6 sm:px-6">
      <div className="flex space-x-3">
        <div className="flex-shrink-0">
          <RP_Avatar avatar={admin?.avatar} size="10" />
        </div>
        <div className="min-w-0 flex ">
          <form onSubmit={handleSubmit} className="gap-y-8">
            <div className="relative">
              <label htmlFor="body" className="sr-only">
                Beantwoorden op
              </label>
              <div className="flex-1 dark:text-black">
              <TextEditorAll p_height="40vh" setValue={setNewComments} value={newComments?.body} editorKey={'body'}/>
              </div>
            </div>
            <div className="mt-3 flex flex-1 items-center justify-between relative">
              <a
                href="#"
                className="group inline-flex items-start space-x-2 text-sm text-gray-500 hover:text-gray-900"
              >
                <QuestionMarkCircleIcon
                  aria-hidden="true"
                  className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                />
                <span>Voeg paar notities toe voor uw collega's</span>
              </a>
              <button
                type="submit"
                className="inline-flex items-center justify-center rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Opslaan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Beantwoorden;
