import {
  ChevronDownIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const SideBar = ({
  navigation,
  classNames,
  location,
  user,
  toggleOpen,
  open,
}) => {
  const [showDetails, setshowDetails] = useState(false);
  return (
    <div
      className={`hidden lg:w-full lg:max-w-xs lg:px-6 lg:flex-1 lg:fixed lg:inset-y-0 lg:left-0 lg:z-10 lg:block lg:w-50 lg:overflow-y-auto ${
        user?.role == 2 ? "bg-[#002614] dark:bg-gray-900" : "bg-gray-700"
      } lg:pb-4`}
    >
      <div className="flex h-16 shrink-0 items-center justify-center">
        <img
          className="h-10 w-auto"
          src="https://sam.xjy.mybluehost.me/wp-content/uploads/2024/03/repeatconnect2.png"
          alt="Repeathealth"
        />
      </div>
      <nav className="flex flex-1 flex-col">
        <ul className="-mx-2 flex-1 space-y-1">
          {navigation(user)
            .map((item) => (
              <li key={item.name}>
                <NavLink
                  to={item.href}
                  className={({ isActive }) =>
                    classNames(
                      isActive || location.pathname.startsWith(item.href)
                        ? "bg-gray-800 text-white"
                        : "text-gray-400 hover:text-white hover:bg-gray-800",
                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    )
                  }
                >
                  <div className="flex-1 flex gap-x-3">
                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                  {item.name}
                  </div>
                  {item?.children && (
                    <>
                      {showDetails ? (
                        <button title="Sluit de details" className="  p-1 rounded-full" onClick={() => setshowDetails(false)}>
                          {" "}
                          <ChevronDownIcon className="h-4 w-4 shrink-0" />{" "}
                        </button>
                      ) : (
                        <button title="Open voor details" className=" p-1 rounded-full"  onClick={() => setshowDetails(true)}>
                          {" "}
                          <ChevronRightIcon className="h-4 w-4 shrink-0" />{" "}
                        </button>
                      )}
                    </>
                  )}
                </NavLink>
                {item?.children && showDetails && (
                  <ul className="ml-4 space-y-1">
                    {item.children.map((child) => (
                      <li key={child.name}>
                        <NavLink
                          to={child.href}
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? "bg-gray-800 text-white"
                                : "text-gray-400 hover:text-white hover:bg-gray-800",
                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            )
                          }
                        >
                          <child.icon
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {child.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
        </ul>
      </nav>
    </div>
  );
};

export default SideBar;
