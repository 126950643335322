
import React, { useEffect, useState, useLayoutEffect } from "react";
import moment from "moment";
import { titleDayArray, generateDaysArrayForEetdagboek,classNames } from "./calendarUtils";
import CrudEvent from "../Calender/CrudEvent";
import ShowInfoAfspraak from "../Calender/ShowInfoAfspraak";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";


const CalendarHeader = ({ currentDate, onPrevious, onNext, setView }) => (
  <header className="flex items-center dark:bg-gray-700 dark:text-white  justify-between border-b border-gray-200 px-6 py-4">
    <button
      type="button"
      onClick={onPrevious}
      className="-m-1.5 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
    >
      <ChevronLeftIcon className="h-7 w-7" aria-hidden="true" />
    </button>

    <h1 className="text-base font-semibold leading-6 text-gray-900 dark:bg-gray-700 dark:text-white ">
      {moment(currentDate).locale('nl-be').format("MMMM YYYY")}
    </h1>

    <button
      type="button"
      onClick={onNext}
      className="-m-1.5 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
    >
      <ChevronRightIcon className="h-7 w-7" aria-hidden="true" />
    </button>

    <button
      type="button"
      onClick={() => setView(1)}
      className="ml-6 rounded-md bg-green-800 px-3 py-2 text-sm font-semibold text-white hover:bg-green-500"
    >
      Eetdagboek View
    </button>
  </header>
);

const CalendarDayCell = ({ day, onShowEvent }) => (
  <div
    className={classNames(
      day?.isCurrentMonth ? "bg-white dark:bg-gray-700 dark:text-white " : " dark:bg-gray-500 dark:text-white  bg-gray-50 text-gray-500",
      "relative px-3 py-2 h-28"
    )}
  >
    <time
      dateTime={day?.date}
      className={day?.isToday ? "flex h-6 w-6 items-center justify-center rounded-full bg-green-600 text-white" : undefined}
    >
      {day?.date?.split("-").pop().replace(/^0/, "")}
    </time>
    {day?.events?.length > 0 && (
      <ol className="mt-2">
        {day?.events?.slice(0, 2).map((event) => (
          <li key={event?.eetdagboek_id} onClick={() => onShowEvent(event)}>
            <p className="font-medium text-gray-900 text-xs bg-green-100 p-1">
              {event?.eetdagboek_title}
            </p>
          </li>
        ))}
        {day?.events?.length > 2 && (
          <li className="text-gray-500">+ {day?.events?.length - 2} more</li>
        )}
      </ol>
    )}
  </div>
);

const MonthViewEetdagboek = ({ user, userdetails, appointments, setView }) => {
  const [currentDate, setCurrentDate] = useState(moment().locale('nl-be').format("YYYY-MM"));
  const [days, setDays] = useState(generateDaysArrayForEetdagboek(currentDate, null, appointments));
  const [openCreateEvent, setOpenCreateEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useLayoutEffect(() => {
    setDays(generateDaysArrayForEetdagboek(currentDate, null, appointments));
  }, [appointments]);

  useEffect(() => {
    setDays(generateDaysArrayForEetdagboek(currentDate, null, appointments));
  }, [currentDate]);

  const handlePreviousMonth = () => {
    setCurrentDate(moment(currentDate).subtract(1, "month").format("YYYY-MM"));
  };

  const handleNextMonth = () => {
    setCurrentDate(moment(currentDate).add(1, "month").format("YYYY-MM"));
  };

  const showEvent = (event) => {
    setSelectedEvent(event);
  };

  return (
    <div className="lg:flex  m-8 border-2 bg-gray-200 dark:bg-gray-100 rounded lg:flex-col">
      <CalendarHeader
        currentDate={currentDate}
        onPrevious={handlePreviousMonth}
        onNext={handleNextMonth}
        setView={setView}
      />
      
      <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold">
        {titleDayArray.map((title, key) => (
          <div key={key} className="bg-white dark:bg-gray-700 dark:text-white  py-2">{title}</div>
        ))}
      </div>

      <div className="grid grid-cols-7 grid-rows-5 gap-px">
        {days.map((day) => (
          <CalendarDayCell key={day.date} day={day} onShowEvent={showEvent} />
        ))}
      </div>
    </div>
  );
};

export default MonthViewEetdagboek;