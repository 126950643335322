import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import uuid from "react-uuid";
import { AXIOS_POST, AXIOS_UPDATE } from "../../server/api/crud";
import Swal from "sweetalert2";
import moment from "moment";
import {
  setAppointmentInWacht,
  setAppointmentUpdate,
} from "../../redux/appointmentSlice";
import { getUserdetails } from "../../Localstorage/localstorage";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import RP_Avatar from "../RP_Avatar";

function calculateFollowHour(type, time = "12:45:00") {
  // Create a Date object with the starting time
  const [hours, minutes] = time.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes);


  // Add 46 minutes
  if (type === "Follow up - 46min") {
    date.setMinutes(date.getMinutes() + 46);
  } else {
    date.setMinutes(date.getMinutes() + 60);
  }

  // Format the result in HH:MM:SS
  const result = date.toTimeString().split(" ")[0].slice(0, 5); // Extract HH:mm
  return result;
}

function getColor(type) {
  let result = "";
  // Add 46 minutes
  if (type == "Follow up - 46min") {
    result = "green";
  } else {
    result = "green";
  }

  return result;
}

export default function CrudEventAdmin({
  openModel = false,
  setOpenModal,
  admin,
}) {
  const userdetails = getUserdetails();
  const userdetail = userdetails || getUserdetails();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [newEvent, setNewEvent] = useState({
    id: null,
    title: "",
    description: "",
    startTime: "08:00",
    endTime: "",
    type: "Follow up - 46min",
    color: "",
    name: "",
    date: "",
    cols: 0,
    datetime: "",
    user_id: userdetail?.user_id,
    user: userdetail?.full_name,
    user_avatar: userdetail?.avatar,
    admin_id: admin?.user_id,
    admin: admin?.full_name,
    location: "",
  });


  // Create or Update event
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Create new event
      // Update existing event
      let newobject = newEvent;
      newobject.id = uuid();
      newobject.admin_id = admin?.user_id;
      newobject.admin = admin?.full_name;
      newobject.date = moment(newEvent?.datetime).format("YYYY-MM-DD");
      newobject.datetime = moment(newEvent?.datetime).format(
        "YYYY-MM-DD HH:MM:SS"
      );

    

      await AXIOS_POST(`afspraak`, newobject).then((respons) => {
        if (respons.status == 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Afspraak is geboekt met de specialist: ${admin?.full_name} op ${moment(newobject.datetime).format('LLL')}`,
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
      dispatch(setAppointmentInWacht(newobject));
      resetForm();
      setOpenModal(false);
      navigate('/planning');

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setNewEvent((prev) => ({
      ...prev,
      endTime: calculateFollowHour(newEvent.type, newEvent.startTime),
    }));
  }, [newEvent.startTime, newEvent.type]);

  useEffect(() => {
    setNewEvent((prev) => ({
      ...prev,
      cols:
        moment(newEvent.datetime).day() == 0
          ? "col-start-7"
          : `col-start-${moment(newEvent.datetime).day()}`,
    }));
  }, [newEvent.datetime]);

  // Reset form to initial state
  const resetForm = () => {
    setNewEvent({
      id: null,
      title: "",
      description: "",
      startTime: "08:00",
      endTime: "",
      type: "Follow up - 46min",
      color: "",
      name: "",
      date: "",
      cols: 0,
      datetime: "",
      user_id: userdetail?.user_id,
      user: userdetail?.full_name,
      user_avatar: userdetail?.avatar,
      admin_id: admin?.user_id,
      admin: admin?.full_name,
      location: "",
    });
    setOpenModal(false);
  };

  return (
    <Dialog open={openModel} onClose={setOpenModal} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="container mx-auto p-4">
              <h1 className="text-2xl font-semibold mb-4">Afspraak</h1>

              {/* Event Form */}
              <form className="mb-6" onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 mb-4">
                  <div
                    className={`inline-flex items-center gap-x-1.5 rounded dark:bg-gray-100 bg-gray-600 px-3 py-2 text-white shadow-sm`}
                  >
                    <RP_Avatar avatar={admin?.avatar} size={8} alt={` ${admin?.full_name}`} />
                    <p className="text-sm font-semibold dark:text-gray-800">{admin?.full_name}</p>
                  </div>
                </div>
                <div className="grid grid-cols-1 mb-4">
                  <select
                    id="category"
                    name="category"
                    defaultValue={newEvent.category}
                    onChange={(e) =>
                      setNewEvent({
                        ...newEvent,
                        type: e.target.value,
                        color: getColor(newEvent.type),
                      })
                    }
                    className="border p-2"
                  >
                    <option value={"Follow up - 46min"}>
                      Follow up - 46min
                    </option>
                    <option value={"Follow up EFT - 1uur"}>
                      Follow up EFT - 1uur
                    </option>
                  </select>
                </div>
                <div className="grid grid-cols-1 mb-4">
                  <input
                    type="date"
                    placeholder="datum"
                    className="border p-2"
                    value={moment(newEvent.datetime).format("YYYY-MM-DD")}
                    onChange={(e) =>
                      setNewEvent({ ...newEvent, datetime: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <select
                    className="border p-2"
                    value={newEvent.startTime}
                    onChange={(e) =>
                      setNewEvent({ ...newEvent, startTime: e.target.value })
                    }
                    required
                  >
                    {/* Display time options in :00 and :30 increments */}
                    {Array.from({ length: 15 }, (_, i) => {
                      const hour = (i + 8).toString().padStart(2, "0"); // Pad the hour to 2 digits
                      return (
                        <>
                          <option value={`${hour}:00`}>{`${hour}:00`}</option>
                        </>
                      );
                    })}
                  </select>
                  {/*             <input
                    type="time"
                    placeholder="Start Time"
                    className="border p-2"
                    value={newEvent.startTime}
                    onChange={(e) =>
                      setNewEvent({ ...newEvent, startTime: e.target.value })
                    }
                    step={"1800"}
                    required
                  /> */}
                  <span placeholder="End Time" className="border p-2">
                    {moment(newEvent.endTime, "HH:mm:ss").format("HH:mm")}
                  </span>
                </div>
                <div className="grid grid-cols-1 mt-4">
                  <textarea
                    type="text"
                    rows={3}
                    placeholder="Opmerking"
                    className="border p-2"
                    value={newEvent?.description}
                    onChange={(e) =>
                      setNewEvent({ ...newEvent, description: e.target.value })
                    }
                    
                  />
                </div>
                <button
                  type="submit"
                  className="bg-green-800 text-white px-4 py-2 mt-4 rounded"
                >
                  Afspraak maken
                </button>
                <button
                  type="button"
                  onClick={resetForm}
                  className="bg-gray-500 text-white px-4 py-2 mt-4 ml-4 rounded"
                >
                  Cancel
                </button>
              </form>

              {/* Event List */}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
