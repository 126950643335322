import React, { useLayoutEffect, useState } from "react";
import { Fragment } from "react";
import {
  CalendarIcon,
  EllipsisHorizontalIcon,
  MapPinIcon,
} from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import moment from "moment";
import LittleCalendarUpcoming from "./LittleCalendarUpcoming";
import { useSelector } from "react-redux";
import { generateDaysArrayUpComing } from "./calenderUtilties";
import RP_Avatar from "../RP_Avatar";

const currentMonth = moment().format("YYYY-MM");

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MeetingComponent = ({ meetings, day }) => {
  const [events, setEvents] = useState([]);

  useLayoutEffect(() => {
    let array = meetings.filter(
      (item) =>
        moment(item.date).locale("nl-be").format("YYYY-MM-DD") ===
        moment(day.date).locale("nl-be").format("YYYY-MM-DD")
    );
    setEvents(array);
  }, [day.date]);

  return (
    <>
      {events.length > 0 ? (
        <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
          {events.map((meeting) => (
            <li
              key={meeting?.id}
              className="relative flex space-x-6 py-6 xl:static"
            >
              <RP_Avatar avatar={meeting?.user_avatar} size={14} alt={` ${meeting?.user}`} />
              <div className="flex-auto">
                <h3 className="pr-10 font-semibold text-gray-900 xl:pr-0 dark:text-white">
                  {meeting.user}{" "}
                  <span className="text-xs text-gray-500 dark:text-gray-400">
                    (Specialist: {meeting?.admin})
                  </span>
                </h3>
                <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row dark:text-gray-300">
                  <div className="flex items-start space-x-3">
                    <dt className="mt-0.5">
                      <span className="sr-only">Date</span>
                      <CalendarIcon
                        className="h-5 w-5 text-gray-400 dark:text-gray-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd>
                      <time dateTime={meeting.datetime}>
                        {moment(meeting.datetime).locale("nl-be").format("LL")}{" "}
                        om{" "}
                        {moment(meeting.startTime, "HH:mm:ss")
                          .locale("nl-be")
                          .format("HH:mm")}{" "}
                        -{" "}
                        {moment(meeting.endTime, "HH:mm:ss")
                          .locale("nl-be")
                          .format("HH:mm")}
                      </time>
                    </dd>
                  </div>
                  <div className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                    <dt className="mt-0.5">
                      <span className="sr-only">Location</span>
                      <MapPinIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd>{meeting.type}</dd>
                  </div>
                </dl>
              </div>
              <Menu
                as="div"
                className="absolute right-0 top-6 xl:relative xl:right-auto xl:top-auto xl:self-center"
              >
                <div>
                  <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
                    <span className="sr-only">Open options</span>
                    <EllipsisHorizontalIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            Edit
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            Cancel
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </li>
          ))}
        </ol>
      ) : (
        <div className="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
          <span>Geen afspraken beschikbaar op de geselecteerd datum</span>
        </div>
      )}
    </>
  );
};

const UpcomingAgenda = () => {

  const [dayselected, setDayselected] = useState({});
  const { appointments } = useSelector((state) => state.appointment);

  return (
    <div className="p-4">
      <h2 className="text-base font-semibold leading-6 text-gray-900 py-4 dark:text-gray-100">
        Afspraken
      </h2>
      <div className="lg:grid lg:grid-cols-12 lg:gap-x-16">
        <LittleCalendarUpcoming
          currentMonth={currentMonth}
          setDayselected={setDayselected}
          appointments={appointments}
          dayselected={dayselected}
          generateDaysArray={generateDaysArrayUpComing}
        />
        <MeetingComponent meetings={appointments} day={dayselected} />
      </div>
    </div>
  );
};

export default UpcomingAgenda;
