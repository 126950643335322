'use client'

import { CheckCircleIcon, InboxArrowDownIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import Select2 from "../../Componenten/Select2";
import { AXIOS_GET } from "../../server/api/crud";
import { 
  setMailMessage, 
  setMailMessageItemsFilterLoad, 
  setMailMessageItemsLoad, 
  setMailRoomsLoad 
} from "../../redux/mailSlice";
import { MAKE_SOCKET_CHANNEL, SOCKET_CHANNELS } from "../../constants/constants";
import { IOSOCKETCLIENT, setIOSocketListeners } from "../../linksocket/linksocket";
import { io } from "socket.io-client";
import SelectPatienten from "../../Componenten/SelectPatienten";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const MailboxAdmin = () => {
  const dispatch = useDispatch();
  const { mailRooms, mailMessage } = useSelector((state) => state.mail);
  const { patienten } = useSelector((state) => state.user);
  let channel, channelroom;
  const [selected, setSelected] = useState(patienten[0]);

  const socket = io.connect("http://localhost:4000");

  const updateMailMessage = (message) => {
    dispatch(setMailMessage(message));
  };

  const fetchMailRooms = async (id) => {
    try {
      const response = await AXIOS_GET(`mailbox/mailmessageById/${id}`);
      dispatch(setMailRoomsLoad(response.data.data));
      dispatch(setMailMessage(response.data.data[0]));
    } catch (error) {
      console.error("Error fetching mailrooms", error);
    }
  };

  const fetchMailMessages = async (id) => {
    try {
      const response = await AXIOS_GET(`mailbox/messageItemsByUser/${id}`);
      dispatch(setMailMessageItemsLoad(response.data.data));
      dispatch(setMailMessageItemsFilterLoad(response.data.data));
    } catch (error) {
      console.error("Error fetching mail messages", error);
    }
  };

  const refreshMail = useCallback(
    (payload) => {
      getMail(payload);
    },
    [selected]
  );

  const getMail = useCallback(async (item) => {
    setSelected(item);
    await Promise.all([fetchMailRooms(item?.user_id), fetchMailMessages(item?.user_id)]);
  },[fetchMailRooms,fetchMailMessages, setSelected]);


// In your useEffect
useEffect(() => {
  
  if (selected?.user_id && mailMessage?.id) {
      const channel = MAKE_SOCKET_CHANNEL(mailMessage?.id, SOCKET_CHANNELS.MAIL_ITEM);
      setIOSocketListeners(channel, SOCKET_CHANNELS.MAIL_ITEM, () => refreshMail(selected)); // Pass as a callback
  }

  return () => {
      if (selected?.user_id && mailMessage?.id) {
          IOSOCKETCLIENT.emit("JOINLEAVEROOM", { action: "leave", channelroom });
      }
  }
}, [selected, mailMessage?.id, refreshMail, IOSOCKETCLIENT]);



  return (
    <aside className="hidden xl:order-first xl:block xl:flex-shrink-0">
      <div className="relative flex h-full w-96 flex-col border-r border-gray-200 bg-white dark:bg-gray-600 dark:text-white">
        <div className="flex-shrink-0">
          <div className="flex py-4 flex-col justify-center  px-6 dark:text-white">
            <div className="flex items-baseline space-x-3">
              <h2 className="text-lg font-medium text-gray-900 flex items-center gap-x-2 dark:text-white">
                <span className="bg-transparent inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-lg">
                  <InboxArrowDownIcon
                    aria-hidden="true"
                    className="h-6 w-6 text-gray-400 dark:text-white"
                  />
                </span>
                Postvak In
              </h2>
            </div>
            <div>
              <div className="my-2">
                {patienten?.length > 0 && (
                  <SelectPatienten
                    selected={selected || patienten[0]}
                    setSelected={getMail}
                    data={patienten}
                  />
                )}
              </div>
              <p className="ml-12 text-xs font-medium text-gray-500 dark:text-white">
                Aantal Berichten {mailRooms?.length || 0}
              </p>
            </div>
          </div>
        </div>
        <nav
          aria-label="Message list"
          className="min-h-0 flex-1 overflow-y-auto mt-1 p-1"
        >
          <ul
            role="list"
            className="divide-y divide-gray-100 border-b border-gray-100"
          >
            {mailRooms?.length > 0 &&
              mailRooms?.map((message) => (
                <li
                  key={message?.id}
                  onClick={() => updateMailMessage(message)}
                  className="m-2 relative rounded-md hover:bg-green-50"
                >
                  <div
                    className={classNames(
                      message.id === mailMessage?.id
                        ? "bg-green-100 ring-green-500 ring-1 m-1"
                        : "bg-white",
                      "rounded-lg p-4 hover:bg-green-50"
                    )}
                  >
                    {message.id === mailMessage?.id && (
                      <CheckCircleIcon className="absolute right-4 h-8 w-8 text-green-700" />
                    )}
                    <div className="flex justify-between space-x-3 rounded-md ring-blue-200 ring-3">
                      <div className="min-w-0 flex-1">
                        <span className="block focus:outline-none">
                          <span aria-hidden="true" className="absolute inset-0" />
                          <p className="truncate text-sm font-medium text-gray-900">
                            {message?.sender}
                          </p>
                          <p className="truncate text-sm text-gray-500">
                            {message?.subject}
                          </p>
                        </span>
                      </div>
                    </div>
                    <div className="mt-1">
                      <time
                        dateTime={message?.date}
                        className="flex-shrink-0 whitespace-nowrap text-xs right-0 text-gray-500"
                      >
                        {moment(message?.datetime).format("LLL")}
                      </time>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default MailboxAdmin;
