import { HandThumbUpIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick/lib/slider";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const RPStepperWithPage = ({ datas, user ,navigation}) => {
    // Determine the initial slide index
  const id = datas.find((item) => item.status === "current")?.id;
  const [slideIndex, setSlideIndex] = useState(0);
  const [updateCount, setUpdateCount] = useState(0); 
  
useEffect(() => {
  if(id !== undefined)
  setSlideIndex(id)

}, [id]);

  

  return (
    <section>
      <div className="lg:border-b lg:border-t lg:border-gray-200">
        <nav
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          aria-label="Progress"
        >
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200 "
          >
            {datas.map((step, stepIdx) => (
              <li
              {
                ...(step.status !== "upcoming" && {
                  onClick: () => setSlideIndex(stepIdx),
                })
              } key={step.id}
                className={classNames(
                  stepIdx == slideIndex  ? 'bg-gray-100 dark:bg-gray-600': '',
                  `relative overflow-hidden lg:flex-1 dark:bg-gray-900`)}
              >
                <div
                  className={classNames(
                    stepIdx === 0 ? "rounded-t-md border-b-0" : "",
                    stepIdx === datas.length - 1
                      ? "rounded-b-md border-t-0"
                      : "",
                    "overflow-hidden border border-gray-200 lg:border-0"
                  )}
                >
                  {step.status === "complete" ? (
                    <a href={step.href} className="group">
                      <span
                        className={` absolute left-0 top-0 dark:bg-gray-100 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full`}
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          stepIdx !== 0 ? "lg:pl-9" : "",
                          "flex items-start px-6 py-5 text-sm font-medium"
                        )}
                      >
                        <span className="flex-shrink-0">
                          <span className="flex h-10 w-10 items-center justify-center rounded-full bg-green-600">
                            <CheckIcon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </span>
                        <span className="mx-4 mt-0.5 flex min-w-0 flex-col">
                          <span className="text-sm font-medium dark:text-gray-100">
                            {step.name}
                          </span>
                          <span className="text-xs font-small text-gray-500 dark:text-gray-100">
                            {step.description}
                          </span>
                        </span>
                      </span>
                    </a>
                  ) : step.status === "current" ? (
                    <a href={step.href} aria-current="step">
                      <span
                        className="absolute left-0 top-0 h-full w-1 bg-green-600 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          stepIdx !== 0 ? "lg:pl-9" : "",
                          "flex items-start px-6 py-5 text-sm font-medium"
                        )}
                      >
                        <span className="flex-shrink-0">
                          <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-green-600 dark:text-gray-100">
                            <span className="text-green-600 dark:text-gray-100">{step.id}</span>
                          </span>
                        </span>
                        <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                          <span className="text-sm font-medium text-green-600 dark:text-gray-100">
                            {step.name}
                          </span>
                          <span className="text-xs font-medium text-gray-500 dark:text-gray-100">
                            {step.description}
                          </span>
                        </span>
                      </span>
                    </a>
                  ) : step.status === "upcoming" ? (
                    <div className="group">
                      <span
                        className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          stepIdx !== 0 ? "lg:pl-9" : "",
                          "flex items-start px-6 py-5 text-sm font-medium"
                        )}
                      >
                        <span className="flex-shrink-0">
                          <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300 dark:text-gray-100">
                            <span className="text-gray-500 dark:text-gray-400">{step.id}</span>
                          </span>
                        </span>
                        <span className="ml-4 mt-0.5 flex min-w-0 flex-col ">
                          <span className="text-sm font-medium text-gray-500 dark:text-gray-300">
                            {step.name}
                          </span>
                          <span className="text-xs font-medium text-gray-500">
                            {step.description}
                          </span>
                        </span>
                      </span>
                    </div>
                  ) : (
                    <div className="group">
                      <span
                        className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          stepIdx !== 0 ? "lg:pl-9" : "",
                          "flex items-start px-6 py-5 text-sm font-medium"
                        )}
                      >
                        <span className="flex-shrink-0">
                          <span className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-600 dark:bg-gray-600">
                            <HandThumbUpIcon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </span>
                        <span className="mx-4 mt-0.5 flex min-w-0 flex-col">
                          <span className="text-sm font-medium dark:text-gray-100">
                            {step.name}
                          </span>
                          <span className="text-xs font-small text-gray-500 dark:text-gray-200">
                            {step.description}
                          </span>
                        </span>
                      </span>
                    </div>
                  )}

                  {stepIdx !== 0 ? (
                    <>
                      {/* Separator */}
                      <div
                        className="absolute inset-0 left-0 top-0 hidden w-4 lg:block"
                        aria-hidden="true"
                      >
                        <svg
                          className={classNames(
                            stepIdx == slideIndex || stepIdx == slideIndex+1 ? 'text-gray-600 dark:text-green-500': 'text-gray-300',
                            "h-full w-full  dark:text-gray-100")}
                          viewBox="0 0 12 82"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M0.5 0V31L10.5 41L0.5 51V82"
                            stroke="currentcolor"
                            vectorEffect="non-scaling-stroke"
                          />
                        </svg>
                      </div>
                    </>
                  ) : null}
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="slider-container h-dvh">
          {datas.map((step, stepIdx) => (
            <div key={stepIdx} className="px-4 py-4">
              {
                slideIndex == stepIdx &&
                <step.component  user={user} navigation={navigation} />
              }
            </div>
          ))}
      </div>
    </section>
  );
};

export default RPStepperWithPage;
