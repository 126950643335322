"use client";

import { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import TextEditor from "../TextEditor";

const RPDrawerComment = ({
  item,
  open,
  setOpen,
  admin,
  setUpdateAdmin,
  onClick = () => {},
  user = false,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateAdmin((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10 bg-red">
      <div className="fixed inset-0" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-3xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              {user ? (
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl dark:bg-gray-600">
                <div className="bg-gray-700 px-4 py-6 sm:px-6">
                  <div className="flex items-center justify-between">
                    <DialogTitle className="text-base font-semibold leading-6 text-white">
                    
                      <div> <span className="text-lg">Opmerking geplaatst dooor: {item?.admin}</span></div>
                      <div> <span className="text-xs">Title: ({item?.title})</span></div>
                    </DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={() => setOpen(false)}
                        className="relative rounded-md bg-gray-700 text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                  <p className="text-gray-100 text-sm">
                    <time dateTime={item?.datum}>
                      {moment(item?.datum)
                        .locale("nl-be")
                        .format("DD/MM/YYYY hh:mm")}
                    </time>
                  </p>
                </div>
                  <label className="text-lg text-gray-900 p-4 dark:text-gray-100 ">Score: {admin?.score}/10</label>
                  <div
                    dangerouslySetInnerHTML={{ __html: admin?.opmerking }}
                    className={`mt-1 space-y-2 dark:text-white text-sm text-gray-800 p-4`}
                  />
                </div>
              ) : (
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="bg-gray-700 px-4 py-6 sm:px-6">
                    <div className="flex items-center justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-white">
                        {item?.title}
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => setOpen(false)}
                          className="relative rounded-md bg-gray-700 text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                    <p className="text-gray-100 text-sm">
                      <time dateTime={item?.datum}>
                        {moment(item?.datum)
                          .locale("nl-be")
                          .format("DD/MM/YYYY hh:mm")}
                      </time>
                    </p>
                  </div>
                  <div className="mt-1 p-4">
                    <label className="text-sm text-gray-900">Opmerking</label>
                    {/*  <textarea
                    id="opmerking"
                    name="opmerking"
                    value={admin?.opmerking || ""}
                    onChange={handleChange}
                    rows={25}
                    className="block w-full rounded-md border-0 mt-2 py-4 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                  /> */}
                    <TextEditor
                      value={admin}
                      setValue={setUpdateAdmin}
                      key={"opmerking"}
                    />
                  </div>
                  <div className="flex flex justify-between items-end">
                    <div className="mt-1 p-4">
                      <label
                        htmlFor="score"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Score
                      </label>
                      <div className="mt-2">
                        <input
                          id="score"
                          name="score"
                          value={admin?.score}
                          type="text"
                          onChange={handleChange}
                          placeholder="0/10"
                          className="block w-16 px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        onClick={() => setOpen(false)}
                        className="rounded-md bg-white px-3 py-2 h-10 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                      >
                        Annuleer
                      </button>
                      <button
                        onClick={onClick}
                        type="submit"
                        className="ml-2 rounded-md bg-gray-800 px-3 py-2 h-10 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                      >
                        Opslaan
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default RPDrawerComment;
