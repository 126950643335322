import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Filler,
  Tooltip,
  Legend
);

const VooruitgangChart = ({
  selectedMetric,
  setSelectedMetric,
  metricData,
  averageScore,
}) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const chartOptions = {
    color: isDarkMode ? "#4B5563" : "#374151", // Gray-300 for dark mode, Gray-700 for light mode
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: isDarkMode ? "#4B5563" : "white",
        },
        grid: {
          color: isDarkMode ? "#4B5563" : "#E5E7EB",
        },
      },
      y: {
        ticks: {
          color: isDarkMode ? "#4B5563" : "white",
        },
        grid: {
          color: isDarkMode ? "#4B5563" : "#E5E7EB",
        },
      },
    },

    plugins: {
      legend: {
        labels: {
          color: isDarkMode ? "#4B5563" : "white",
        },
      },
      tooltip: {
        callbacks: {
          // Customize the title of the tooltip
          title: function (tooltipItems) {
            return `${tooltipItems[0].label}`;
          },
          // Customize the label of the tooltip
          label: function (tooltipItem) {
            const index = tooltipItem?.dataIndex; // Get the index of the data point
            const dataset = tooltipItem?.dataset; // Access the dataset
            const rawData = metricData[selectedMetric][index]; // Access the corresponding data object

            return `Score: ${tooltipItem?.raw} ${
              rawData?.uitleg ? " | Meer uitleg : " + rawData?.uitleg : ""
            }`;
          },
          // Optionally add footer
          footer: function (tooltipItems) {
            return `Extra Info: ${tooltipItems.length} points`;
          },
        },
        enabled: false, // Disable the default tooltip
        external: function (context) {
          // Create the custom tooltip element if it doesn't exist
          let tooltipEl = document.getElementById("chartjs-tooltip");
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.padding = "0px";
            tooltipEl.style.pointerEvents = "none";
            tooltipEl.style.transition = "all 0.2s ease";
            document.body.appendChild(tooltipEl);
          }

          const tooltipModel = context?.tooltip;

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
          }

          // Set tooltip content
          if (tooltipModel?.body) {
            const bodyLines = tooltipModel?.body.map((item) => item?.lines);
            const index = tooltipModel?.dataPoints[0]?.dataIndex; // Index of the point
            const rawData = metricData[selectedMetric][index]; // Custom data

            let innerHtml = `
            <div class="text-xs" ><strong class="dark:text-white" >Date:</strong> <span class="dark:text-white">${tooltipModel?.title[0]}</span></div>
            <div class="text-xs flex items-center gap-x-1">
              <div class="bg-[#818CF833] dark:bg-[#34D39933] border-2 border-[#818CF8] dark:border-[#34D399] w-3 h-3 rounded"></div>  
              <strong class="dark:text-white">Score:</strong> <span class="dark:text-white">${tooltipModel?.dataPoints[0].raw}</span>
            </div>`;

            if (rawData?.uitleg) {
              innerHtml += `
            <div class="text-xs mt-2 dark:text-white"><strong>More Info:</strong> </div>
            <div class="text-xs dark:text-white">${rawData?.uitleg}</div>`;
            }

            tooltipEl.innerHTML =
              '<div class="max-w-56 p-2 bg-white rounded border-4 dark:border-[#34D39933]  border-[#818CF833] dark:bg-gray-700 shadow dark:bg-gray-800">' +
              innerHtml +
              "</div>";
          }

          // Set tooltip position
          const canvas = context.chart.canvas;
          const position = canvas.getBoundingClientRect();

          tooltipEl.style.opacity = "1";
          tooltipEl.style.left =
            position.left +
            window.pageXOffset +
            tooltipModel.caretX -
            90 +
            "px";
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + "px";
        },
      },
    },
  };

  // Check if dark mode is active
  useEffect(() => {
    const checkDarkMode = () => {
      setIsDarkMode(
        window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: light)").matches
      );
    };

    checkDarkMode();
    window.addEventListener("resize", checkDarkMode);

    return () => window.removeEventListener("resize", checkDarkMode);
  }, []);

  console.log(selectedMetric)

  return (
    <div className="h-96 mb-2 pb-20 border p-4 rounded">
      {/* Time Filter */}
      <label
        className="text-xl text-gray-600 dark:text-gray-100"
        htmlFor="time-filter"
      >
        Vooruitgang
      </label>
      <div className="flex justify-between items-center mt-4 gap-x-4">
        <select
          id="time-filter"
          onChange={(e) => setSelectedMetric(e.target.value)}
          className="ml-2 p-1 border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-200"
        >
          <option value="slaap">Slaapkwaliteit</option>
          <option value="energie">Energieniveau</option>
          <option value="stress">Stressniveau</option>
          <option value="cravings">Cravings</option>
        </select>

      <p className="text-xs mb-2 text-gray-600 dark:text-gray-100">
          Gemiddelde score : <span className={`${averageScore[selectedMetric] > 5 ? 'bg-green-600 text-white' : 'bg-red-600 text-white'} p-1 rounded-md`}>{averageScore[selectedMetric]}</span>
        </p>
      </div>
      
      <Line
        data={{
          labels: metricData[selectedMetric]?.map(
            (item) => `Dag ${moment(item.date).format("L")}`
          ),
          datasets: [
            {
              label: `${selectedMetric} Niveau`,
              data: metricData[selectedMetric]?.map((item) => item.score),
              borderColor: isDarkMode ? "#818CF8" : "#34D399", // Indigo-400 for dark mode, Green-400 for light mode
              backgroundColor: isDarkMode
                ? "rgba(129, 140, 248, 0.2)" // Lighter indigo for dark mode
                : "rgba(52, 211, 153, 0.2)", // Lighter green for light mode
              fill: true,
              tension: 0.3,
            },
          ],
        }}
        options={chartOptions}
      />
    </div>
  );
};

export default VooruitgangChart;
