import moment from "moment";
import { useEffect, useLayoutEffect, useState } from "react";

const eve = [
  {
    title: "Breakfast",
    startTime: "06:00",
    endTime: "07:00", // endTime instead of duration
    color: "bg-blue-50 text-blue-700 hover:bg-blue-100",
    colStart: 3,
  },
  {
    title: "Flight to Paris",
    startTime: "07:30",
    endTime: "09:30", // endTime instead of duration
    color: "bg-pink-50 text-pink-700 hover:bg-pink-100",
    colStart: 3,
  },
  {
    title: "Meeting with design team at Disney",
    startTime: "10:00",
    endTime: "11:30", // endTime instead of duration
    color: "bg-gray-100 text-gray-700 hover:bg-gray-200",
    colStart: 6,
  },
  {
    title: "Testing",
    startTime: "12:55",
    endTime: "19:55", // endTime instead of duration
    color: "bg-gray-100 text-gray-700 hover:bg-gray-200",
    colStart: 1,
  },
];

// Function to calculate the grid row based on start and end times
const getGridRow = (startTime, endTime) => {
  const [startHour, startMinutes] = moment(startTime,'HH:mm:ss').format('HH:mm').toString().split(":").map(Number);
  const [endHour, endMinutes] = moment(endTime,'HH:mm:ss').format('HH:mm').toString().split(":").map(Number);

  // Calculate start and end grid rows
  const startRow = (startHour- 8) * 12 + Math.round(startMinutes / 5) + 2; // Offset by 2 for header
  const endRow = (endHour - 8) * 12 + Math.round(endMinutes / 5) + 2;

  // Calculate span between start and end rows
  const span = endRow - startRow;

  return `${startRow} / span ${span}`;
};

const Skeleton = () => (
  <>
   <li
          key={1}
          className={`relative mt-px flex col-start-1`} // Apply column class if exists
        ></li>
        <li
          key={2}
          className={`relative mt-px flex col-start-2`} // Apply column class if exists
        ></li>
        <li
          key={3}
          className={`relative mt-px flex col-start-3`} // Apply column class if exists
        ></li>
        <li
          key={4}
          className={`relative mt-px flex col-start-4`} // Apply column class if exists
        ></li>
        <li
          key={5}
          className={`relative mt-px flex col-start-5`} // Apply column class if exists
        ></li>
        <li
          key={6}
          className={`relative mt-px flex col-start-6`} // Apply column class if exists
        ></li>
        <li
          key={7}
          className={`relative mt-px flex col-start-7`} // Apply column class if exists
        ></li>
 
  </>
)

const WeekEvents = ({evenmentes, setOpen}) => {
const [events, setevents] = useState(evenmentes)
useEffect(() => {
  
  setevents(evenmentes);
  

  return () => {
    
  };
}, [evenmentes])
  
  return (
    <ol
      className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
      style={{
        gridTemplateRows: "1.75rem repeat(180, minmax(0, 1fr)) auto",
      }}
    >
 {events.map((event, index) => {
  // Check if the event has a 'cols' property for grid column placement
  const colStartClass = event?.cols ? `${event?.cols}` : '';

  return (
    <>
    <Skeleton/>
      {event?.cols && (
        <li
          key={index}
          onClick={() => setOpen(true)}
          style={{ gridRow: getGridRow(event?.startTime, event?.endTime) }}
          className={`relative mt-px flex ${colStartClass}`} // Apply column class if exists
        >
          <span
            className="cursor-pointer group absolute inset-1 shadow-sm flex flex-col overflow-y-auto rounded-md p-2 text-xs border-1.5 border-green-600 leading-5 bg-green-100 dark:bg-green-700"
          >
            <p className="order-1 font-semibold">{event.type}</p>
            <p className="order-1 text-xs">{event.user}</p>
            <p className="group-hover:text-current">
              <time dateTime={`2022-01-12T${event.startTime}`}>
                {moment(event.startTime,"HH:mm:ss").format('HH:mm')}
              </time>{" "}
              -{" "}
              <time dateTime={`2022-01-12T${event.endTime}`}>
                {moment(event.endTime,"HH:mm:ss").format('HH:mm')}
              </time>
            </p>
          </span>
        </li>
      )}
    </>
  );
})}

    </ol>
  );
};


export default WeekEvents;