import React, { useLayoutEffect, useState } from "react";
import { AXIOS_GET, AXIOS_POST, AXIOS_UPDATE } from "../../../../server/api/crud";
import Swal from "sweetalert2";


// Generate buttons for selectable metric values
const GenerateButtons = ({ data, name, handleMetricChange }) => {
  return Array.from({ length: 11 }, (_, index) => (
    <div
      key={index}
      onClick={() => handleMetricChange(index, name)}
      className={`px-4 py-2 mx-1 font-semibold rounded-md border cursor-pointer ${
        data === index
          ? "bg-green-600 text-white"
          : "bg-transparent text-gray-700 border-gray-300 hover:bg-green-100"
      }`}
    >
      {index}
    </div>
  ));
};


const DagelijksFormulier = ({ setSelected, setOpen, voeding, userdetails }) => {
  const [formData, setFormData] = useState({
    kookfrequentie: "",
    uit_eten_frequentie: "",
    winkel_frequentie: "",
    kookvaardigheid: 5,
    plezier_in_koken: "",
    plezier_in_koken_text: "",
    voeding_id: voeding?.id,
    definitief: 0,
    user_id:userdetails?.user_id,
  });

  const handleMetricChange = (data, name) => {
    setFormData({ ...formData, [name]: data });
  };

  const getData = async () => {
    await AXIOS_GET(
      `voedsel/dagelijksegewoonten/user/${userdetails?.user_id}`).then((res) => {
      if (res.status == 200) {
        if(res.data.data.length > 0)
        setFormData(res.data.data[0])
      }
    });
  };

  useLayoutEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const saveForm = async () => {
    const id = formData?.id;
    const newObject = formData;
    newObject.definitief = 1;
    const respons = id ? await AXIOS_UPDATE(`voedsel/dagelijksegewoonten`,id, newObject) : await AXIOS_POST(`voedsel/dagelijksegewoonten`, newObject) ;
        if (respons.status == 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Bedankt met het invullen van deze formulier`,
            showConfirmButton: false,
            timer: 2500,
          });
          AXIOS_UPDATE(`voedsel/status`,voeding?.id, {status:3}).then((respons) => {
            if (respons.status == 200) {
              AXIOS_UPDATE(`voedsel/versturen`,voeding?.id, {versturen:1}).then((respons) => {
                if (respons.status == 200) {
                }
              }).catch((error)=> {})
            }
          }).catch((error)=> {})
          setOpen(false);


        }
  };

  const saveForLaterForm = async () => {
    const id = formData?.id;
    const newObject = formData;
    newObject.definitief = 0;
    const respons = id ? await AXIOS_UPDATE(`voedsel/dagelijksegewoonten`,id, formData) : await AXIOS_POST(`voedsel/dagelijksegewoonten`, formData) ;
        if (respons.status == 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Bedankt met het invullen van deze formulier`,
            showConfirmButton: false,
            timer: 2500,
          });

          setOpen(false);

        }
  };

  const verzendenForm = (e) => {
    e.preventDefault();
    saveForm();
  };

  const opslaanLaterVerderdoen = (e) => {
    e.preventDefault();
    saveForLaterForm();
  };

  return (
    <div className="mt-4 p-8 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-6xl">
      <p className="text-center p-4 rounded-md bg-gray-200 ">
        Dagelijkse gewoonten
      </p>
      <form className="space-y-8 mx-16 my-12">
        {/* Cooking Frequency */}
        <div>
          <label className="block text-lg font-semibold">
            Hoe vaak kook je per week?
          </label>
          <div className="space-x-4 mt-2">
            {[
              "0 maaltijden",
              "1-2 maaltijden",
              "3-4 maaltijden",
              "5 of meer",
            ].map((option) => (
              <label key={option} className="inline-flex items-center">
                <input
                  type="radio"
                  name="kookfrequentie"
                  value={option}
                  checked={formData?.kookfrequentie === option}
                  onChange={handleChange}
                  className="mr-2"
                />
                {option}
              </label>
            ))}
          </div>
        </div>

        {/* Eating Out Frequency */}
        <div>
          <label className="block text-lg font-semibold">
            Hoe vaak per week ga je uit eten of maak je gebruik van
            afhaalmaaltijden of maaltijdleveringen?
          </label>
          <div className="space-x-4 mt-2">
            {[
              "0 maaltijden",
              "1-2 maaltijden",
              "3-4 maaltijden",
              "5 of meer",
            ].map((option) => (
              <label key={option} className="inline-flex items-center">
                <input
                  type="radio"
                  name="uit_eten_frequentie"
                  value={option}
                  checked={formData?.uit_eten_frequentie === option}
                  onChange={handleChange}
                  className="mr-2"
                />
                {option}
              </label>
            ))}
          </div>
        </div>

        {/* Shopping Frequency */}
        <div>
          <label className="block text-lg font-semibold">
            Hoe vaak doe jij inkopen?
          </label>
          <div className="space-x-4 mt-2 grid grid-cols-3">
            {[
              "Dagelijks meerdere keren",
              "Dagelijks",
              "Om de 2 dagen",
              "Paar dagen per week",
              "1 keer per week",
              "Minder dan 1 keer per week",
              "Ik doe geen inkopen",
            ].map((option) => (
              <label key={option} className="inline-flex items-center">
                <input
                  type="radio"
                  name="winkel_frequentie"
                  value={option}
                  checked={formData?.winkel_frequentie === option}
                  onChange={handleChange}
                  className="mr-2"
                />
                {option}
              </label>
            ))}
          </div>
        </div>

        {/* Cooking Skills Rating */}
        <div>
          <label className="block text-lg font-semibold">
            Geef jouw keukenvaardigheden een score
          </label>
            <div className="flex flex-wrap mb-4">
            <GenerateButtons
              data={formData?.kookvaardigheid}
              name={"kookvaardigheid"}
              handleMetricChange={handleMetricChange}
            />
            </div>
          <div className="text-center mt-2">{formData?.kookvaardigheid}</div>
        </div>

        {/* Enjoy Cooking */}
        <div>
          <label className="block text-lg font-semibold">Kook je graag?</label>
          <div className="space-x-4 mt-2 ">
            {["Ja", "Soms", "Neen"].map((option) => (
              <label key={option} className="inline-flex items-center">
                <input
                  type="radio"
                  name="plezier_in_koken"
                  value={option}
                  checked={formData?.plezier_in_koken === option}
                  onChange={handleChange}
                  className="mr-2"
                />
                {option}
              </label>
            ))}
          </div>
          <textarea
            name="plezier_in_koken_text"
            value={formData?.plezier_in_koken_text}
            onChange={handleChange}
            rows={10}
            className="w-full p-2 border rounded mt-4"
            placeholder="Beschrijf uw reactie..."
          />
        </div>

        {/* Submit Button */}
        <div className="flex justify-between">
          <div className="gap-x-2 flex">
            <button
              onClick={() => setOpen(false)}
              className="mt-6 bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded"
            >
              Annuleer
            </button>
            <button
              onClick={() => setSelected(1)}
              className="mt-6 bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded"
            >
              Vorige
            </button>
          </div>

          <div className="flex gap-x-2">
            <button
            type="button"
            onClick={opslaanLaterVerderdoen}
            className="mt-6 bg-green-700 hover:bg-green-800 text-white py-2 px-4 rounded">
              Opslaan en later verder doen
            </button>
            <button
            type="button"
              onClick={verzendenForm}
              className="mt-6 bg-blue-700 hover:bg-blue-800 text-white py-2 px-4 rounded"
            >
              Verzenden
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DagelijksFormulier;
