import React, { useState } from "react";
import { imgrepeat } from "../../image";
import { useNavigate } from "react-router-dom";
import { AXIOS_POST } from "../../server/api/crud";
import Swal from "sweetalert2";
import uuid from "react-uuid";

const Register = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmColor, setConfirmColor] = useState(false);
  const [confirmpassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleSignUp = async () => {
    const usernameRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$|^[a-zA-Z0-9_]+$/;
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{3,}$/;

    // Validation checks for username
    if (!username || username.trim() === "") {
      alert("Gebruikersnaam mag niet leeg zijn");
      return;
    }

    if (!usernameRegex.test(username)) {
      alert("Ongeldige gebruikersnaam of e-mailadres");
      return;
    }

    // Validation checks for password
    if (!password || password.trim() === "") {
      alert("Wachtwoord mag niet leeg zijn");
      return;
    }

    if (!passwordRegex.test(password)) {
      alert(
        "Wachtwoord moet minimaal 3 karakters lang zijn, minstens één hoofdletter, één nummer en één speciaal karakter bevatten"
      );
      return;
    }

    if (confirmpassword.trim() !== password.trim()) {
      alert("Wachtwoorden zijn niet gelijk");
      return;
    }

    try {
      const roles =
        username.includes("Marleen") || username.includes("Lindsey") ? 1 : 2;
      const id = uuid();
      await AXIOS_POST("auth/signup", {
        id: id,
        username: username,
        password: password,
        role: roles,
        email: null,
      }).then((res) => {
        if (res.data.affectedRows === 1) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `Bedankt voor de registratie.
            Gelieve u aan te melden met deze gebruikersnaam: ${username}`,
            showConfirmButton: false,
            timer: 2500,
          });
          navigate("/signin");
        }
      });
    } catch (error) {
      alert("Er is een fout opgetreden bij het registreren");
      console.error("Error during sign-up:", error);
    }
  };

  const ConfirmPassword = (value) => {
    setConfirmPassword(value);
    if (value === password) {
      setConfirmColor(true);
    } else {
      setConfirmColor(false);
    }
  };
  return (
    <div className="flex h-screen w-full flex-col justify-center items-center lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-20 w-auto"
          src={imgrepeat.logo_green}
          alt="RepeatHealth"
        />
        <h1 className="mt-10 text-center text-4xl font-bold leading-9 tracking-tight text-gray-900">
          Repeatconnect.
        </h1>
        <h2 className="mt-2 text-center text-lg font-bold leading-9 tracking-tight text-gray-900">
          Gelieve u zich hieronder aan te melden.
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="space-y-10">
          <div>
            <div className="mt-2">
              <input
                id="username"
                name="username"
                type="username"
                placeholder="Gebruikersnaam"
                value={username}
                autoComplete="username"
                onChange={(e) => setUsername(e.target.value)}
                required
                className="block w-full text-lg rounded-md border-0 px-4 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-darkblue-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                $
                value={password}
                placeholder="Wachtwoord"
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                required
                className="block w-full text-lg rounded-md border-0 px-4 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-darkblue-600 sm:text-sm sm:leading-6"
              />
            </div>
            <div className="mt-2">
              <input
                id="confirm_password"
                name="confirm_password"
                type="password"
                value={confirmpassword}
                placeholder="Bevestig Wachtwoord"
                {...(!confirmColor && { title: "Wachtwoord is niet gelijk" })}
                onChange={(e) => ConfirmPassword(e.target.value)}
                autoComplete="current-confirm_password"
                required
                className={`block w-full text-lg rounded-md border-0 px-4 py-4 text-gray-900 shadow-sm  ring-inset ${
                  confirmColor ? "ring-gray-300 ring-1" : "ring-red-500 ring-2"
                } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-darkblue-600 sm:text-sm sm:leading-6`}
              />
            </div>
          </div>

          <div>
            <button
              onClick={handleSignUp}
              className="flex w-full justify-center text-lg rounded-md bg-green-900 px-4 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Aanmelden
            </button>
          </div>
        </div>

      {/*   <div className="relative mt-4">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-2 text-sm text-gray-500">Of</span>
          </div>
        </div>

        <div className="px-6 mt-4 sm:px-0 max-w-sm ">
          <button
            type="button"
            className="text-white text-lg px-4 py-4 w-full  bg-[#4285F4] hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center mr-2 mb-2"
          >
            <svg
              className="mr-2 -ml-1 w-6 h-6"
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="google"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 488 512"
            >
              <path
                fill="currentColor"
                d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
              ></path>
            </svg>
            <span className="flex ">Meld u aan met Google</span>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Register;
