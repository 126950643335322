import Swal from "sweetalert2";
import { AXIOS_UPDATE } from "../server/api/crud";

export const savePreIntakeForm = async (
  e,
  user,
  formData,
  admin,
  setOpen,
  errors,
  isDefinitive = false
) => {
  // Check if form is being submitted definitively and has errors
  if (isDefinitive && !errors) {
    return Swal.fire({
      position: "center",
      icon: "error",
      title: `Gelieve de foutieve velden te corrigeren met de juiste waarden.`,
      showConfirmButton: false,
      timer: 2500,
    });
  }

  // Check admin permissions
  if (admin !== null) {
    return Swal.fire({
      position: "center",
      icon: "error",
      title: `Enkel de patient mag formulier ${
        isDefinitive ? "versturen" : "opslaan en later verder doen"
      }`,
      showConfirmButton: false,
      timer: 2500,
    });
  }

  try {
    formData.status = isDefinitive ? "completed" : "current";
    await AXIOS_UPDATE(`preintake`, user.id, { preintake: formData });
    await AXIOS_UPDATE(
      `preintake/definitief`,
      `${user.id}/${isDefinitive ? "1" : "0"}`,
      {}
    );

    if (isDefinitive) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: `Uw Pre-intake vragenlijst is volledig ingevuld en verstuurd. Gelieve nu de volgende stap te volgende`,
        showConfirmButton: false,
        timer: 2500,
      });
    }

    setOpen(false);
  } catch (error) {
    Swal.fire({
      position: "center",
      icon: "error",
      title: `Er is iets mis gelopen gelieve alles eens opnieuw na kijken`,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};

export const updateOpmerking = async (user, formData, admin, setOpen) => {
  console.log(admin, formData);
  if (admin == null) {
    return Swal.fire({
      position: "center",
      icon: "error",
      title: `Enkel de patient mag formulier opslaan en later verder doen`,
      showConfirmButton: false,
      timer: 2500,
    });
  }

  if (
    formData.opm_admin == null ||
    formData.opm_admin == undefined ||
    formData.opm_admin == ""
  ) {
    return Swal.fire({
      position: "center",
      icon: "error",
      title: `Gelieve een opmerking in te vullen`,
      showConfirmButton: false,
      timer: 2500,
    });
  }

  try {
    const { full_name, user_id } = admin;
    const { opm_admin } = formData;

    await AXIOS_UPDATE(`preintake/opmerkingadmin`, user.user_id, {
      opmerking: { admin: full_name, admin_id: user_id, opm_admin: opm_admin },
    });
    Swal.fire({
        position: "center",
      icon: "success",
      title: `Opmerking opgeslagen`,
      showConfirmButton: false,
      timer: 2500,
      });
    setOpen(false);
  } catch (error) {
    Swal.fire({
      position: "center",
      icon: "error",
      title: `Er is iets mis gelopen gelieve alles eens opnieuw na kijken`,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};
